import { React, useState } from 'react'
import { HeaderContainer, HomeButton, Title } from './styles'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Dialog } from '@headlessui/react'
import { FaBars } from 'react-icons/fa'
import { HiMiniXMark } from 'react-icons/hi2'
import { Menu, Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import logo from '../../assets/icons/logo.png'

import apresentacao_icon from '../../assets/icons/apresentacao_icon.png'
import linhaDoTempo from '../../assets/icons/linha_do_tempo.png'
import memorias from '../../assets/icons/memorias.png'
import colecoes from '../../assets/icons/colecoes.png'
import infograficos from '../../assets/icons/infograficos.png'
import creditos from '../../assets/icons/creditos.png'

function HeaderPages ({ content, marginRight }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const menu = (
    <Menu>
      <Menu.Item key='1'>
        <NavLink to='/publicacoes' className='flex items-center font-semibold'>
          <p className='mb-0 text-[#6F3228]'>Publicações</p>
        </NavLink>
      </Menu.Item>
      <Menu.Item key='2'>
        <NavLink
          to='/biblioteca'
          className='flex items-center text-[#6F3228] font-semibold'
        >
          <p className='mb-0 text-[#6F3228]'>Biblioteca</p>
        </NavLink>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <HeaderContainer>
        <HomeButton>
          <NavLink to='/'>
            <FontAwesomeIcon icon={faHome} color='#fff' />
          </NavLink>
        </HomeButton>
        <Title marginRight={marginRight}>{content}</Title>
        <div>
          <div className='flex'>
            <button
              type='button'
              className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className='sr-only'>Open main menu</span>
              <FaBars color='#fff' />
            </button>
          </div>

          <Dialog as='div' open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className='fixed inset-0 z-10' />
            <Dialog.Panel className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white bg-opacity-95 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
              <div className='flex items-center justify-between'>
                <a href='/' className='-m-1.5 p-1.5'>
                  <img
                    className='h-8 w-auto'
                    src={logo}
                    alt='Observatório Maranhão Agrário'
                  />
                </a>
                <button
                  type='button'
                  className='-m-2.5 rounded-md p-2.5 text-gray-700'
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className='sr-only'>Fechar menu</span>

                  <HiMiniXMark className='h-6 w-6' aria-hidden='true' />
                </button>
              </div>
              <div className='mt-6 flow-root'>
                <div className='-my-6 divide-y divide-gray-500/10'>
                  <div className='space-y-6 py-6'>
                    <NavLink
                      to='/sobre'
                      className='flex items-center font-bold text-[#6F3228]'
                    >
                      <img
                        src={apresentacao_icon}
                        className='mr-2 w-8 h-8'
                        alt='apresentacao'
                      />{' '}
                      Apresentação
                    </NavLink>
                    <NavLink
                      to='/questao-agraria'
                      className='flex items-center font-bold text-[#6F3228]'
                    >
                      <img
                        src={linhaDoTempo}
                        className='mr-2 w-8 h-8'
                        alt='linha do tempo'
                      />{' '}
                      Linha do tempo
                    </NavLink>
                    <NavLink
                      to='/em-memoria'
                      className='flex items-center font-bold text-[#6F3228]'
                    >
                      <img
                        src={memorias}
                        className='mr-2 w-8 h-8'
                        alt='memorias'
                      />{' '}
                      Espaço de memórias
                    </NavLink>
                    <Dropdown overlay={menu} trigger={['click']}>
                      <NavLink
                        to='#'
                        className='flex items-center font-bold text-[#6F3228]'
                        onClick={e => e.preventDefault()}
                      >
                        <img
                          src={colecoes}
                          className='mr-2 w-8 h-8'
                          alt='colecoes'
                        />{' '}
                        Coleções <DownOutlined className='ml-2' />
                      </NavLink>
                    </Dropdown>
                    <NavLink
                      to='/infograficos'
                      className='flex items-center font-bold text-[#6F3228]'
                    >
                      <img
                        src={infograficos}
                        className='mr-2 w-8 h-8'
                        alt='infograficos'
                      />
                      Infográficos
                    </NavLink>
                    <NavLink
                      to='/creditos'
                      className='flex items-center font-bold text-[#6F3228]'
                    >
                      <img
                        src={creditos}
                        className='mr-2 w-8 h-8'
                        alt='creditos'
                      />
                      Créditos
                    </NavLink>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
      </HeaderContainer>
    </>
  )
}

export default HeaderPages
