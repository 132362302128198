import React from 'react'
import { Container } from './styles'
import HeaderPages from '../../components/HeaderPages/HeaderPages'
import img from '../../assets/images/icone_baixar_livros.png'
const Biblioteca = () => {
  return (
    <>
      <HeaderPages content='Biblioteca' marginRight='1rem'/>
      <Container>
        <h1 className='mb-4 pt-12'>
          BIBLIOTECA MARIA DA GLÓRIA ROCHA FERREIRA
        </h1>
        <p>
          A biblioteca do Laboratório de Extensão, Pesquisa e Ensino de
          Geografia (LEPENG) leva o nome de Maria da Glória Rocha Ferreira, a
          geógrafa de Primeira Cruz-MA é uma das principais figuras da Geografia
          Maranhense. Sua tese sobre a "modernização da agricultura" no Maranhão
          é considerada uma das principais referências na área. O acervo, que
          conta com mais de 500 obras, é composto em grande parte por livros
          doados pela Professora Glória, agora acessíveis ao público.
        </p>
        <p>
          Além de clássicos da Geografia do Maranhão, Geografia Agrária,
          epistemologia e ensino de Geografia, a biblioteca realiza constantes
          aquisições. Para acessar os materiais, os interessados devem entrar em
          contato pelo e-mail observatóriomaranhaoagrario@gmail.com e agendar um
          horário para consulta. As obras estão disponíveis apenas para consulta
          interna na sala do LEPENG.
        </p>
        <div className='flex justify-center'>
          <a
            href='https://drive.google.com/file/d/1GqXbsgKTF6IHB-syZnCb6gCOQSv3jEG3/view?usp=sharing'
            target='_blank'
            rel='noreferrer'
            
          >
            <img src={img} alt='Baixar livros' className='mt-4 max-w-[150px] animate-pulse'/>
          </a>
        </div>
      </Container>
    </>
  )
}
export default Biblioteca
