import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './styles.css'

import bernardo from '../../assets/images/bernado.png'
import leonice from '../../assets/images/leonice.png'
import ariana from '../../assets/images/ariana.png'
import jose_sobreiro from '../../assets/images/jose_sobreiro.png'
import eduardo from '../../assets/images/eduardo.png'
import irismar_dos_reis from '../../assets/images/irismar_dos_reis.png'
import diogo from '../../assets/images/diogo.png'
import antonio_cordeiro from '../../assets/images/antonio_cordeiro.png'
import zulene from '../../assets/images/zulene.png'
import edmilson from '../../assets/images/edmilson.png'


const Carousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  return (
    <Slider {...settings}>
      <div className='carousel-item'>
        <img
          src={bernardo}
          alt='Bernardo'
        />
        <p className='frase'>"O observatório Maranhão Agrário é o mais novo território da luta pela terra no estado."</p>
        <p>Bernardo Mançano Fernandes</p>
        <p>Geógrafo - UNESP</p>
      </div>
      <div className='carousel-item'>
        <img
          src={leonice}
          alt='Leonice'
        />
        <p className='!leading-4'>"O Observatório Maranhão Agrário é um espaço democrático onde todos podem compartilhar e encontrar novos saberes."</p>
        <p>Maria Leonice Martins</p>
        <p>Professora de História - IEMA</p>
      </div>
      <div className='carousel-item'>
        <img
          src={jose_sobreiro}
          alt='José Sobreiro'
        />
         <p className='!leading-5'>"Com engajamento <i>sui generis</i>, o Observatório Maranhão Agrário se propõe a analisar os movimentos da realidade e propor reflexões e soluções inteligente para a realidade dos movimentos."</p>
        <p>José Sobreiro Filho</p>
        <p>Geógrafo - UNB</p>
      </div>
      <div className='carousel-item'>
        <img
          src={ariana}
          alt='Ariana'
        />
         <p className='!leading-4'>"O observatório traz memórias escritas e desenhadas, de lutas do passado e do presente."</p>
        <p>Ariana Silva</p>
        <p>Secretária Executiva da RAMA</p>
      </div>
      <div className='carousel-item'>
        <img
          src={eduardo}
          alt='Eduardo Paulon Girardi '
        />
         <p className='!leading-5'>“O Observatório Maranhão Agrário permite uma leitura geográfica inédita dos problemas do campo no estado, demonstrando o potencial da Geografia para entender e lutar contra os conflitos, as violências e as desigualdades que configuram a questão agrária.”</p>
        <p>Eduardo Paulon Girardi </p>
        <p>Geógrafo - Unesp</p>
      </div>
      <div className='carousel-item'>
        <img
          src={irismar_dos_reis}
          alt='Irismar dos Reis'
        />
         <p className='!leading-5'>“O Observatório Maranhão Agrário é um instrumento muito importante para divulgar a luta dos povos e as injustiças praticadas pelos grandes projetos destruidores da biodiversidade no planeta.”</p>
        <p>Irismar dos Reis</p>
        <p>Militante do MST</p>
      </div>
      <div className='carousel-item'>
        <img
          src={diogo}
          alt='Diogo Cabral'
        />
        <p className='!leading-4'>“O Observatório, fruto de dedicada e fundamental pesquisa acadêmica, é um espaço de memória permanente das lutas do Maranhão Agrário.”</p>
        <p>Diogo Cabral</p>
        <p>Advogado Popular</p>
      </div>
      <div className='carousel-item'>
        <img
          src={antonio_cordeiro}
          alt='Antônio Cordeiro'
        />
        <p className='!leading-4'>“O Observatório do Maranhão Agrário registra a evolução da agricultura maranhense, constituindo importante fonte de dados para a sua análise.”</p>
        <p>Antônio Cordeiro</p>
        <p>Geógrafo - UFMA</p>
      </div>
      <div className='carousel-item'>
        <img
          src={zulene}
          alt='Zulene Barbosa'
        />
        <p className='!leading-4'>“O Observatório do Maranhão Agrário é ineditismo.”</p>
        <p>Zulene Barbosa</p>
        <p>Cientista Social - UEMA</p>
      </div>
      <div className='carousel-item'>
        <img
          src={edmilson}
          alt='Edmilson Costa'
        />
        <p className='!leading-4'>“O Observatório Maranhão Agrário é muito importante para mapear e registrar a luta e resistência dos Trabalhadores e Trabalhadoras Rurais frente aos grandes latifundiários.”</p>
        <p>Edmilson Costa</p>
        <p>Assentado rural</p>
      </div>
      {/* Adicione mais itens conforme necessário */}
    </Slider>
  )
}

function SampleNextArrow (props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        position: 'absolute',
        top: '40%',
        right: '-30px', // Ajuste a distância da borda direita
        transform: 'translateY(-50%)',
        zIndex: 2
      }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow (props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        position: 'absolute',
        top: '40%',
        left: '-30px', // Ajuste a distância da borda esquerda
        transform: 'translateY(-50%)',
        zIndex: 2
      }}
      onClick={onClick}
    />
  )
}

export default Carousel
