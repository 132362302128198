import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/home/index'
import Mapas from './pages/mapas'
import MapaInterativo from './pages/mapa_interativo'
import EmMemoria from './pages/linhas_do_tempo/em_memoria'
import QuestaoAgraria from './pages/linhas_do_tempo/questao_agraria'
import Publicacoes from './pages/publicacoes'
import Post from './pages/post'
import Sobre from './pages/sobre'
import Infograficos from './pages/infograficos'
import Creditos from './pages/creditos'
import PublicacoesChildren from './pages/publicacoesChildren'
import Noticias from './pages/noticias'
import Biblioteca from './pages/biblioteca'
function App () {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/mapas/:id' element={<Mapas />} />
          <Route path='/mapa-interativo' element={<MapaInterativo />} />
          <Route path='/em-memoria' element={<EmMemoria />} />
          <Route path='/questao-agraria' element={<QuestaoAgraria />} />
          <Route path='/publicacoes' element={<Publicacoes />} />
          <Route path='/publicacoes/:id' element={<PublicacoesChildren />} />
          <Route path='/sobre' element={<Sobre />} />
          <Route path='/infograficos' element={<Infograficos />} />
          <Route path='/creditos' element={<Creditos />} />
          <Route path='/post/:id/:title' element={<Post />} />
          <Route path='/noticias' element={<Noticias />} />
          <Route path='/biblioteca' element={<Biblioteca />} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
