import React from 'react'
import { Container } from './styles'
import HeaderPages from '../../components/HeaderPages/HeaderPages'
import logo_em_linha from '../../assets/images/logo_em_linha.png'
const Creditos = () => {
  return (
    <>
      <HeaderPages content='Créditos' marginRight='1rem' />
      <Container>
        <div>
          <h2 className='col-span-full text-2xl font-bold text-gray-900 text-center'>
            Referências Utilizadas
          </h2>
          <div className='w-full max-h-[300px] overflow-y-scroll mt-4 mb-6 p-3 overflow-x-auto rounded-lg bg-gray-300'>
            <h2 className='col-span-full text-xl font-bold text-gray-900 text-center'>
              Espaço de Memórias
            </h2>
            <div className='text-justify text-[0.9rem]'>
              <p>
                CPT. Conflitos no campo Brasil / Centro de Documentação Dom
                Tomás Balduíno. - Goiânia: CPT Nacional, 1986 - 2024. Disponível
                em: {'<'}
                https://www.cptnacional.org.br/publicacoes-2/conflitos-no-campo-brasil
                {'>'}. Acesso em 16 fev. 2024.
              </p>
              <p>
                FETAEMA; CABRAL, Diogo Diniz Ribeiro (Org.). Assassinatos no
                campo 1964 - 1985. São Luís, 2023.
              </p>
            </div>
            <h2 className='col-span-full text-xl font-bold text-gray-900 text-center'>
              Infográficos
            </h2>
            <div className='text-justify text-[0.9rem]'>
              <p className=''>
                CPT - Comissão Pastoral da Terra.{' '}
                <b>Caderno de Conflitos no Campo Brasil</b>. Centro de
                Documentação Dom Tomás Balduíno. Goiânia: CPT Nacional, 2011 -
                2024.
              </p>
              <p>
                DATALUTA - Rede Brasileira de Pesquisas das Lutas por Espaços e
                Territórios. <b>Base Brasil Dataluta Assentamentos 2023</b>.
                Presidente Prudente: Unesp, 2023.
              </p>
              <p className='break-all'>
                Assis, L. F. F. G.; Ferreira, K. R.; Vinhas, L.; Maurano, L.;
                Almeida, C.; Carvalho, A.; Rodrigues, J.; Maciel, A.; Camargo,{' '}
                <b>
                  C. TerraBrasilis: A Spatial Data Analytics Infrastructure for
                  Large-Scale Thematic Mapping
                </b>
                . ISPRS International Journal of Geo-Information. 8, 513, 2019.
                Disponível em:{' '}
                <span>
                  https://terrabrasilis.dpi.inpe.br/app/dashboard/deforestation/biomes/legal_amazon/increments
                </span>
                . Acesso em: 10 ago. 2024.
              </p>
            </div>
            <h2 className='col-span-full text-xl font-bold text-gray-900 text-center'>
              Linha do tempo da Questão Agrária
            </h2>
            <h3>Século XVII</h3>
            <div className='text-justify text-[0.9rem]'>
              <p className='break-all'>
                ABBEVILLE, Claude.{' '}
                <b>
                  História da missão dos padres capuchinhos na ilha do Maranhão
                  e suas circunvisinhaças
                </b>
                . Maranhão: Typ. do Frias, 1874. Tradução de Cezar Augusto
                Marques. Disponível em:
                <span>http://www2.senado.leg.br/bdsf/handle/id/221724</span>
                Acesso em: 14 nov. 2023.
              </p>
              <p>
                COSTA, Wagner Cabral da (org.). <b>História do Maranhão</b>:
                Novos estudos. São Luís: Edufma, 2004. 299 p. ISBN
                85-85048-47-6.
              </p>
              <p>
                FERREIRA, Antonio José de A.{' '}
                <b>
                  Políticas territoriais e a reorganização do espaço maranhense
                </b>
                . São Paulo. Tese (Doutorado em Geografia Humana). DG/FFLCH/USP,
                2008.
              </p>
              <p>
                MEIRELES, Mário M. <b>Dez estudos históricos</b>. São Luís:
                Alumar Cultura, 1994.
              </p>
              <p>
                MEIRELES, Mário M. <b>História do Maranhão</b>. 3. ed. atual.
                São Paulo: Siciliano, 2001. ISBN 85-267-0868-6.
              </p>
              <p>
                TRIBUZI, Bandeira. <b>Formação econômica do Maranhão</b>: Uma
                proposta de desenvolvimento. 2. ed. São Luís: CORECON, 2011.
                ISBN 978-85-98274-25-6.
              </p>
            </div>
            <h3>Século XVIII</h3>
            <div className='text-justify text-[0.9rem]'>
              <p>
                BARBOSA, Benedito Carlos Costa.{' '}
                <b>Em outras margens do atlântico</b>: tráfico negreiro para o
                estado do Maranhão e Grão-Pará (1707-1750). 2009. 152 f.
                Dissertação (Mestrado) - Universidade Federal do Pará, Instituto
                de Filosofia e Ciências Humanas, Belém, 2009. Programa de
                Pós-Graduação em História Social da Amazônia. Disponível em:
                https://repositorio.ufpa.br/jspui/handle/2011/4303 . Acesso em:
                15/05/2024.
              </p>
              <p>
                CABRAL, Maria do Socorro Coelho. <b>Caminhos do gado</b>:
                Conquista e ocupação do sul do Maranhão. Coleção Humanidades,
                vol. 05. São Luís: Edufma, 2008. ISBN 978-85-7862-2.
              </p>
              <p>
                COELHO NETTO, Eloy. <b>História do sul do Maranhão</b>: Terra -
                Vida - Homens e Acontecimentos. Belo Horizonte: São Vicente,
                1979.
              </p>
              <p>
                FERREIRA, Antonio José de A.{' '}
                <b>
                  Políticas territoriais e a reorganização do espaço maranhense
                </b>
                . São Paulo. Tese (Doutorado em Geografia Humana). DG/FFLCH/USP,
                2008.
              </p>
              <p>
                MEIRELES, Mário M. <b>História do Maranhão</b>. 3. ed. atual.
                São Paulo: Siciliano, 2001. ISBN 85-267-0868-6.
              </p>
              <p>
                PFLUEGER, Grete Soares.{' '}
                <b>Conhecendo a história e a cartografia urbana de São Luís</b>.
                São Luís: UEMAnet, 2021. ISBN 978-65-89787-56-3. E-book.
              </p>
            </div>
            <h3>Século XIX</h3>
            <div className='text-justify text-[0.9rem]'>
              <p>
                CARVALHO, Willian.{' '}
                <b>
                  A Lei de Terras de 1850 e sua relação com a questão fundiária
                  no Brasil
                </b>
                . Jusbrasil, [<i>s. l.</i>], 25 jul. 2020. Disponível em:
                https://www.jusbrasil.com.br/artigos/a-lei-de-terras-de-1850-e-sua-relacao-com-a-questao-fundiaria-no-brasil/838743929.
                Acesso em: 19 jun. 2024.
              </p>
              <p>
                COSTA, Wagner Cabral da (org.). <b>História do Maranhão</b>:
                Novos estudos. São Luís: Edufma, 2004. 299 p. ISBN
                85-85048-47-6.
              </p>
              <p>
                FERREIRA, Antonio José de A.{''}
                <b>
                  Políticas territoriais e a reorganização do espaço maranhense
                </b>
                . São Paulo. Tese (Doutorado em Geografia Humana). DG/FFLCH/USP,
                2008.
              </p>
              <p className='break-all'>
                GASSEN, Valcir.{' '}
                <b>A Lei de Terras de 1850 e o direito de propriedade</b>. 1994.
                Dissertação (Pós-graduação em Direito) - Universidade Federal de
                Santa Catarina, Florianópolis, 1994. Disponível em:
                https://repositorio.ufsc.br/xmlui/bitstream/handle/123456789/76176/96758.pdf?sequence=1&isAllowed=y.
                Acesso em: 17 jun. 2024.
              </p>
              <p>
                MEIRELES, Mário M. <b>História do Maranhão</b>. 3. ed. atual.
                São Paulo: Siciliano, 2001. ISBN 85-267-0868-6.
              </p>
              <p>
                TRIBUZI, Bandeira. <b>Formação econômica do Maranhão</b>: Uma
                proposta de desenvolvimento. 2. ed. São Luís: CORECON, 2011.
                ISBN 978-85-98274-25-6.
              </p>
            </div>
            <h3>Década de 1900</h3>
            <div className='text-justify text-[0.9rem]'>
              <p>
                ANDRADE, Manoel Correia de. <b>Lutas camponesas no Nordeste</b>.
                São Paulo: Ática, 1986. ISBN 8508010982.
              </p>
              <p>
                CRUZ, Olímpio. Cauiré Imana, o cacique rebelde. Brasília:
                Thesaurus, 1982.
              </p>
              <p>
                COELHO NETTO, Eloy. <b>História do sul do Maranhão</b>: Terra -
                Vida - Homens e Acontecimentos. Belo Horizonte: São Vicente,
                1979.
              </p>
              <p className='break-all'>
                GUIMARÃES, Pedro Henrique. <b>O coronelismo ainda respira</b>:
                Atributos do universo coronelista na conjuntura
                político-eleitoral de municípios do Maranhão para os cargos de
                deputado estadual e federal nas eleições 2018. Orientador: Prof.
                Dr. Roberto Carvalho Veloso. 2023. Dissertação (Pós-graduação em
                Direito) - Universidade Federal do Maranhão, São Luís, 2023.
                Disponível em:
                https://tedebc.ufma.br/jspui/bitstream/tede/4692/2/PedroGuimar%C3%A3es.pdf
                . Acesso em: 18 jun. 2024.
              </p>
              <p className='break-all'>
                LACERDA, Maykon Albuquerque. "<b>As cangalhas maranhenses</b>":
                O coronelismo configurado na imagem de Vitorino Freire entre
                1945 e 1965. Mundo Livre-UFF, Campos do Goytacazes, v. 5, n. 2,
                p. 115-131, agosto/dezembro 2019. Disponível em:
                https://periodicos.uff.br/mundolivre/article/view/40345/23221 .
                Acesso em: 19 jun. 2024.
              </p>
              <p className='break-all'>
                OLIVEIRA, Eric Nilson da Costa.{' '}
                <b>
                  Análise socioeconômica de Alagoas: fontes e história sobre
                  algodão e a indústria têxtil
                </b>
                , 1850-1915. 2023. 143 f. Dissertação (Mestrado em História) –
                Instituto e Ciências Humanas, Comunicação e Artes, Universidade
                Federal de Alagoas, Maceió, 2020.
              </p>
              <p className='break-all'>
                OLIVEIRA, Mayjara Rêgo Costa Garcia; CABRERA, Isabel Ibarra.{' '}
                <b>Notícias de um sertão</b>: representações dos sertões
                maranhenses em periódicos na Primeira República (1900-1920).
                Revista Interdisciplinar em Cultura e Sociedade, v. 4, n. Espec,
                p. 409–422, 5 Jan 2019 Disponível em:
                https://periodicoseletronicos.ufma.br/index.php/ricultsociedade/article/view/10540.
                Acesso em: 27 mai 2024.
              </p>
              <p>
                TRIBUZI, Bandeira. <b>Formação econômica do Maranhão</b>: Uma
                proposta de desenvolvimento. 2. ed. São Luís: CORECON, 2011.
                ISBN 978-85-98274-25-6.
              </p>
            </div>
            <h3>Década de 1910</h3>
            <div className='text-justify text-[0.9rem]'>
              <p>
                COELHO NETTO, Eloy. <b>História do sul do Maranhão</b>: Terra -
                Vida - Homens e Acontecimentos. Belo Horizonte: São Vicente,
                1979.
              </p>
              <p>
                COSTA, Wagner Cabral da (org.). <b>História do Maranhão</b>:
                Novos estudos. São Luís: Edufma, 2004. 299 p. ISBN
                85-85048-47-6.
              </p>
              <p className='break-all'>
                OLIVEIRA, Viviane de. Economia do babaçu no Maranhão e a
                invisibilidade do trabalho camponês. <b>Escritas</b>: Revista do
                curso de História de Araguaína, [s. l.], v. 14, n. 1, p.
                145-166, 2022. DOI 10.20873/vol14n01pp145-166. Disponível em:
                https://www.researchgate.net/publication/365456019_ECONOMIA_DO_BABACU_NO_MARANHAO_E_A_INVISIBILIDADE_DO_TRABALHO_CAMPONES
                . Acesso em: 12 jun. 2024.
              </p>
              <p className='break-all'>
                SIQUEIRA, Eranir Martins de; SOUSA, Neimar Machado de. A atuação
                do Serviço de Proteção ao Índio e a história dos guarani/kaiowá.
                <b>ANPUH</b>: XXIII Simpósio Nacional de História, Londrina,
                2005. Disponível em:
                https://anpuh.org.br/uploads/anais-simposios/pdf/2019-01/1548206569_06c6f40d5948eeff1e4a8ace22afe2e3.pdf
                . Acesso em: 9 jun. 2024.
              </p>
              <p>
                TRIBUZI, Bandeira. <b>Formação econômica do Maranhão</b>: Uma
                proposta de desenvolvimento. 2. ed. São Luís: CORECON, 2011.
                ISBN 978-85-98274-25-6.
              </p>
            </div>
            <h3>Década de 1920</h3>
            <div className='text-justify text-[0.9rem]'>
              <p>
                ALMEIDA, Giniomar Ferreira. <b>O Lenine Maranhense</b>:
                fuzilamentos e cultura histórica no interior do Maranhão(1921).
                2010. 124 f. Dissertação (Mestrado em História) - Universidade
                Federal da Paraí­ba, João Pessoa, 2010. Disponível em:
                https://repositorio.ufpb.br/jspui/handle/tede/6025 . Acesso em:
                22/05/2024.
              </p>
              <p>
                BARBOSA, Zulene Muniz; ALMEIDA, Desni Lopes.{' '}
                <b>A rota dos grandes projetos no Maranhão</b>: a dinâmica entre
                o local, o regional e o transnacional. Anais do V Simpósio
                Internacional Lutas Sociais na América Latina: “Revoluções nas
                Américas: passado, presente e futuro”, Londrina, p. 38-46, 2013.
                Disponível em:
                https://www.uel.br/grupo-pesquisa/gepal/v4_zulene_e_desni_GIV.pdf
                . Acesso em: 3 jun. 2024.
              </p>
              <p className='break-all'>
                ESTADO DO MARANHÃO. Decreto nº 417, de 11 de abril de 1921.
                Aprova o regulamento para o serviço de medição e demarcação das
                terras públicas e particulares com fim de ser organizado o
                cadastro territorial e a carta geral do Estado.{' '}
                <b>Regulamento para o serviço de terras</b>, São Luís, 11 abr.
                1921. Disponível em:
                http://casas.cultura.ma.gov.br/portal/sgc/modulos/sgc_bpbl/acervo_digital/arq_ad/20181116100404.pdf
                . Acesso em: 30 maio 2024.
              </p>
              <p className='break-all'>
                SILVA, Wagner de Sousa e; MORENO, Sandra Antonielle Garcês;
                MONTEIRO, Fabíola Monteiro da Conceição da Lima.{' '}
                <b>De perseguido a reconhecido</b>: a história da resistência do
                bumba-meu-boi na cidade de São Luís-MA. Anais do V Congresso
                Norte-Nordeste de Pesquisa e Inovação: CONNEPI, Maceió, 2010.
                Disponível em:
                http://connepi.ifal.edu.br/ocs/anais/conteudo/anais/files/conferences/1/schedConfs/1/papers/599/public/599-4918-1-PB.pdf
                . Acesso em: 4 jun. 2024.
              </p>
            </div>
            <h3>Década de 1930</h3>
            <div className='text-justify text-[0.9rem]'>
              <p>
                GARFIELD, Seth. As raízes de uma planta que hoje é o Brasil: os
                índios e o Estado-Nação na era Vargas.{' '}
                <b>Revista Brasileira de História</b>. São Paulo, v. 20, nº 39,
                p. 15-42. 2000.
              </p>
              <p className='break-all'>
                LOPES, Raimundo Helio.{' '}
                <b>A Delegacia Militar do Norte e o Governo Provisório</b>:
                disputas políticas e a nomeação dos interventores nortistas.
                Anais do XXVI Simpósio Nacional de História – ANPUH, São Paulo,
                julho 2011. Disponível em:
                https://www.snh2011.anpuh.org/resources/anais/14/1300844674_ARQUIVO_textocompletoanpuh2011RaimundoHelioLopes.pdf
                . Acesso em: 23 jul. 2024.
              </p>
              <p>
                MONTEIRO, Maria E. B. O Serviço de Proteção aos Índios e o
                Tribunal Especial na Amazônia. In.{' '}
                <b>
                  O uso da fotografia na defesa do Serviço de Proteção aos
                  Índios no início da década de 30
                </b>
                . Brasiliana Fotográfica. 26 de abr. de 2018. Disponível em:
                https://historiahoje.com/getulio-varg . Acesso em: 05 de nov. de
                2020.
              </p>
              <p>
                REIS, Flávio.{' '}
                <b>Grupos Políticos e Estrutura Oligárquica No Maranhão</b>. 2ª.
                ed. São Luís: Halley, 2013. 240 p. ISBN 0978856252.
              </p>
              <p>
                Relatório Anual do Ministério da Agricultura, Indústria e
                Comércio, Vol II. 1943. – Disponível em:
                http://ddsnext.crl.edu/brazil . Acesso: julho de 2021.
              </p>
              <p>
                RIBEIRO, Aldo Tavares.{' '}
                <b>FERROVIA SÃO LUÍS – TERESINA E A CIDADE DE CODÓ</b>: história
                e memória a partir de 1895 a década de 1940. Orientador: Prof.
                Dr. Marcia Milena Galdez Ferreira. 2021. Dissertação
                (Pós-graduação em História) - Universidade Estadual do Maranhão,
                São Luís, 2021. Disponível em:
                https://repositorio.uema.br/handle/123456789/1375 . Acesso em:
                10 jun. 2024.
              </p>
              <p>
                SANTOS, Anna Karla de Almeida; PFLUEGER, Grete Soares.
                <b>Modernidades industriais no Maranhão</b>. Labor & Engenho, v.
                13, 2019.
              </p>
              <p>
                TRIBUZI, Bandeira. <b>Formação econômica do Maranhão</b>: Uma
                proposta de desenvolvimento. 2. ed. São Luís: CORECON, 2011.
                ISBN 978-85-98274-25-6.
              </p>
              <p>
                VIVEIROS, Jerônimo de.{' '}
                <b>História do Comércio do Maranhão 1612 - 1895</b>. São Luís:
                Associação Comercial do Maranhão - São Luís, 1954.
              </p>
            </div>
            <h3>Década de 1940</h3>
            <div className='text-justify text-[0.9rem]'>
              <p>
                ARAÚJO, Helciane de Fátima Abreu.{' '}
                <b>ESTADO/MOVIMENTOS SOCIAIS NO CAMPO</b>: a trama da construção
                conjunta de uma política pública no Maranhão. 2010. Tese de
                Doutorado (Doutorado em Sociologia) - Universidade Federal do
                Ceará, Fortaleza, 2010. Disponível em:
                https://1library.org/document/y4w033kq-estado-movimentos-sociais-construcao-conjunta-politica-publica-maranhao.html
                . Acesso em: 10 jun. 2024.
              </p>
              <p className='break-all'>
                RAMOS, Carolina.{' '}
                <b>CAPITAL E TRABALHO NO SINDICALISMO RURAL BRASILEIRO</b>: uma
                análise sobre a CNA e sobre a Contag (1964-1985). 2011. Tese de
                Doutorado (Pós-graduação em História) - Universidade Federal
                Fluminense, Niterói, 2011. Disponível em:
                https://app.uff.br/riuff/bitstream/handle/1/16388/Tese-carolina-torres-alves-de-almeida-ramos%20%281%29.pdf?sequence=1&isAllowed=y
                . Acesso em: 30 maio 2024.
              </p>
            </div>
            <h3>Década de 1950</h3>
            <div className='text-justify text-[0.9rem]'>
              <p className='break-all'>
                MENDONÇA, Bartolomeu Rodrigues; BRUSTOLIN, Cíndia; ALVES, Elio
                De Jesus Pantoja. PROJETOS DE DESENVOLVIMENTO E TERRITÓRIOS
                TRADICIONAIS NO MARANHÃO, BRASIL: AMEAÇAS E PROCESSOS DE
                RESISTÊNCIA. <b>Repocs</b>, São Luís, v. 16, n. 32, p. 94-116,
                agosto/dezembro 2019. Disponível em:
                https://periodicoseletronicos.ufma.br/index.php/rpcsoc/article/view/13232/7332
                . Acesso em: 20 maio 2024.
              </p>
              <p className='break-all'>
                SANTOS, Frednan Bezerra dos; TAVARES, João Claudino. Questão
                Agrária e Violência no Maranhão: grilagem, colonização dirigida
                e a luta dos trabalhadores. <b>Revista de Políticas Públicas</b>
                , v. 20, n. 1, p. 361-381, 2006. Disponível em:
                https://periodicoseletronicos.ufma.br/index.php/rppublica/article/view/5061
                . Acesso em: 29 maio 2024.
              </p>
              <p className='break-all'>
                SANTOS, Raimundo Lima dos. Manoel Conceição Santos: de camponês
                a líder político. <b>Revista Eletrônica História em Reflexão</b>
                , [<i>S. l.</i>], v. 4, n. 7, 2010. Disponível em:
                https://ojs.ufgd.edu.br/historiaemreflexao/article/view/742 .
                Acesso em: 15 mai. 2024.
              </p>
              <p>
                SOUSA, Ferdinando de. <b>BELÉM-BRASÍLIA</b>: “A RODOVIA DA
                UNIDADE NACIONAL”. Água, Vida e Cia - Ferdinando de Sousa, [
                <i>S. l.</i>], 10 jul. 2019. Disponível em:
                https://ferdinandodesousa.com/2019/10/07/belem-brasilia-a-rodovia-da-unidade-nacional/
                . Acesso em: 20 maio 2024.
              </p>
            </div>
            <h3>Década de 1960</h3>
            <div className='text-justify text-[0.9rem]'>
              <p>
                AMARAL NETO, Roberval. "<b>LEI SARNEY DE TERRAS</b>": conflitos
                fundiários e resistência camponesa no oeste maranhense nas
                décadas de 1970 e 1980. In: 30° Simpósio Nacional de História -
                História e o futuro da educação no Brasil, 2019, Recife. Anais
                do 30° Simpósio Nacional de História - História e o futuro da
                educação no Brasil. Recife: Anpuh, 2019. v. 01, p. 01 – 16.
              </p>
              <p>
                CORRÊA, Carlos Hugo Studart.{' '}
                <b>Em algum lugar das selvas amazônicas</b>: as memórias dos
                guerrilheiros do Araguaia (1966-1974). 2013. 619 f., il. Tese
                (Doutorado em História)—Universidade de Brasília, Brasília,
                2013.
              </p>
              <p>
                GOVERNO FEDERAL (Brasil). Ministério do Desenvolvimento
                Regional. Institucional. In: GOVERNO FEDERAL (Brasil). Governo
                Federal (org.).{' '}
                <b>Superintendência do Desenvolvimento da Amazônia</b>:
                Histórico - SUDAM. [S. l.], 13 out. 2020. Disponível em:
                https://www.gov.br/sudam/pt-br/acesso-a-informacoes/institucional/historico-sudam
                . Acesso em: 15 jul. 2024.
              </p>
              <p>
                GOVERNO FEDERAL (Brasil). <b>Ministério dos povos indígenas</b>{' '}
                (org.). A Funai. [S. l.], 27 nov. 2020. Disponível em:
                https://www.gov.br/funai/pt-br/acesso-a-informacao/institucional/Institucional
                . Acesso em: 11 jun. 2024.
              </p>
              <p className='break-all'>
                MUNIZ, Lenir Moraes.{' '}
                <b>A CRIAÇÃO DE BÚFALOS NA BAIXADA MARANHENSE</b>: uma análise
                do desenvolvimentismo e suas implicações sócio-ambientais. Anais
                III JORNADA INTERNACIONAL DE POLÍCAS PÚBLICAS: QUESTÃO SOCIAL E
                DESENVOLVIMENTO NO SÉCULO XXI, São Luís, 2007. Disponível em:
                https://www.joinpp.ufma.br/jornadas/joinppIII/html/Trabalhos/EixoTematicoI/
                Eixo%209%20a117d7fcc8043a5882d9lLenir%20Moraes%20Muniz.pdf .
                Acesso em: 3 jun. 2024.
              </p>
              <p>
                PREFEITURA MUNICIPAL DE XAMBIOÁ (Tocantins) (ed.).{' '}
                <b>Guerrilha do Araguaia</b>. <i>In</i>: A cidade. [<i>S. l.</i>
                ], 2020. Disponível em:
                https://xambioa.to.gov.br/guerrilha-do-araguaia/ . Acesso em: 17
                jun. 2024.
              </p>
              <p>
                RODRIGUES, Cíntia Régia. As populações indígenas e o Estado
                Nacional pós-ditadura militar. História Unisinos. vol. 9, n. 3,
                setembro-dezembro, 2005. p. 240-245. Universidade do Vale do Rio
                dos Sinos, São Leopoldo. Disponível em:
                https://www.redalyc.org/articulo.oa?id=579866845011.
              </p>
              <p>
                SILVA, Juliana Resende <i>et al</i>.{' '}
                <b>
                  Caracterização e diagnóstico das patologias em um trecho da
                  rodovia BR-010 Belém-Brasília
                </b>
                : subtrecho entre municípios de Imperatriz (MA) e Governador
                Edson Lobão (MA). Revista de Gestão e Secretariado. v. 14, n. 8.
                p. 13257-13272, 2023.
              </p>
            </div>
            <h3>Década de 1970</h3>
            <div className='text-justify text-[0.9rem]'>
              <p>
                ALMEIDA, Alderico José Santos.{' '}
                <b>
                  O processo eleitoral e a reprodução de grupos políticos no
                  Maranhão
                </b>
                : embates recentes. 2010. Dissertação (Mestrado) - PONTIFÍCIA
                UNIVERSIDADE CATÓLICA DE SÃO PAULO, São Paulo, 2010. Disponível
                em:
                https://1library.org/document/q5mvdlry-pontificia-universidade-catolica-paulo-alderico-jose-santos-almeida.html
                . Acesso em: 14 maio 2024.
              </p>
              <p className='break-all'>
                BARBOSA, Zulene Muniz; ALMEIDA, Desni Lopes; CARNEIRO, Saulo;
                MESQUITA, Uslan Junior. O GLOBAL E O REGIONAL: grandes projetos
                desigualdades regionais e lutas sociais no Maranhão.{' '}
                <b>Anais V Jornada Internacional de Políticas Públicas</b>, [
                <i>s. l.</i>], 2011. Disponível em:
                https://www.joinpp.ufma.br/jornadas/joinpp2011/CdVjornada/JORNADA_EIXO_2011/
                MESAS_TEMATICAS/O_GLOBAL_E_O_REGIONAL_GRANDES_PROJETOS_DESIGUALDADES_E_LUTAS
                _SOCIAIS_NO_MARANHAO_NEW.pdf . Acesso em: 22 maio 2024.
              </p>
              <p>
                COMISSÃO PASTORAL DA TERRA (Brasil) (org.). <b>Histórico</b>. [
                <i>S. l.</i>], 5 fev. 2010. Disponível em:
                https://www.cptnacional.org.br/sobre-nos/historico . Acesso em:
                24 maio 2024.
              </p>
              <p className='break-all'>
                FARIAS, Luis Carlos Everton de; DA, Integrantes. Experiência:
                <b>
                  Reestruturação do INCRA Instituto Nacional de Colonização e
                  Reforma Agrária
                </b>
                . julho/2000. Relato da situação anterior à introdução da
                inovação, identificação de problemas que precisam ser
                resolvidos. [s.l.: s.n., s.d.]. Disponível em:
                https://repositorio.enap.gov.br/bitstream/1/485/1/085_01_Reestrutura%C3%A7%C3%A3o%20do%20Incra.pdf
                . Acesso em 24 de maio de 2024.
              </p>
              <p>
                FAUSTO, Boris. <b>História Concisa do Brasil</b>. São Paulo:
                Edusp, 2018.
              </p>
              <p>
                FETAEMA (Maranhão) (ed.). Sobre nós. <i>In</i>: <b>FETAEMA</b>:
                Federação dos Trabalhadores Rurais Agricultores e Agricultoras
                do Estado do Maranhão. São Luís, 2018. Disponível em:
                http://fetaema.org/sobre-nos . Acesso em: 22 maio 2024.
              </p>
              <p>
                FONSECA, Pedro Cezar Dutra; MONTEIRO, Sergio Marley Modesto. O
                Estado e suas razões: o II PND.{' '}
                <b>Revista de Economia Política</b>, vol. 28, n° 1 (109), p.
                28-46, janeiro-março/2008.
              </p>
              <p>
                MINISTÉRIO DO DESENVOLVIMENTO AGRÁRIO (Brasil). INCRA (org.).{' '}
                <b>A Política</b>. [<i>S. l.</i>], 28 jun. 2020. Disponível em:
                https://www.gov.br/incra/pt-br/assuntos/reforma-agraria/a-politica
                . Acesso em: 13 maio 2024.
              </p>
              <p>
                SANTOS, Clóvis Caribé Menezes dos. Programa de Cooperação
                Nipo-Brasileira para o Desenvolvimento dos Cerrados –PRODECER:
                um espectro ronda os cerrados brasileiros. <b>Estudos</b>:
                Sociedade e Agricultura, Rio de Janeiro, v. 24, n. 2, p.
                384-416, outubro de 2016. Disponível em:
                https://www.redalyc.org/articulo.oa?id=599964678001 . Acesso em:
                16 maio 2024.
              </p>
            </div>
            <h3>Década de 1980</h3>
            <div className='text-justify text-[0.9rem]'>
              <p className='break-all'>
                ANGELO, Maurício. Trilhos da maior mina de ferro do mundo
                impactam a vida de comunidades pobres no Maranhão.{' '}
                <b>Mongabay</b> - Notícias ambientais para informar e
                transformar, [<i>S. l.</i>], p. 115-131, 2 ago. 2022. Disponível
                em:
                https://brasil.mongabay.com/2022/06/trilhos-da-maior-mina-de-ferro-do-mundo-impactam-a-
                vida-de-comunidades-pobres-no-maranhao/#:~:text=S%C3%A3o%20pelo%20menos%20130%20comunidades,
                claro%2C%20a%20capital%20S%C3%A3o%20Lu%C3%ADs . Acesso em: 22
                maio 2024.
              </p>
              <p>
                BARBOSA, Zulene (2001). <b>Maranhão, Brasil</b>: lutas de
                classes e reestruturação produtiva em uma nova rodada de
                transnacionalização do capitalismo. Tese de doutorado. São
                Paulo, PUC,SP.
              </p>
              <p>
                CASTRO, Mariana. Contando e recontado histórias: conheça a luta
                da primeira ocupação de terra no Maranhão. <b>MST</b>, [
                <i>s. l.</i>
                ], 30 set. 2022. Disponível em:
                https://mst.org.br/2022/09/30/contando-e-recontado-historias-conheca-a-luta-da-primeira-ocupacao-de-terra-no-maranhao/
                . Acesso em: 18 jun. 2024.
              </p>
              <p>
                CORBARI, Marcos Antônio. Artigo | Padre Josimo Tavares, outro
                Cristo no nosso meio: 35 anos de martírio, na luta pela terra.
                <b>Instituto Cultural Padre Josimo</b>, 9 ago. 2024. Disponível
                em:
                https://padrejosimo.com.br/site/artigos/artigo-padre-josimo-tavares-outro-cristo-no-nosso-meio-35-anos-de-martirio-na-luta-pela-terra/
                . Acesso em: 30 maio 2024.
              </p>
              <p className='break-all'>
                HOLANDA, Rita de Cássia Ayres de.{' '}
                <b>Memória e Direitos Humanos</b>: Memórias das famílias
                quilombolas remanejadas para implantação do Centro de Lançamento
                de Alcântara -MA na Ditadura Militar (1964-1985). Orientador:
                Evander Ruthieri S. da Silva. 2022. Artigo (Especialização) -
                INSTITUTO LATINO-AMERICANO DE ARTE, CULTURA E HISTÓRIA (ILAACH),
                Foz do Iguaçu, 2022. Disponível em:
                https://dspace.unila.edu.br/server/api/core/bitstreams/d92d907d-bc0e-4414-bd16-d651e5cb452f/content
                . Acesso em: 16 maio 2024.
              </p>
              <p className='break-all'>
                GOVERNADOR DO ESTADO DO MARANHÃO.{' '}
                <b>Lei nº 4734, de 18 de junho de 1986</b>. Proíbe a derrubada
                de palmeira de babaçu e dá outras providências. [<i>S. l.</i>],
                18 jun. 1986. Disponível em:
                https://www.mpma.mp.br/arquivos/COCOM/arquivos/centros_de_apoio/cao_meio_ambiente/
                legislacao/legislacao_estadual/Noticia1226A972.pdf . Acesso em:
                19 jun. 2024.
              </p>
            </div>
            <h3>Década de 1990</h3>
            <div className='text-justify text-[0.9rem]'>
              <p className='break-all'>
                BARBOSA, Catarina.{' '}
                <b>Massacre de Eldorado do Carajás completa 24 anos</b>: "Um dia
                para não esquecer". Brasil de Fato, Belém, p. Direitos Humanos,
                6 ago. 2024. Disponível em:
                https://www.brasildefato.com.br/2020/04/17/massacre-de-eldorado-do-carajas-completa-24-anos-um-dia-para-nao-esquecer
                . Acesso em: 12 jun. 2024.
              </p>
              <p>
                CARNEIRO, Marcelo Sampaio. <b>Terra, Trabalho e Poder</b>:
                Conflitos e lutas sociais no Maranhão contemporâneo. São Paulo:
                Annablume, 2013. ISBN 978-85-391-0593-9.
              </p>
              <p>
                CPT - Conflitos no Campo Brasil 1992. Centro de Documentação Dom
                Tomás Balduíno. Goiânia: CPT Nacional, 1993.
              </p>
              <p>
                FEITOSA, Milena Monteiro; SOUSA, Erika Costa; NASCIMENTO,
                Louize; LEMOS, José de Jesus Sousa. A soja no estado do
                Maranhão, Brasil: uma análise temporal da expansão e
                substituição das culturas alimentares. <b>Geografares</b>, [
                <i>s. l.</i>], n. 37, 2023. Disponível em:
                https://journals.openedition.org/geografares/9884 . Acesso em: 3
                jun. 2024.
              </p>
              <p className='break-all'>
                MESQUITA, Benjamin Alvino de.{' '}
                <b>OS NOVOS CENÁRIOS DA DINAMICA AGRÍCOLA NA AMAZONIA</b>: as
                empresas globais. Anais - VI Jornada Internacional de Políticas
                Públicas, São Luís, 2013. Disponível em:
                https://www.joinpp.ufma.br/jornadas/joinpp2013/JornadaEixo2013/anais-
                eixo12-questaoagricolaquestaoagrariasegurancaalimentarepoliticaspublicas/pdf/
                osnovoscenariosdadinamicaagricolanaamazonia-asempresasglobais.pdf
                . Acesso em: 31 maio 2024.
              </p>
              <p className='break-all'>
                OLIVEIRA, Allison Bezerra. INDÚSTRIA DE CELULOSE E O AVANÇO DA
                SILVICULTURA DO EUCALIPTO NA FRONTEIRA AGRÍCOLA DA AMAZÔNIA
                MARANHENSE. <b>GEOSUL</b>: Revista do Departamento de
                Geociências, Florianópolis, v. 34, n. 71, p. 301-327, Abril
                2019. DOI https://doi.org/10.5007/1982-5153.2019v34n71p301.
                Disponível em:
                https://periodicos.ufsc.br/index.php/geosul/article/view/1982-5153.2019v34n71p301/39979
                . Acesso em: 24 maio 2024.
              </p>
              <p className='break-all'>
                SANTOS, Frednan Bezerra dos; ARRAIS NETO, Crisóstomo de Andrade;
                FERREIRA, Leticia Araujo.{' '}
                <b>
                  A EXPANSÃO DA SOJA NO MARANHÃO E ALGUMAS CONSEQUÊNCIAS
                  SOCIO-AMBIENTAIS
                </b>
                : questões preliminares (1990-2005). Anais IV Jornada
                Internacional de Políticas Públicas, [s. l.], 2009. Disponível
                em:
                https://www.joinpp.ufma.br/jornadas/joinppIV/OLD/eixos_OLD/8.%20Agricultura,%20Seguran%C3%A7
                a%20Alimentar%20e%20Meio%20Ambiente/A%20EXPANS%C3%83O%20DA%20SOJA%20NO%20MARANH%C3%83O%20E%20ALGUMAS%20CONSEQU%C3%8ANCIAS.pdf
                . Acesso em: 10 jun. 2024.
              </p>
            </div>
            <h3>Década de 2000</h3>
            <div className='text-justify text-[0.9rem]'>
              <p className='break-all'>
                AGOSTINHO, Luane Lemos Felício. As leis do Babaçu livre e o
                desenvolvimento econômico: Uma análise do conflito de interesses
                das regiões urbanas no Maranhão.{' '}
                <b>Revista de Políticas Públicas</b>, v. 14, p. 290-296, 11 dez
                2012. Disponívem em:
                https://periodicoseletronicos.ufma.br/index.php/rppublica/article/view/424
                . Acesso em: 20 maio 2024.
              </p>
              <p>
                BOLSA Família reduz pobreza e desigualdade em 15 anos. In:
                GOVERNO FEDERAL (Brasil). Instituto de Pesquisa Econômica
                Aplicada (coord.). <b>IPEA</b>. [<i>S. l.</i>], 22 set. 2021.
                Disponível em:
                https://www.ipea.gov.br/portal/categorias/45-todas-as-noticias/noticias/2386-bolsa-familia-reduz-pobreza-e-desigualdade-em-15-anos
                . Acesso em: 11 jun. 2024.
              </p>
              <p>
                CPT - Conflitos no Campo Brasil 1992. Centro de Documentação Dom
                Tomás Balduíno. Goiânia: CPT Nacional, 2003.
              </p>
              <p>
                IBAMA/MA. Maranhão ganha sua primeira reserva extrativista em
                área de cerrado: Chapada Limpa. <b>UC Socioambiental</b>, [
                <i>S. l.</i>
                ], 28 set. 2007. Unidades de Conservação no Brasil. Disponível
                em: https://uc.socioambiental.org/pt-br/noticia/49584 . Acesso
                em: 18 jun. 2024.
              </p>
              <p>
                IBGE (ed.). Bolsa Família aumenta a permanência de jovens na
                escola. <i>In</i>: <b>Casa Civil</b>. [<i>S. l.</i>], 22 set.
                2021. Disponível em:
                https://www.gov.br/casacivil/pt-br/assuntos/noticias/2015/setembro/bolsa-familia-aumenta-a-permanencia-de-jovens-na-escola
                . Acesso em: 11 jun. 2024.
              </p>
              <p className='break-all'>
                OLIVEIRA, Camilla Kathleen Gonçalves de; OLIVEIRA, Carla da
                Silva; PUNTEL, Juliana Furini de Vasconcellos; GONÇALVES, Luísa
                Gomes. Programa Bolsa Família: história, impactos e
                atualizações. <i>In</i>:<b>Politize</b>. [<i>S. l.</i>]:
                Instituto Mattos Filho, 2023. Disponível em:
                https://www.politize.com.br/direito-desenvolvimento/programa-bolsa-familia/#:~:text=Foi%20em%202003%20que%20o,Lei%20n%C2%BA%2010.836%2F2004)
                . Acesso em: 18 jun. 2024.
              </p>
              <p className='break-all'>
                PEREIRA, Eldyane Santos; SANTOS, Marcione Margarida da Silva;
                TARGA, Marcelo Santos. Impactos ambientais da usina hidrelétrica
                de Estreito, MA – Amazonia -Brasil: uma revisão integrativa.
                <b>Revista Técnica Ciências Ambientais</b>, Taubaté, v. 1, n. 7,
                10 set. 2023. Disponível em:
                https://ipabhi.org/repositorio/index.php/rca/article/view/97/110
                . Acesso em: 11 jun. 2024.
              </p>
              <p>
                TERRITÓRIOS LIVRES DO BAIXO PARNAÍBA. MA: Criação da Resex
                Chapada Limpa põe fim a conflitos na região. <b>Ecodebate</b>, [
                <i>S. l.</i>], 16 jun. 2015. Disponível em:
                https://www.ecodebate.com.br/2015/06/16/ma-criacao-da-resex-chapada-limpa-poe-fim-a-conflitos-na-regiao/
                . Acesso em: 18 jun. 2024.
              </p>
            </div>
            <h3>Década de 2010</h3>
            <div className='text-justify text-[0.9rem]'>
              <p className='break-all'>
                CASTRO, Mariana. Um ano após despejo violento, comunidade do
                Cajueiro (MA) resiste a ameaças. <b>Brasil de Fato</b>, 02 set.
                2020. Disponível
                em:https://www.brasildefato.com.br/2020/09/02/um-ano-apos-despejo-violento-comunidade-do-cajueiro-ma-resiste-a-ameacas#:~:text=
                Na%20%C3%A9poca%20do%20despejo%2C%20em,do%20territ%C3%B3rio%20e%20da%20vida
                . Acesso em: 20 mai 2024.
              </p>
              <p className='break-all'>
                CONSELHO INDIGENISTA MISSIONÁRIO. Acampamento Bem Viver no
                Incra/MA se encerrou com vitórias e uma Teia fortalecida.
                <b>Instituto Socio Ambiental</b>, [<i>S. l.</i>], 9 ago. 2024.
                Notícias, p. 165-180. Disponível em:
                https://acervo.socioambiental.org/acervo/noticias/acampamento-bem-viver-no-incrama-se-encerrou-com-vitorias-e-uma-teia-fortalecida
                . Acesso em: 21 jun. 2024.
              </p>
              <p>
                CRUZ, Antônio José Araújo; ANTIPON, Lívia Cangiano. USO DO
                TERRITÓRIO, INFORMAÇÃO E COMUNICAÇÃO NA METRÓPOLE DE SÃO LUÍS:
                EXPULSÃO E PERMANÊNCIA NA COMUNIDADE DO CAJUEIRO.{' '}
                <b>Geográfica</b>, p. 1019. 2020.
              </p>
              <p>
                EM BUSCA do bem viver. Direção: Murilo Santos. Produção:
                Coordenação Estadual das Pastorais Sociais e Secretariado
                Executivo do Regional Ne 5 da CNBB. Roteiro: Murilo Santos. São
                Luís: CNBB NEV, 2016. Disponível em:
                https://www.youtube.com/watch?v=TKMWvupNitI . Acesso em: 21 jun.
                2024.
              </p>
              <p>
                FUNDAÇÃO OSWALDO CRUZ. MA – Comunidades impactadas pela
                implementação do Complexo Termoelétrico Parnaíba exigem medidas
                de compensação e mitigação e indenizações por danos morais
                coletivos.
                <i>In</i>: <b>Mapa de conflitos</b>. [<i>S. l.</i>], 2015.
                Disponível em:
                https://mapadeconflitos.ensp.fiocruz.br/conflito/ma-comunidades-impactadas-pela-implementacao-do-complexo-termoeletrico-parnaiba-exigem-medidas-de-compensacao-e-mitigacao-e-indenizacoes-por-danos-morais-coletivos/
                . Acesso em: 24 jun. 2024.
              </p>
              <p>
                INSTITUTO HUMANITAS UNISINOS. Quilombolas que ocupam o Incra no
                MA iniciam greve de fome. <b>IHU - Adital</b>, [<i>S. l.</i>], 9
                ago. 2024. Notícias, p. p.252-280. Disponível em:
                https://www.ihu.unisinos.br/noticias/44190-quilombolas-que-ocupam-o-incra-no-ma-iniciam-greve-de-fome
                . Acesso em: 20 jun. 2024.
              </p>
              <p>
                SILVA, Karênina Fonsêca.{' '}
                <b>Desenvolvimento do Maranhão e a Refinaria Premium-I</b>: o
                processo de implantação do empreendimento e suas implicações no
                modo de vida e trabalho dos trabalhadores rurais de Bacabeira.
                2014. Dissertação (Pós-graduação em Desenvolvimento
                Socioespacial e Regional) - Universidade Estadual do Maranhão,
                São Luís, 2014. Disponível em:
                https://repositorio.uema.br/handle/123456789/511 . Acesso em: 11
                jun. 2024.
              </p>
              <p className='break-all'>
                SMOLII , Isabela. Violência contra índios Gamela no Maranhão
                causa espanto e desperta profundas questões territoriais e um
                firme posicionamento do Consea. <b>IPEA</b>, [<i>S. l.</i>], 4
                maio 2017. Participação em foco, p. notícias. Disponível em:
                https://www.ipea.gov.br/participacao/noticiasmidia/1575-violencia-gamela-maranhao
                . Acesso em: 25 jun. 2024.
              </p>
              <p>
                SPOSATI, Ruy. “Eles são mesmo índios?”, a pergunta por trás do
                ataque aos Gamela. <b>Brasil de fato</b>, 9 ago. 2024.
                Disponível em:
                https://www.brasildefato.com.br/2017/06/29/eles-sao-mesmo-indios-a-pergunta-por-tras-do-ataque-aos-gamela
                . Acesso em: 19 jun. 2024.
              </p>
            </div>
            <h3>Década de 2020</h3>
            <div className='text-justify text-[0.9rem]'>
              <p>
                CASTRO, Mariana. Morte de quebradeira de coco e filho esmagados
                por árvore revela impactos do agronegócio no MA.{' '}
                <b>Brasil de Fato</b>, 9 ago. 2024. Direitos Humanos, p.
                115-131. Disponível em:
                https://www.brasildefato.com.br/2021/11/15/morte-de-quebradeira-de-coco-e-filho-esmagados-por-arvore-revela-impactos-do-agronegocio-no-ma
                . Acesso em: 31 maio 2024.
              </p>
              <p>
                DEFENSORIA PÚBLICA DA UNIÃO. Corte IDH: Brasil pode ser
                condenado por crimes contra quilombolas de Alcântara (MA).{' '}
                <b>Direitos humanos. DPU</b>, 9 ago. 2024. Disponível em:
                https://direitoshumanos.dpu.def.br/corte-idh-brasil-pode-ser-condenado-por-crimes-contra-quilombolas-de-alcantara-ma/
                . Acesso em: 27 jun. 2024.
              </p>
              <p>
                SANTOS, Luiz Eduardo Neves dos. Cinismo, espetáculo e o “novo”
                Plano Diretor de São Luís. <b>Esquerda on line</b>, [
                <i>S. l.</i>
                ], p. editorial, 6 abr. 2022. Disponível em:
                https://esquerdaonline.com.br/2022/04/06/cinismo-espetaculo-e-o-novo-plano-diretor-de-sao-luis/
                . Acesso em: 17 jun. 2024.
              </p>
            </div>
          </div>
        </div>
        <div>
          <h2 className='col-span-full text-xl font-bold text-gray-900 text-center'>
            Financiamento
          </h2>
          <div className='text-center leading-3'>
            <p className='leading-4 md:leading-4'>
              Fundação de Amparo à Pesquisa e ao Desenvolvimento Científico e
              Tecnológico do Maranhão (FAPEMA) - Universal 06353/22
            </p>
            <p className='leading-4 md:leading-3'>
              Conselho Nacional de Desenvolvimento Científico e
              Tecnológico (CNPq)
            </p>
            <p>Universidade Federal do Maranhão (UFMA)</p>
          </div>
          <div className='flex md:flex-row flex-col md:justify-between my-8'>
            <div className='text-center leading-3 md:mb-0 mb-4'>
              <p>
                <b>Riordan dos Santos</b>
              </p>
              <p>riordan3607@gmail.com</p>
              <p>Desenvolvedor</p>
            </div>
            <div className='text-center leading-3 md:mb-0 mb-4'>
              <p>
                <b>Emanuelle Lemos</b>
              </p>
              <p>manuluz.lemos@gmail.com</p>
              <p>Desenvolvedora</p>
            </div>

            <div className='text-center leading-3 md:mb-0 mb-4'>
              <p>
                <b>Michael Aiko</b>
              </p>
              <p>aikocriacoes@gmail.com </p>
              <p>Ilustrador e Designer</p>
            </div>
          </div>
          <div className='flex justify-center mb-8'>
            <img
              src={logo_em_linha}
              alt='logo em linha'
              className='max-w-full w-[400px] mt-[-2rem]'
            />
          </div>
        </div>
      </Container>
    </>
  )
}
export default Creditos
