import CardMemoria from '../../../components/CardMemoria/CardMemoria'
import icone_homem from './assets/icone_homem.png'
import icone_mulher from './assets/icone_mulher.png'
import icone_chacina from './assets/icone_chacina.png'
export const data = [
  {
    key: 0,
    year: '1964',
    content: (
      <CardMemoria
        image={icone_homem}
        name='Antonio Fernandes de Sousa'
        year={1964}
        role='Líder Camponês'
        place='Povoado Coco - Tuntum'
      />
    )
  },
  {
    key: 1,
    year: '1972',
    content: (
      <CardMemoria
        image={icone_homem}
        name='João Maranhão'
        year={1972}
        role='Líder Camponês'
        place='Joselândia'
      />
    )
  },
  {
    key: 2,
    year: '1973',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Gil de Oliveira Campos'
          year={1973}
          role='Líder Camponês'
          place='Fazenda Barra - Caxias'
        />
        <CardMemoria
          image={icone_mulher}
          name='Dona Maria'
          year={1973}
          place='Fazenda Barra - Caxias'
        />
      </>
    )
  },
  {
    key: 3,
    year: '1974',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='João Palmeira Sobrinho'
          year={1974}
          role='Ex-presidente do STTR de Imperatriz'
          place='Fazenda Pindaré - Santa Luzia'
        />
        <CardMemoria
          image={icone_homem}
          name='José Viana'
          year={1974}
          role='Camponês'
          place='Fazenda Pindaré - Santa Luzia'
        />
        <CardMemoria
          image={icone_chacina}
          name='Cinco posseiros não identificados'
          year={1974}
          place='Centro do Zezinho -  João Lisboa'
        />
        <CardMemoria
          image={icone_homem}
          name='Posseiros não identificado'
          year={1974}
          place='Povoado Sabonete - Grajaú'
        />
        <CardMemoria
          image={icone_chacina}
          name='Onze posseiros não identificados'
          year={1974}
          place='São Pedro da Água Branca'
        />
      </>
    )
  },
  {
    key: 4,
    year: '1975',
    content: (
      <CardMemoria
        image={icone_homem}
        name='Zacarias Gomes da Silva'
        year={1975}
        place='Imperatriz'
      />
    )
  },
  {
    key: 5,
    year: '1976',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Francisco Cerqueira'
          year={1976}
          role='Camponês'
          place='Coquelândia - Imperatriz'
        />
        <CardMemoria
          image={icone_homem}
          name='Raimundo Costa'
          year={1976}
          place='Lago da Pedra'
        />
        <CardMemoria
          image={icone_homem}
          name='Anastácio Raimundo Costa'
          year={1976}
          place='Lago da Pedra'
        />
      </>
    )
  },
  {
    key: 6,
    year: '1977',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='José Ribamar Souza'
          year={1977}
          role='Camponês'
          place='Povoado Angical - Imperatriz'
        />
        <CardMemoria
          image={icone_homem}
          name='Francisco Pereira da Silva'
          year={1977}
          role='Camponês'
          place='Fazenda Pindaré - Santa Luzia'
        />
        <CardMemoria
          image={icone_homem}
          name='Manoel Odinei'
          year={1977}
          role='Camponês'
          place='São Pedro da Água Branca'
        />
        <CardMemoria
          image={icone_homem}
          name='Valdir '
          year={1977}
          role='Camponês'
          place='Ladeira Vermelha - Açailândia'
        />
      </>
    )
  },
  {
    key: 7,
    year: '1978',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Enoc Pessoa'
          year={1978}
          role='Camponês'
          place='Fazenda Pindaré - Santa Luzia'
        />
        <CardMemoria
          image={icone_homem}
          name='Antonio Batista da Rocha'
          year={1978}
          role='Camponês'
          place='Povoado Lagoa - Timbiras'
        />
        <CardMemoria
          image={icone_homem}
          name='Amadeu Manoel de Melo'
          year={1978}
          role='Camponês'
          place='Povoado Sucuruizinho - Santa Luzia'
        />
        <CardMemoria
          image={icone_mulher}
          name='Esposa do Sr. Amadeu Manoel de Melo'
          year={1978}
          role='Camponesa'
          place='Povoado Sucuruizinho - Santa Luzia'
        />
        <CardMemoria
          image={icone_homem}
          name='Hermínio Alves da Luz'
          year={1978}
          role='Camponês'
          place='Fazenda Maguary - Santa Luzia'
        />
        <CardMemoria
          image={icone_homem}
          name='Um camponês não identificado'
          year={1978}
          role='Camponês'
          place='Zona rural de Açailândia - Açailândia'
        />
      </>
    )
  },
  {
    key: 8,
    year: '1979',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='José Barroso'
          year={1979}
          role='Camponês'
          place='Morada Nova - Tuntum'
        />
        <CardMemoria
          image={icone_homem}
          name='Firmino Guerreiro dos Santos'
          year={1979}
          role='Camponês'
          place={
            <>
              Porto Franco do Caru <br /> São João do Caru
            </>
          }
        />
        <CardMemoria
          image={icone_homem}
          name='Uma criança não identificada'
          year={1979}
          place='Palmeiras - Esperantinópolis'
        />
        <CardMemoria
          image={icone_homem}
          name='Eliseu Possione'
          year={1979}
          role='Camponês'
          place='Esparantinopólis'
        />
        <CardMemoria
          image={icone_homem}
          name='Eduardo Resende de Souza'
          year={1979}
          role='Camponês'
          place='Tuntum'
        />
        <CardMemoria
          image={icone_homem}
          name='camponês não identificado'
          year={1979}
          role='Camponês'
          place='Estaca Zero - Vitória do Mearim'
        />
        <CardMemoria
          image={icone_homem}
          name='Camponês não identificado'
          year={1979}
          role='Camponês'
          place='Coelho Neto'
        />
        <CardMemoria
          image={icone_homem}
          name='Camponês não identificado'
          year={1979}
          role='Camponês'
          place='Cajapió'
        />
        <CardMemoria
          image={icone_homem}
          name='Camponês não identificado'
          year={1979}
          role='Camponês'
          place='Barra do Corda'
        />
        <CardMemoria
          image={icone_homem}
          name='Camponês não identificado'
          year={1979}
          role='Camponês'
          place='Santa Luzia'
        />
        <CardMemoria
          image={icone_homem}
          name='Hermínio Alves da Luz'
          year={1979}
          role='Camponês'
          place='Centro dos Brabos - Santa Luzia'
        />
        <CardMemoria
          image={icone_homem}
          name='Miguel Tributina'
          year={1979}
          place='Bacabal'
        />
      </>
    )
  },
  {
    key: 9,
    year: '1980',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Francisco Jesus da Silva'
          year={1980}
          role='Camponês'
          place='Povoado Lagoinha - Esperantinopólis'
        />
        <CardMemoria
          image={icone_homem}
          name='João Antero da Silva'
          year={1980}
          role='Dirigente Sindical'
          place='Tuntum'
        />
        <CardMemoria
          image={icone_homem}
          name='Antônio Genésio Veras'
          year={1980}
          role='Camponês'
          place='Brejona - Parnarama'
        />
        <CardMemoria
          image={icone_homem}
          name='José Bertolino'
          year={1980}
          role='Camponês'
          place='Parnarama'
        />
        <CardMemoria
          image={icone_homem}
          name='Cícero Catarino'
          year={1980}
          role='Camponês'
          place='Povoado João Vito - Parnarama'
        />
        <CardMemoria
          image={icone_homem}
          name='Napoleão'
          year={1980}
          role='Camponês'
          place='Belmonte - Caxias'
        />
        <CardMemoria
          image={icone_homem}
          name='Um camponês não identificado'
          year={1980}
          role='Camponês'
          place='São Pedro da Água Branca'
        />
        <CardMemoria
          image={icone_homem}
          name='José Martins de Souza'
          year={1980}
          role='Camponês'
          place='São Pedro da Água Branca'
        />
      </>
    )
  },
  {
    key: 10,
    year: '1981',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Marcelo dos Santos'
          year={1981}
          role='Delegado sindical'
          place='Codó'
        />
        <CardMemoria
          image={icone_homem}
          name='Edson Rodrigues Moreira'
          year={1981}
          place='Santa Luzia'
        />
      </>
    )
  },
  {
    key: 11,
    year: '1982',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='José Antônio Cruz'
          year={1982}
          role='Camponês'
          place='Paru - Viana'
        />
        <CardMemoria
          image={icone_homem}
          name='Baltazar Carneiro'
          year={1982}
          role='Camponês'
          place='Buritizinho - Sucupira do Norte'
        />
        <CardMemoria
          image={icone_homem}
          name='João Carneiro'
          year={1982}
          role='Camponês'
          place='Turiaçu'
        />
        <CardMemoria
          image={icone_homem}
          name='Luís Viana'
          year={1982}
          role='Camponês'
          place='Nazaré - São Domingos'
        />
        <CardMemoria
          image={icone_homem}
          name='Elias Zi Costa'
          year={1982}
          role='Líder sindical e Presidente do STTR de Santa Luzia'
        />
        <CardMemoria
          image={icone_homem}
          name='João Brito'
          year={1982}
          place='Pio XII'
        />
        <CardMemoria
          image={icone_homem}
          name='Cícero Reinaldo de Souza'
          year={1982}
          role='Camponês'
          place='Anajá - Grajaú'
        />
        <CardMemoria
          image={icone_chacina}
          name='Um bebê e dois posseiros não identificados'
          year={1982}
          place='Anajá, Fazenda Flexal - Grajaú'
        />
        <CardMemoria
          image={icone_chacina}
          name='3 posseiros não identificados'
          year={1982}
          place='Cinturão Verde'
        />
      </>
    )
  },
  {
    key: 12,
    year: '1983',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Aristides Texeira Santos'
          year={1983}
          role='Camponês'
          place='Santa Tereza - Brejo'
        />
        <CardMemoria
          image={icone_homem}
          name='João José de Lima'
          year={1983}
          role='Camponês'
          place='Bacabal'
        />
        <CardMemoria
          image={icone_homem}
          name='João Alves de Lima'
          year={1983}
          place='Sítio Novo - Bacabal'
        />
        <CardMemoria
          image={icone_homem}
          name='Raimundo Nonato Lopes'
          year={1983}
          place='Cumbique - Paço do Lumiar'
        />
        <CardMemoria
          image={icone_homem}
          name='Geraldo Alves Mesquita'
          year={1983}
          place='Arame - Grajaú'
        />
        <CardMemoria
          image={icone_homem}
          name='Paulo Caetano da Silva'
          year={1983}
          role='Camponês'
          place='Arame - Grajaú'
        />
        <CardMemoria
          image={icone_homem}
          name='Benedito Rodrigues Ramos'
          year={1983}
          role='Liderança Rural'
          place='Surrão - Urbano Santos'
        />
        <CardMemoria
          image={icone_mulher}
          name='Raimunda Ramos'
          year={1983}
          role='Liderança Rural'
          place='Surrão - Urbano Santos'
        />
        <CardMemoria
          image={icone_homem}
          name='José Custodio'
          year={1983}
          role='Camponês'
          place='Lago do Mato - Governador Acher'
        />
        <CardMemoria
          image={icone_homem}
          name='Francisco Alves Costa'
          year={1983}
          role='Camponês'
          place='Paiol de Centro - Parnarama'
        />
      </>
    )
  },
  {
    key: 13,
    year: '1984',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Domingos Silva'
          year={1984}
          place='Santa Helena'
        />

        <CardMemoria
          image={icone_homem}
          name='Dalvino Severino da Conceição'
          year={1984}
          place='Tijupá Queimado - São José de Ribamar'
        />

        <CardMemoria
          image={icone_homem}
          name='Mateus Loiola de Souza'
          year={1984}
          place='Tijupá Queimado - São José de Ribamar'
        />

        <CardMemoria
          image={icone_homem}
          name='Julião Pinto de Souza'
          year={1984}
          place='Juçaral - Lago Verde'
          role='Camponês'
        />

        <CardMemoria
          image={icone_homem}
          name='Bento Alves de Lima'
          year={1984}
          place='Juçaral - Lago Verde'
          role='Camponês'
        />

        <CardMemoria
          image={icone_homem}
          name='Roque Diniz (Castro)'
          year={1984}
          place='Juçaral - São Vicente Férrer'
          role='Liderança Rural'
        />

        <CardMemoria
          image={icone_homem}
          name='Alzecindo da Mata Vila Nova'
          year={1984}
          place='Piquiá - Açailândia'
          role='Liderança Rural'
        />

        <CardMemoria
          image={icone_homem}
          name='Benedito Raquel Mendes'
          year={1984}
          place='Fazenda Sapucaia - Santa Luzia'
          role='Camponês'
        />

        <CardMemoria
          image={icone_homem}
          name='Acelino Raquel'
          year={1984}
          place='Fazenda Sapucaia - Santa Luzia'
          role='Camponês'
        />

        <CardMemoria
          image={icone_homem}
          name='José Machado'
          year={1984}
          place='Juçaral do Vital - PIO XII'
          role='Liderança Rural'
        />

        <CardMemoria
          image={icone_homem}
          name='Cantídio Diniz'
          year={1984}
          place='Cardeais - Joselândia'
          role='Líder Sindical'
        />

        <CardMemoria
          image={icone_homem}
          name='Um lavrador não identificado'
          year={1984}
          place='Pinheiro'
          role='Lavrador'
        />

        <CardMemoria
          image={icone_homem}
          name='Francisco Rodrigues Batista'
          year={1984}
          place='Macaúba - Coroatá'
        />

        <CardMemoria
          image={icone_homem}
          name='Raimundo Alves da Silva (Nonatinho)'
          year={1984}
          place='Santa Luzia'
          role='Líder Sindical'
        />

        <CardMemoria
          image={icone_homem}
          name='Artur Alves'
          year={1984}
          place='Codó'
          role='Camponês'
        />

        <CardMemoria
          image={icone_chacina}
          name='José de Ribamar Mendes e um desconhecido'
          year={1984}
          place='Jaibara - Itapecuru'
        />

        <CardMemoria
          image={icone_homem}
          name='André Carneiro'
          year={1984}
          place='Agroceres - Turiaçu'
          role='Camponês'
        />
      </>
    )
  },
  {
    key: 14,
    year: '1985',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Antonio Cirilo'
          year={1985}
          place='Agroceres - Turiaçu'
          role='Camponês'
        />

        <CardMemoria
          image={icone_homem}
          name='José Alexandre'
          year={1985}
          place='Agroceres - Turiaçu'
          role='Camponês'
        />

        <CardMemoria
          image={icone_homem}
          name='José (Zezinho Careca)'
          year={1985}
          place='Fazenda Faisa - Santa Luzia'
        />

        <CardMemoria
          image={icone_homem}
          name='José Irias'
          year={1985}
          place='Quinta - São Luiz Gonzaga'
          role='Camponês'
        />

        <CardMemoria
          image={icone_homem}
          name='Jonas Alexandre'
          year={1985}
          place='Dom Pedro'
        />

        <CardMemoria
          image={icone_homem}
          name='Francisco Ferreira da Silva'
          year={1985}
          place='Centro do Cajueiro do Careca - Joselândia'
        />

        <CardMemoria
          image={icone_homem}
          name='José Alexandre'
          year='17/02/1985'
          place='Morada Nova - Santa Helena'
          role='Posseiro'
        />

        <CardMemoria
          image={icone_homem}
          name='Pedro Texeira'
          year='02/03/1985'
          place='Quinta - São Luiz Gonzaga'
          role='Lavrador'
        />

        <CardMemoria
          image={icone_homem}
          name='José Rodrigues Santos'
          year='06/06/1985'
          place='Santa Maria - Rosário'
          role='Lavrador'
        />
        <CardMemoria
          image={icone_homem}
          name='José Careca'
          year='16/06/1985'
          place='Arapari - Santa Luzia'
          role='Lavrador'
        />

        <CardMemoria
          image={icone_homem}
          name='Valetin'
          year='16/06/1985'
          place='Arapari - Santa Luzia'
          role='Lavrador'
        />

        <CardMemoria
          image={icone_homem}
          name='Francisco Sousa'
          year='19/06/1985'
          place='Maria Preta - Colinas'
          role='Lavrador'
        />

        <CardMemoria
          image={icone_homem}
          name='Abílio Muniz'
          year='02/07/1985'
          place='Codó'
          role='Posseiro'
        />

        <CardMemoria
          image={icone_homem}
          name='Domingos Gomes de Melo'
          year='19/07/1985'
          place='Jussara - Timbiras'
          role='Lavrador'
        />

        <CardMemoria
          image={icone_homem}
          name='Gonçalo F. Gomes Sousa'
          year='11/08/1985'
          place='Santa Teresa - Lago da Pedra'
          role='Líder Sindical'
        />

        <CardMemoria
          image={icone_homem}
          name='José Ribamar Sousa'
          year='21/08/1985'
          place='Maguari - Monção'
          role='Líder Sindical'
        />

        <CardMemoria
          image={icone_homem}
          name='Domingos Abreu Marques'
          year='05/09/1985'
          place='Fazenda Cachimbó - Coroatá'
          role='Posseiro'
        />

        <CardMemoria
          image={icone_homem}
          name='Manoel Ferreira de Souza'
          year='05/09/1985'
          place='Fazenda Cachimbó - Coroatá'
          role='Posseiro'
        />

        <CardMemoria
          image={icone_homem}
          name='Antônio Ferreira Silva'
          year='17/09/1985'
          place='Arapari - Santa Luzia'
          role='Líder Sindical'
        />

        <CardMemoria
          image={icone_homem}
          name='Antônio Inácio'
          year='17/09/1985'
          place='Moriá - Santa Luzia'
          role='Lavrador'
        />

        <CardMemoria
          image={icone_homem}
          name='José Luís Moraes'
          year='18/09/1985'
          place='Fazenda B. de Bolonho - Cajari'
          role='Lavrador'
        />

        <CardMemoria
          image={icone_homem}
          name='Antônio Pedro Rocha'
          year='24/09/1985'
          place='Alvoredo - Coroatá'
          role='Lavrador'
        />

        <CardMemoria
          image={icone_homem}
          name='Antenor Sena de Freitas'
          year='03/10/1985'
          place='Fazenda Capoema - Santa Luzia/ Buriticupu'
          role='Posseiro'
        />

        <CardMemoria
          image={icone_homem}
          name='Francisco Teodoro da Silva'
          year='03/10/1985'
          place='Fazenda Capoema - Santa Luzia/Buriticupu'
          role='Posseiro'
        />

        <CardMemoria
          image={icone_homem}
          name='Manoel Monteiro de Sousa'
          year='23/11/1985'
          place='Pau Santo - Bacabal'
          role='Posseiro'
        />
      </>
    )
  },
  {
    key: 15,
    year: '1986',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Claudiomar Rodrigues de Souza'
          year='05/02/1986'
          place='Centro de Imperatriz - Imperatriz'
          role='Comerciante'
        />

        <CardMemoria
          image={
            'https://res.cloudinary.com/dbnsmasqe/image/upload/v1716657318/em_memoria/10_05_86_biqcvi.png'
          }
          name='Pe. Josimo Morais Tavares'
          year='10/05/1986'
          place='Imperatriz'
          role='Padre CPT'
        />

        <CardMemoria
          image={icone_homem}
          name='Antônio Fortenel Araújo'
          year='17/05/1986'
          place='Centro do Aguiar - Lago do Junco'
          role='Sindicato'
        />

        <CardMemoria
          image={icone_homem}
          name='José Inácio da Silva Filho'
          year='21/05/1986'
          place='Timon'
          role='Pastor - Igreja Batista'
        />

        <CardMemoria
          image={icone_homem}
          name='Francisco Alves de Souza'
          year='05/06/1986'
          place='Tanque - Codó'
          role='Lavrador'
        />

        <CardMemoria
          image={icone_homem}
          name='Teodoro'
          year='20/07/1986'
          place='Cândido Mendes'
        />

        <CardMemoria
          image={icone_homem}
          name='Agenor Costa Rodrigues'
          year='07/08/1986'
          place='Vila Nova dos Mártires - Imperatriz'
          role='Lavrador'
        />
      </>
    )
  },
  {
    key: 16,
    year: '1987',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Edson Carvalho de Araújo'
          year='04/02/1987'
          role='Lavrador'
          place='Assassinato por Conflito de Terra - Lago da Pedra'
        />

        <CardMemoria
          image={icone_homem}
          name='Antônio Alves Oliveira'
          year='01/03/1987'
          role='Lavrador'
          place='Conflito por Terra - Luís Domingues'
        />

        <CardMemoria
          image={icone_homem}
          name='Raimundo de Jesus Silva'
          year='12/03/1987'
          role='Dirigente do Movimento Sindical'
          place='Assassinato causado por conflito por terra - Bom Jardim'
        />

        <CardMemoria
          image={icone_homem}
          name='Tomé Serra'
          year='06/04/1987'
          role='Lavrador'
          place='Conflito por terra - Cajapió'
        />

        <CardMemoria
          image={icone_homem}
          name='José de Ribamar Marques'
          year='29/04/1987'
          role='Trabalhador Rural'
          place='Conflito Trabalhista - Empresa Ceres - Turiaçu'
        />

        <CardMemoria
          image={icone_homem}
          name='Agostinho Leite'
          year='18/06/1987'
          role='Lavrador'
          place='Pov Abundância e Santa Vitória/ Conflito por Terra - Timbiras'
        />

        <CardMemoria
          image={icone_homem}
          name='Milton Souza Cruz'
          year='09/07/1987'
          role='Lavrador'
          place='Conflito por Terra - São Luís Gonzaga'
        />

        <CardMemoria
          image={icone_homem}
          name='Gedeão Lustosa Ribeiro'
          year='23/07/1987'
          role='Presidente do Sindicato Trabalhista Rural'
          place='Assassinato do Presidente Sindical Trabalhista rural por conflitos por terra - Igarapé Grande'
        />

        <CardMemoria
          image={icone_homem}
          name='Elias Alves dos Santos'
          year='30/08/1987'
          role='Lavrador'
          place='Conflito por Terra - Paulo Ramos'
        />

        <CardMemoria
          image={icone_homem}
          name='Nestor de Sousa Vaz'
          year=' 10/1987'
          role='Lavrador'
          place='Assassinado na Fazenda Belém por conflito por terra - Buriti'
        />

        <CardMemoria
          image={icone_homem}
          name='Pedro Mota da Silva'
          year='18/11/1987'
          role='Lavrador'
          place='Assassinado na Fazenda Cachucha por conflito por terra - Coroatá'
        />

        <CardMemoria
          image={icone_homem}
          name='Raimundo R. dos Santos'
          year=' 11/1987'
          role='Lavrador'
          place='Conflito por terra - Coroatá'
        />
      </>
    )
  },
  {
    key: 17,
    year: '1988',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Criança (Menor 7 anos)'
          year='06/01/1988'
          place='Assassinada por policiais militares - Santa Luzia'
        />

        <CardMemoria
          image={icone_homem}
          name='Manoel Neto Pereira'
          year='22/01/1988'
          role='Posseiro'
          place='Bacabal'
        />

        <CardMemoria
          image={icone_homem}
          name='Basílio da Conceição'
          year='14/02/1988'
          role='Posseiro'
          place='Bacabal'
        />

        <CardMemoria
          image={icone_homem}
          name='João Batista Campos'
          year='19/02/1988'
          role='Posseiro'
          place='Santa Luzia'
        />

        <CardMemoria
          image={icone_homem}
          name='Doroteu Fortes de Sousa'
          year='30/08/1988'
          role='Posseiro'
          place='Esperantinópolis'
        />

        <CardMemoria
          image={icone_homem}
          name='Criança (06 meses)'
          year='1988'
          place='Assassinada por um pistoleiro - Vitória do Mearim'
          role='Filho de posseiros'
        />

        <CardMemoria
          image={icone_homem}
          name='Posseiro não identificado'
          year='1988'
          role='Posseiro'
          place='Santa Luzia'
        />
      </>
    )
  },
  {
    key: 18,
    year: '1989',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='José Pereira'
          year='29/06/1989'
          role='Posseiro'
          place='Vitória do Mearim'
        />

        <CardMemoria
          image={icone_homem}
          name='José Rocha Moraes'
          year='27/07/1989'
          role='Dirigente do Sindicato dos Trabalhadores Rurais'
          place='São Luís Gonzaga'
        />

        <CardMemoria
          image={icone_homem}
          name='Raimundo Procópio'
          year='23/08/1989'
          role='Posseiro'
          place='Grajaú'
        />

        <CardMemoria
          image={icone_homem}
          name='Eurico Paulo dos Santos'
          year='23/08/1989'
          role='Posseiro'
          place='Grajaú'
        />

        <CardMemoria
          image={icone_homem}
          name='Ananias G. do Nascimento'
          year='29/09/1989'
          role='Pequeno Produtor'
          place='Rosário, Anaj. e Santa Rita'
        />

        <CardMemoria
          image={icone_homem}
          name='Maria B. Pinheiro'
          year='07/11/1989'
          role='Posseira'
          place='Grajaú'
        />
      </>
    )
  },
  {
    key: 19,
    year: '1990',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Valter Baiema Silva'
          year='03/02/1990'
          role='Posseiro'
          place='Conflito por terra - Bom Jardim'
        />

        <CardMemoria
          image={icone_homem}
          name='Raimundo João Sabino'
          year='08/02/1990'
          role='Posseiro'
          place='Conflito por terra - Santa Luzia'
        />

        <CardMemoria
          image={icone_homem}
          name='Raimundo Nonato Souza'
          year='03/04/1990'
          role='Dirigente Sindical'
          place='Conflito por terra - Bacabal'
        />

        <CardMemoria
          image={icone_homem}
          name='Aldionor Cardoso da Silva'
          year='01/06/1990'
          role='Posseiro'
          place='Conflito por terra - Santa Luzia'
        />

        <CardMemoria
          image={icone_homem}
          name='Um posseiro'
          year='12/10/1990'
          role='Posseiro'
          place='Conflito por terra - Santa Luzia'
        />

        <CardMemoria
          image={icone_homem}
          name='Gentil Rodrigues da Silva'
          year='24/10/1990'
          role='Posseiro'
          place='Conflito por terra - Brejo'
        />

        <CardMemoria
          image={icone_homem}
          name='Armando Oliveira Viana'
          year='24/10/1990'
          role='Posseiro'
          place='Conflito por terra - Brejo'
        />

        <CardMemoria
          image={icone_homem}
          name='Alonso Silvestre Gomes'
          year='19/11/1990'
          role='Dirigente Sindical'
          place='Conflito por terra - São Mateus do Maranhão'
        />
      </>
    )
  },
  {
    key: 20,
    year: '1991',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Valdimiro Pereira C. Sales'
          year='14/04/1991'
          role='Posseiro'
          place='Morros'
        />

        <CardMemoria
          image={icone_homem}
          name='José Claudio'
          year='10/06/1991'
          role='Posseiro'
          place='Joselândia'
        />

        <CardMemoria
          image={icone_homem}
          name='Um Índio Guajajara'
          year='/07/1991'
          role='Indigena'
          place='Grajaú'
        />

        <CardMemoria
          image={icone_homem}
          name='Um Índio Gavião'
          year='/08/1991'
          role='Indigena'
          place='Amarante do Maranhão'
        />

        <CardMemoria
          image={icone_homem}
          name='Manoel da Conceição'
          year='/08/1991'
          role='Camponês'
          place='Olho D’Água da Cunhãs'
        />

        <CardMemoria
          image={icone_homem}
          name='Raimundo Mangueira'
          year='11/08/1991'
          role='Pequeno Proprietário'
          place='Chapadinha'
        />

        <CardMemoria
          image={icone_homem}
          name='Um Índio Guajá'
          year='04/09/1991'
          role='Indigena'
          place='Bom Jardim'
        />

        <CardMemoria
          image={icone_chacina}
          name='Três Índios Guajá'
          year='12/09/1991'
          role='Indigenas'
          place='Bom Jardim'
        />

        <CardMemoria
          image={icone_homem}
          name='Valdemiro Souza-Guajajara'
          year='30/09/1991'
          role='Indigena'
          place='Grajaú'
        />

        <CardMemoria
          image={icone_homem}
          name='Francisco de A. Ferreira'
          year='05/11/1991'
          role='Posseiro'
          place='Codó'
        />

        <CardMemoria
          image={icone_homem}
          name='Antônio Tavares Roque'
          year='23/12/1991'
          role='Pequeno Proprietário'
          place='Santa Rita'
        />
      </>
    )
  },
  {
    key: 21,
    year: '1992',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='José Jorge Andrade (Paraíba)'
          year='22/02/1992'
          role='Posseiro'
          place='Conflito por terra - Imperatriz'
        />

        <CardMemoria
          image={icone_homem}
          name='Pedro Araújo Texeira'
          year='13/04/1992'
          role='Dirigente Sindical'
          place='Conflito por terra - São Mateus'
        />

        <CardMemoria
          image={icone_homem}
          name='Severo da Silva'
          year='19/05/1992'
          role='Lavrador'
          place='Conflito por terra - Santa Luzia'
        />

        <CardMemoria
          image={icone_homem}
          name='Juvenal Ferreira Lima'
          year='31/10/1992'
          role='Posseiro'
          place='Conflito por terra - Santa Luzia'
        />

        <CardMemoria
          image={icone_homem}
          name='Domingos Mendes Martins'
          year='02/11/1992'
          role='Posseiro'
          place='Conflito por terra - Grajaú'
        />

        <CardMemoria
          image={icone_homem}
          name='Francisco Gerônimo da Silva'
          year='03/12/1992'
          role='Dirigente do Sindicato'
          place='Conflito por terra - Imperatriz'
        />

        <CardMemoria
          image={icone_homem}
          name='Manoel de Jesus'
          year='24/12/1992'
          role='Posseiro'
          place='Conflito por terra - Vitória do Mearim'
        />

        <CardMemoria
          image={icone_homem}
          name='Augusto Pereira'
          year='1992'
          role='Indigena Guajajara'
          place='Cana Brava/Conflito por terra - Água Doce do Maranhão'
        />

        <CardMemoria
          image={icone_homem}
          name='Roberval'
          year='1992'
          role='Indigena Guajajara'
          place='Araribóia/Conflito por terra - Amarante do Maranhão'
        />

        <CardMemoria
          image={icone_homem}
          name='Francisca Carlos'
          year='1992'
          role='Indigena Guajajara'
          place='Morro Branco/Crime Passional - Paulino Neves'
        />
      </>
    )
  },
  {
    key: 22,
    year: '1993',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='José Miguel Pinto Morais'
          year='14/05/1993'
          role='Presidente do Sindicato'
          place='Grajaú'
        />

        <CardMemoria
          image={icone_homem}
          name='Raimundo Reis'
          year='29/06/1993'
          role='Dirigente do Sindicato'
          place='Turiaçú'
        />
      </>
    )
  },
  {
    key: 23,
    year: '1994',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Manoel Arari'
          year='11/01/1994'
          role='Líder Sindical'
          place='Santa Luzia'
        />

        <CardMemoria
          image={icone_homem}
          name='Djalma Soares Penha'
          year='02/06/1994'
          role='Posseiro'
          place='Penalva'
        />

        <CardMemoria
          image={icone_homem}
          name='Valdir Delgado'
          year='18/08/1994'
          role='Posseiro'
          place='Lago da Pedra'
        />

        <CardMemoria
          image={icone_homem}
          name='Joaquim Neo Gomes'
          year='16/09/1994'
          role='Posseiro'
          place='Imperatriz'
        />

        <CardMemoria
          image={icone_homem}
          name='Valdivino Mendes'
          year='22/10/1994'
          role='Posseiro'
          place='Viana'
        />

        <CardMemoria
          image={icone_homem}
          name='Francisco Carvalho'
          year='25/11/1994'
          role='Trabalhador Rural'
          place='Coroatá'
        />

        <CardMemoria
          image={icone_homem}
          name='Francisco Rodrigues'
          year='25/11/1994'
          role='Vereador/ Pequeno Comerciante'
          place='Coroatá'
        />

        <CardMemoria
          image={icone_homem}
          name='Josiel Alves Lima'
          year='08/12/1994'
          role='Liderança- Sem terra'
          place='Lago da Pedra'
        />
      </>
    )
  },
  {
    key: 24,
    year: '1995',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Raimundo Nonato de Jesus'
          year='10/01/1995'
          role='Posseiro'
          place='Santa Luzia'
        />

        <CardMemoria
          image={icone_homem}
          name='Mateus Marques Bastos'
          year='22/11/1995'
          role='Pequeno proprietário'
          place='Lago Verde'
        />

        <CardMemoria
          image={icone_homem}
          name='Felício Medeiros Filho'
          year='04/12/1995'
          role='Assentado'
          place='Carutapera'
        />
      </>
    )
  },
  {
    key: 25,
    year: '1996',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='José Domingos Bezerra'
          year='11/06/1996'
          role='Sem Terra'
          place='Santa Luzia'
        />

        <CardMemoria
          image={icone_homem}
          name='Antônio Silva'
          year='23/07/1996'
          role='Sem Terra'
          place='Imperatriz'
        />

        <CardMemoria
          image={icone_homem}
          name='Domingos Sousa Brito Neto'
          year='18/08/1996'
          role='Sem Terra'
          place='João Lisboa'
        />

        <CardMemoria
          image={icone_homem}
          name='Américo Araújo Martins'
          year='25/09/1996'
          role='Assentado'
          place='Santa Luzia'
        />
      </>
    )
  },
  {
    key: 26,
    year: '1997',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Francisco da Conceição (Chicão)'
          year='08/10/1997'
          role='Sem Terra'
          place='Açailândia'
        />
      </>
    )
  },
  {
    key: 27,
    year: '1998',
    content: (
      <>
        <CardMemoria
          image={icone_mulher}
          name='Mª Conceição R. de J. Souza'
          year='04/01/1998'
          role='Pequena proprietária'
          place='Itapecuru Mirim'
        />

        <CardMemoria
          image={icone_homem}
          name='Gonçalo Costa da Silva'
          year='20/03/1998'
          role='Assentado'
          place='Alto Alegre - Santa Luzia'
        />

        <CardMemoria
          image={icone_homem}
          name='Adalberto Reis Silva'
          year='12/04/1998'
          role='Posseiro'
          place='Alto Alegre'
        />

        <CardMemoria
          image={icone_homem}
          name='Manoel Sebastião Pereira'
          year='12/07/1998'
          role='Sem terra'
          place='Bom Jardim - Santa Luzia'
        />
      </>
    )
  },
  {
    key: 28,
    year: '1999',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Assunção Ferreira da Silva'
          year='17/04/1999'
          role='Sem Terra'
          place='Fernando Falcão'
        />
      </>
    )
  },
  {
    key: 29,
    year: '2001',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='José de Jesus Santana'
          year='02/05/2001'
          role='Posseiro'
          place='Chapadinha'
        />

        <CardMemoria
          image={icone_homem}
          name='Eliézer Cândido Costa'
          year='06/05/2001'
          role='Assentado'
          place='Pio XII'
        />
      </>
    )
  },
  {
    key: 30,
    year: '2003',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Regis'
          year='04/04/2003'
          role='Assentado'
          place='P.A. Lago Azul/Pov. Real Brasil - Buriticupu'
        />

        <CardMemoria
          image={icone_homem}
          name='Raimundo de Aquino Filho'
          year='09/07/2003'
          role='Posseiro'
          place='Povoado Comboio - Bacabal'
        />

        <CardMemoria
          image={icone_homem}
          name='Antônio Gregório da Conceição'
          year='09/07/2003'
          role='Posseiro'
          place='Povoado Comboio - Bacabal'
        />

        <CardMemoria
          image={icone_homem}
          name='Antônio Pires dos Reis'
          year='27/09/2003'
          role='Posseiro'
          place='Povoado São Felipe - Urbano Santos'
        />
      </>
    )
  },
  {
    key: 31,
    year: '2004',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Evaldo'
          year='06/02/2004'
          role='Assentado'
          place='P.A. Mangueira/Pov. Vila Boa Esperança - Nina Rodrigues'
        />
        <CardMemoria
          image={icone_homem}
          name='José Borges da Silva'
          year='21/04/2004'
          role='Posseiro'
          place='Povoado Jaburu - Aldeias Altas'
        />
      </>
    )
  },
  {
    key: 32,
    year: '2006',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='José Nascimento da Costa (Zé Vaqueiro)'
          year='15/08/2006'
          role='Pequeno Proprietário'
          place='Povoado São Joaquim - Tuntum'
        />

        <CardMemoria
          image={icone_mulher}
          name='Rosa Pereira Macedo'
          year='15/08/2006'
          role='Pequeno Proprietário'
          place='Povoado São Joaquim - Tuntum'
        />
      </>
    )
  },
  {
    key: 33,
    year: '2007',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Alfredo Pereira'
          year='28/08/2007'
          role='Pequeno Proprietário'
          place='Pov. Vergel/ Vegel/ Fazenda Boa Esperança - Codó'
        />

        <CardMemoria
          image={icone_homem}
          name='Tomé Guajajara'
          year='15/10/2007'
          role='Liderança Indígena'
          place='T.I. Araribóia/ 92 Aldeias das Etnias Guajajara - Gavião e Guajá, Buritirana/ Buriticupu/ Bom Jesus das Selvas/ Arame/ Amarante do Maranhão'
        />

        <CardMemoria
          image={icone_homem}
          name='José Antônio de Sousa (Lourenço)'
          year='19/10/2007'
          role='Sindicalista'
          place='Comunidade Estrela de Santana - Viana'
        />
      </>
    )
  },
  {
    key: 34,
    year: '2008',
    content: (
      <>
        <CardMemoria
          image={icone_mulher}
          name='Maria dos Anjos Guajajara'
          year='05/05/2008'
          role='Indígena'
          place='T.I. Araribóia/ 92 Aldeias das Etnias Guajajara, Gavião e Guajá, Buritirana/ Buriticupu/ Bom Jesus das Selvas/ Arame/ Amarante do Maranhão'
        />
      </>
    )
  },
  {
    key: 35,
    year: '2009',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Agenor de Sousa Pereira'
          year='07/08/2009'
          role='Quilombola'
          place='Comunidade Quilombola de Santarém - São Luís Gonzaga do Maranhão'
        />
      </>
    )
  },
  {
    key: 36,
    year: '2010',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Raimundo Pereira da Silva (Raimundo Chagas)'
          year='14/01/2010'
          role='Liderança'
          place='Pov. Vergel/ Vegel/ Fazenda Boa Esperança - Codó'
        />

        <CardMemoria
          image={icone_homem}
          name='Francisco Ribeiro Viana'
          year='20/04/2010'
          role='Liderança'
          place='Assentamento Flechal - Santa Luzia'
        />

        <CardMemoria
          image={icone_homem}
          name='Elias Ximenes Ferreira'
          year='22/09/2010'
          role='Liderança'
          place='Povoado Curva/ Data Timbaúba - São Mateus do Maranhão'
        />

        <CardMemoria
          image={icone_homem}
          name='Flaviano Pinto Neto'
          year='30/10/2010'
          role='Liderança'
          place='Comunidade Quilombola de Charco - São Vicente Ferrer'
        />
      </>
    )
  },
  {
    key: 37,
    year: '2011',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Cícero Felipe da Silva (Cícero Palácio)'
          year='06/02/2011'
          role='Liderança'
          place='P. A. Rosa Saraiva - Santa Luzia'
        />

        <CardMemoria
          image={icone_homem}
          name='Tazirã Ka\ apor'
          year='31/03/2011'
          role='Indígena'
          place='T. I. Alto Turiaçu - Centro do Guilherme'
        />

        <CardMemoria
          image={icone_homem}
          name='João Conceição da Silva'
          year='24/09/2011'
          role='Assentado'
          place='Assentamento Flechal - Santa Luzia'
        />

        <CardMemoria
          image={icone_homem}
          name='Valdenilson Borges'
          year='02/10/2011'
          role='Liderança Quilombola'
          place='Quilombo Rosário - Serrano do Maranhão'
        />

        <CardMemoria
          image={icone_homem}
          name='Criança Awá-Guajá'
          year='30/10/2011'
          role='Indígena'
          place='T.I. Araribóia/ 92 Aldeias das Etnias Guajajara, Gavião e Guajá - Arame'
        />

        <CardMemoria
          image={icone_homem}
          name='Delmir Silva'
          year='07/11/2011'
          role='Quilombola'
          place='Povoado Portinho - Serrano do Maranhão'
        />

        <CardMemoria
          image={icone_homem}
          name='Júlio Luna da Silva'
          year='08/12/2011'
          role='Assentado'
          place='Citema/ Temasa - Arame'
        />
      </>
    )
  },
  {
    key: 38,
    year: '2012',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Francisco da Conceição Souza Guajajara'
          year='09/03/2012'
          role='Liderança Indígena'
          place='Bacurizinho/ Aldeia Kamihaw Guajajara/ Nazaré - Grajaú'
        />

        <CardMemoria
          image={icone_homem}
          name='Raimundo Alves Borges (Cabeça)'
          year='14/04/2012'
          role='Liderança'
          place='Assentamento Terra Bela - Buriticupu'
        />

        <CardMemoria
          image={icone_mulher}
          name='Maria Amélia Guajajara'
          year='28/04/2012'
          role='Liderança Indígena'
          place='T. i. Canabrava/ Aldeias Coquinho/ Coquinho III/ Ilha São Pedro - Grajaú'
        />
      </>
    )
  },
  {
    key: 39,
    year: '2013',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Gilmar dos Santos de Jesus'
          year='20/02/2013'
          role='Assentado'
          place='PA Capoema - Bom Jesus das Selvas'
        />

        <CardMemoria
          image={icone_homem}
          name='Machado'
          year='26/04/2013'
          role='Assentado'
          place='PA Santa Maria - Satubinha'
        />

        <CardMemoria
          image={icone_homem}
          name='Rosivaldo Mesquita Sousa'
          year='28/09/2013'
          role='Posseiro'
          place='Povoado Santa Isabel - Icatu'
        />
      </>
    )
  },
  {
    key: 40,
    year: '2014',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Luís Carlos Silva'
          year='05/01/2014'
          role='Liderança'
          place='Acampamento Irmã Dorothy - Presidente Vargas'
        />

        <CardMemoria
          image={icone_homem}
          name='Raimundo Rodrigues da Silva (Brechó)'
          year='24/02/2014'
          role='Liderança'
          place='Fazenda Campestre Catulo - Timbiras'
        />

        <CardMemoria
          image={icone_mulher}
          name='Maria José Amorim Silva Lima'
          year='10/07/2014'
          role='Liderança'
          place='Povoado Belo Monte 3 - Amarante do Maranhão'
        />

        <CardMemoria
          image={icone_homem}
          name='Valdeci (Seu Madruga)'
          year='15/07/2014'
          role='Posseiro'
          place='Pavi/ Placas/ Pov. Riacho da Cruz - Chapadinha'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716648928/em_memoria/21_07_2014_bauvqp.png'
          name='José Enedina'
          year='21/07/2014'
          role='Liderança'
          place='Santa Rosa - Santa Rosa'
        />
      </>
    )
  },
  {
    key: 41,
    year: '2015',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Antônio de Cipriano'
          year='23/04/2015'
          role='Liderança Quilombola'
          place='Mata de São Benedito - Itapecuru Mirim'
        />

        <CardMemoria
          image={icone_homem}
          name='Eusébio Ka\apor'
          year='26/04/2015'
          role='Indígena'
          place='T. I. Alto Turiaçu - Centro do Guilherme'
        />

        <CardMemoria
          image={icone_homem}
          name='Raimundo Pires Ferreira'
          year='01/06/2015'
          role='Posseiro'
          place='Povoado Belo Monte 3 - Amarante do Maranhão'
        />

        <CardMemoria
          image={icone_mulher}
          name='Zilquenia Machado Queiroz'
          year='30/06/2015'
          role='Posseiro'
          place='Povoado Belo Monte 3 - Amarante do Maranhão'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716656461/em_memoria/25_08_15_w60hrq.png'
          name='Raimundo Santos Rodrigues'
          year='25/08/2015'
          role='Ambientalista'
          place='Rio da Onça/ Pov. Brejinho/ Rebio do Gurupi - Bom Jardim'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716656466/em_memoria/24-12_15png_s6qj0w.png'
          name='Antônio Isídio Pereira da Silva'
          year='24/12/2015'
          role='Liderança'
          place='Pov. Vergel/ Vegel/ Faz. Boa Esperança - Codó'
        />
      </>
    )
  },
  {
    key: 42,
    year: '2016',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Roni dos Santos Miranda'
          year='01/02/2016'
          role='Sindicalista'
          place='Amarante do Maranhão'
        />

        <CardMemoria
          image={icone_mulher} // assumindo que você tem um ícone para mulheres
          name='Francisca das Chagas Silva'
          year='02/02/2016'
          role='Sindicalista'
          place='Com. Quilombola Joaquim Maria - Miranda do Norte'
        />
        <CardMemoria
          image={icone_homem}
          name='Aponuyre Guajajara'
          year='26/03/2016'
          role='Indígena'
          place='T. I. Araribóia/92 Aldeias/Etnias Guajajara, Gavião e Guajá - Amarante do Maranhão'
        />
        <CardMemoria
          image={icone_homem}
          name='Zé Sapo'
          year='31/03/2016'
          role='Quilombola'
          place='Quilombo Cruzeiro/Triângulo - Palmeirândia'
        />
        <CardMemoria
          image={icone_homem}
          name='Fernando Gamela'
          year='07/04/2016'
          role='Indígena'
          place='Povo Gamela de Taquaritiua/Centro do Antero/Sesmaria Índios/Pov. Chulanga - Viana'
        />

        <CardMemoria
          image={icone_homem}
          name='Genésio Guajajara'
          year='11/04/2016'
          role='Indígena'
          place='T. I. Araribóia/92 Aldeias/Etnias Guajajara, Gavião e Guajá - Amarante do Maranhão'
        />

        <CardMemoria
          image={icone_homem}
          name='Isaías Guajajara'
          year='19/04/2016'
          role='Indígena'
          place='T. I. Araribóia/92 Aldeias/Etnias Guajajara, Gavião e Guajá - Amarante do Maranhão'
        />

        <CardMemoria
          image={icone_homem}
          name='Assis Guajajara'
          year='22/04/2016'
          role='Liderança Indígena'
          place='T. I. Araribóia/92 Aldeias/Etnias Guajajara, Gavião e Guajá - Amarante do Maranhão'
        />
        <CardMemoria
          image={icone_homem}
          name='José Lisboa “Zezinho Lisboa”'
          year='19/07/2016'
          role='Posseiro'
          place='Baixa Funda - Santo Amaro do Maranhão'
        />

        <CardMemoria
          image={icone_homem}
          name='Candide Zaraky Tenetehar'
          year='28/07/2016'
          role='Indígena'
          place='T. I. Araribóia/92 Aldeias/Etnias Guajajara, Gavião e Guajá - Amarante do Maranhão'
        />

        <CardMemoria
          image={icone_homem}
          name='José Queirós Guajajara'
          year='23/09/2016'
          role='Liderança indígena'
          place='T. I. Cana Brava-Marajá/São Pedro dos Cacetes/Aldeia Travessia - Grajaú'
        />

        <CardMemoria
          image={icone_homem}
          name='José Dias de Oliveira Lopes Guajajara'
          year='12/11/2016'
          role='Liderança indígena'
          place='T. I. Bacurizinho/Aldeias Kamihaw Guajajara/Nazaré/Pedra/Planalto - Grajaú'
        />

        <CardMemoria
          image={icone_homem}
          name='Cacique José Colírio Oliveira Guajajara'
          year='26/11/2016'
          role='Liderança indígena'
          place='T. I. Cana Brava-Marajá/São Pedro dos Cacetes/Aldeia Travessia - Grajaú'
        />
      </>
    )
  },
  {
    key: 43,
    year: '2017',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='José Caneta Gavião, “Cu Carut”'
          year='06/03/2017'
          role='Indígena'
          place='T.I Governador - Amarante do Maranhão'
        />

        <CardMemoria
          image={icone_mulher}
          name='Sônia Vicente Cacau Gavião, “CryCapric”'
          year='06/03/2017'
          role='Indígena'
          place='T.I Governador - Amarante do Maranhão'
        />

        <CardMemoria
          image={icone_homem}
          name='Raimundo Silva “Umbico”'
          year='12/04/2017'
          role='Liderança quilombola'
          place='Com. quilombola do Charco - São Vicente Ferrer'
        />

        <CardMemoria
          image={icone_homem}
          name='Zé Menino'
          year='05/07/2017'
          role='Pescador'
          place='Comunidade Boa Vista - Araioses'
        />

        <CardMemoria
          image={icone_homem}
          name='João da Cruz Abreu'
          year='06/07/2017'
          role='Sem - terra'
          place='Ocupação presidente Lula - Bom Jesus das Selvas'
        />
      </>
    )
  },
  {
    key: 44,
    year: '2019',
    content: (
      <>
        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716496086/em_memoria/01_11_19_o8cxdh.png'
          name='Paulo Paulino Guajajara'
          year='01/11/2019'
          role='Liderança Indígena'
          place='T. I. Arariboia/92 Aldeias/Etnias Guajajara, Gavião e Guajá - Bom Jesus das Selvas'
        />

        <CardMemoria
          image={icone_homem}
          name='Cacique Firmino Prexede Guajajara'
          year='07/12/2019'
          role='Liderança Indígena'
          place='T. I. Cana Brava/Aldeias Coquinho/Coquinho II/Ilha de São Pedro/Silvino/Mussun/Nova Vitoriano - Jenipapo dos Vieiras'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716657422/em_memoria/07-12-19_fqixpw.png'
          name='Raimundo Benício Guajajara'
          year='07/12/2019'
          role='Liderança Indígena'
          place='T. I. Lagoa Comprida/Aldeias Leite/Decente - Jenipapo dos Vieiras'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716496626/em_memoria/18_12_19_qiznrw.png'
          name='Francisco Sales Costa de Sousa'
          year='18/12/2019'
          role='Liderança'
          place='Povoado Centro do Totó - Zé Doca'
        />
      </>
    )
  },
  {
    key: 45,
    year: '2020',
    content: (
      <>
        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716656518/em_memoria/05_01_20_q6j5km.png'
          name='Celino Fernandes'
          year='05/01/2020'
          role='Quilombola'
          place='Povoado Cedro - Arari'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716657052/em_memoria/05_01_20__l3eam2.png'
          name='Wanderson de Jesus Rodrigues Fernandes'
          year='05/01/2020'
          role='Quilombola'
          place='Povoado Cedro - Arari'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716656542/em_memoria/31_03_20_wmdd6y.png'
          name='Zezico Rodrigues Guajajara'
          year='31/03/2020'
          role='Indígena'
          place='T. I. Arariboia/92 Aldeias/Etnias Guajajara, Gavião e Guajá/Barreira Sanitária - Arame'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716656568/em_memoria/03_08_20_nydkku.png'
          name='Kwaxipuru Kaapor'
          year='03/08/2020'
          role='Indígena'
          place='T. I. Alto Turiaçu - Centro do Guilherme'
        />

        <CardMemoria
          image={icone_homem}
          name='Raimundo Nonato Batista Costa'
          year='15/08/2020'
          role='Posseiro'
          place='Comunidade Vilela/Gleba Campina - Junco do Maranhão'
        />
      </>
    )
  },
  {
    key: 46,
    year: '2021',
    content: (
      <>
        <CardMemoria
          image={icone_homem}
          name='Um quilombola assassinado'
          year='07/01/2021'
          role='Quilombola'
          place='Comunidade Paiol do Centro/Povoado Jabuti/Suzano - Parnarama'
        />

        <CardMemoria
          image={icone_homem}
          name='José Francisco de Souza Araújo (Vanur)'
          year='11/06/2021'
          role='Quilombola'
          place='Pov. Vergel/Vegel/Faz. Boa Esperança - Codó'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716656611/em_memoria/18_06_21_nkkbyv.png'
          name='Maria da Luz Benício de Sousa'
          year='18/06/2021'
          role='Posseiro'
          place='Comunidade Vilela/Gleba Campina - Junco do Maranhão'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716656572/em_memoria/18_06_21._png_rjnsaq.png'
          name='Reginaldo Alves Barros'
          year='18/06/2021'
          role='Posseiro'
          place='Comunidade Vilela/Gleba Campina - Junco do Maranhão'
        />

        <CardMemoria
          image={icone_homem}
          name='Antônio Gonçalves Diniz'
          year='02/07/2021'
          role='Quilombola'
          place='Comunidade Flexeiras/Búfalos - Arari'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716656710/em_memoria/29_10_21_loqqvc.png'
          name='João de Deus Moreira Rodrigues (Conzinhado)'
          year='29/10/2021'
          role='Posseiro'
          place='Povoado Santo Antônio/Baixada Maranhense - Arari'
        />

        <CardMemoria
          image={icone_homem}
          name='José Vane Guajajara'
          year='17/11/2021'
          role='Indígena'
          place='T. I. Rio Pindaré/Aldeia Januária/Tabocal - Bom Jardim'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716656735/em_memoria/12_11_21__z0b4kz.png'
          name='Maria José Rodrigues'
          year='12/11/2021'
          role='Quebradeira de coco babaçu'
          place='Fazenda Esperança/Povoado Boa Esperança/Resex Enseada da Mata - Penalva'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716656758/em_memoria/12_11_21_hjs8qd.png'
          name='José do Carmo Corrêa Júnior'
          year='12/11/2021'
          role='Quebradeira de coco babaçu'
          place='Fazenda Esperança/Povoado Boa Esperança/Resex Enseada da Mata - Penalva'
        />
      </>
    )
  },
  {
    key: 47,
    year: '2022',
    content: (
      <>
        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716656811/em_memoria/08_01_22_lseifc.png'
          name='José Lopes Rodrigues, Quiqui'
          year='08/01/2022'
          role='Quilombola'
          place='Povoado Cedro - Arari'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716656831/em_memoria/29_04_22_eyhz6d.png'
          name='Edvaldo Pereira Rocha'
          year='29/04/2022'
          role='Quilombola'
          place='Com. Quilombola Jacarezinho - São João Soter'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716656854/em_memoria/14_05_22_ucyr4o.png'
          name="Sarapó Ka'apor"
          year='14/05/2022'
          role='Indígena'
          place='T.I Alto Turiaçu - Centro do Guilherme'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716656874/em_memoria/27_02_22_veamwv.png'
          name='Moacir de Jesus dos Santos Correia'
          year='27/06/2022'
          role='Posseiro'
          place='Povoado Encanto - Pinheiro'
        />
        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716656984/em_memoria/03_09_22_mxa8zs.png'
          name='Jael Carlos Miranda Guajajara'
          year='03/09/2022'
          role='Indígena'
          place='T. I. Arariboia/92 Aldeias/Etnias Guajajara, Gavião e Guajá - Arame'
        />

        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716657004/em_memoria/03_08_22_hohzu1.png'
          name='Janildo Oliveira Guajajara'
          year='03/09/2022'
          role='Indígena'
          place='T. I. Arariboia/92 Aldeias/Etnias Guajajara, Gavião e Guajá - Amarante do Maranhão'
        />

        <CardMemoria
          image={icone_homem}
          name='Antônio Cafeteiro Sousa Silva Guajajara'
          year='11/09/2022'
          role='Indígena'
          place='T. I. Arariboia/92 Aldeias/Etnias Guajajara, Gavião e Guajá - Amarante do Maranhão'
        />
      </>
    )
  },
  {
    key: 48,
    year: '2023',
    content: (
      <>
        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717545769/em_memoria/24_01_23_ogjqwx.png'
          name='José Inácio Guajajara'
          year='24/01/2023'
          role='Indígena'
          place='T. I. Cana Brava/Guajajara/Aldeias Coquinho/ Coquinho II/Ilha de São Pedro/Silvino/Mussun/Nova Vitoriano - Grajaú'
        />
        ,
        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717545769/em_memoria/28_01_23_kukurj.png'
          name='Valdemar Marciano Guajajara'
          year='28/01/2023'
          role='Indígena'
          place='T. I. Arariboia/92 Aldeias/Etnias Guajajara, Gavião e Guajá - Amarante do Maranhão'
        />
        ,
        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717545769/em_memoria/31_01_23_vyoevq.png'
          name='Raimundo Ribeiro da Silva “Doutor”'
          year='31/01/2023'
          role='Funcionário Público (Servidor da Secretaria Especial de Saúde Indigena) '
          place='T. I. Arariboia/92 Aldeias/Etnias Guajajara, Gavião e Guajá - Arame'
        />
        ,
        <CardMemoria
          image='https://res.cloudinary.com/dbnsmasqe/image/upload/v1716656686/em_memoria/02_07_21_urp1jk.png'
          name='José Alberto Moreno Mendes “Doka”'
          year='27/10/2023'
          role='Líder Quilombola'
          place='Comunidade Quilombola Jaibara - Itapecuru Mirim'
        />
      </>
    )
  }
]
