import React from 'react'
import { Container } from './styles'
import { RiCrossFill } from 'react-icons/ri'

function CardMemoria ({ image, name, year, role, place }) {
  return (
    <Container>
      <section>
        {/* <LazyLoad height={200} once offset={1000}> */}
          <img src={image} alt='icone' className='max-w-full' />
        {/* </LazyLoad> */}
        <h2>{name}</h2>
        <div>
          <RiCrossFill color='#6F3228' className='mr-2' />
          <h3>{year}</h3>
        </div>

        <p>{role}</p>
        <p>{place}</p>
      </section>
    </Container>
  )
}

export default CardMemoria
