import styled from 'styled-components'
export const Container = styled.section`
  margin-top: 3rem;
  padding: 3rem 10vw;
  padding-top: 0;
  p,
  h2 {
    font-family: 'Piazzolla', serif;
  }
  @media (max-width: 768px) {
    margin-top: 1rem;
    padding: 1rem 5vw;
  }
  /* background: ${({ theme }) => theme.color.bg}; */ ;
`
export const BoxContent = styled.div`
  display: flex;
  column-gap: 3rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const BoxLeft = styled.div`
  width: 73%;
  p {
    text-align: justify;
    font-family: 'Piazzolla', serif;
    font-weight: 500;
    line-height: 1.6;
    font-size: 1.2rem;
  }
  .lastText {
    text-align: right;
    line-height: 1.8;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`
export const BoxRight = styled.div`
  width: 27%;
  p {
    font-size: 1.2em;
  }
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }
`
