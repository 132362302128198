import styled from "styled-components";

export const Container = styled.section`
  margin-top: 4rem;
  padding: 3rem 10vw;
  padding-top: 0;
  //background-color: #F5F5F5;
  @media(max-width: 768px){
    margin-top: 1rem;
    padding: 3rem 1vw;
    padding-left: 9vw;
    padding-top: 1rem;
  }
`;

