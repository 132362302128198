import styled from "styled-components";
export const HeaderContainer = styled.header`
  background: #6f3228;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

`;
export const HomeButton = styled.button`
   background-color: transparent;
  color: white;
  border: none;
  padding: 10px;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
  a{
    text-decoration: none;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  margin: 0;
  margin-right: ${props => (props.marginRight ? props.marginRight : "0")};
`;