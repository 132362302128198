import React, { useEffect, useState, Suspense, lazy } from 'react'
import { Container, BackgroundVideo } from './styles'

// import Title from "../../components/Title/Title";
const Menu = lazy(() => import('../../components/Menu/Menu'))
// import image from "../../assets/images/mapa.png";
// import image2 from "../../assets/images/mapa2.jpg";
// import { motion } from "framer-motion";
// import Header from '../../components/Header/Header';
const Header2 = lazy(() => import('../../components/Header2/index'))
// import video_home from '../../assets/home.mp4';
const BlogSection = lazy(() =>
  import('../../components/BlogSection/BlogSection')
)
const Footer = lazy(() => import('../../components/Footer/Footer'))

const Home = () => {
  const [videoSrc, setVideoSrc] = useState('')

  useEffect(() => {
    const videoUrl =
      'https://res.cloudinary.com/dbnsmasqe/video/upload/v1717868179/video/home_wlykmt.mp4'
    const cachedVideoUrl = sessionStorage.getItem('videoUrlObservatorio')

    if (cachedVideoUrl) {
      setVideoSrc(cachedVideoUrl)
    } else {
      setVideoSrc(videoUrl)
      sessionStorage.setItem('videoUrlObservatorio', videoUrl)
    }
  }, [])

  return (
    <Suspense
      fallback={
        <div className='flex justify-center items-center mt-[2rem] md:mt-[5rem]'>
          Carregando...
        </div>
      }
    >
      <Menu />
      <Container>
        {/* <Header /> */}
        <Header2 />
        <BackgroundVideo
          autoPlay
          loop
          muted
          playsInline
          controls={false}
          src={videoSrc}
          type='video/mp4'
          style={{ pointerEvents: 'none' }}
        />
        <div className='flex justify-center items-center mt-[5rem]'>
          <img
            src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1728754572/logos/title_nrcftn.png'
            alt='title'
            className='max-w-[90%] md:max-w-[100%] w-[550px] '
          />
        </div>
      </Container>
      <BlogSection />
      <Footer />
    </Suspense>
  )
}

export default Home
