import styled from 'styled-components'
import img from '../../assets/images/fundo_biblioteca.png'
export const Container = styled.section`
  position: relative;
  margin-top: 0rem;
  padding: 3rem 15vw;
  padding-top: 0;
  height: 100vh;
  @media (max-width: 768px) {
    padding: 1rem 6vw;
    overflow-x: hidden;
    height: auto;
    padding-bottom: 3rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.35;
    z-index: -1; /* Ensure the background is behind the content */
  }
  h1 {
    text-align: left;
    font-size: 1.9em;
    color: #b4372a;
    font-weight: 400;
    line-height: 2rem;
    font-family: 'Rubik Dirt', system-ui;
    span {
      font-weight: 500;
    }
  }
  p {
    font-family: 'Piazzolla', serif;
    font-weight: 500;
    text-align: justify;
    line-height: 1.6;
    font-size: 1.1rem;
  }
  @keyframes pulse {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }

  .animate-pulse {
    animation: pulse 2s infinite;
  }
`
