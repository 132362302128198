import React, { useState, useRef, useEffect } from 'react'
import {
  TimelinePagination,
  NavigationButtons,
  YearList,
  Year,
  YearDot,
  NavigationButton,
  TimelineContent
} from './styles'


const TimelineInfografico = ({ data, dataContent }) => {
  const [currentYear, setCurrentYear] = useState(data[0].year) // Ajuste para começar do primeiro ano
  const yearListRef = useRef(null)

  useEffect(() => {
    if (yearListRef.current) {
      yearListRef.current.scrollLeft = 0 // Definir scrollLeft como 0 para começar do início
    }
  }, [])

  const handlePrevYear = () => {
    const currentIndex = data.findIndex(item => item.year === currentYear)
    if (currentIndex > 0) {
      const prevYear = data[currentIndex - 1].year
      setCurrentYear(prevYear)
      smoothScrollTo(yearListRef.current, prevYear)
    }
  }

  const handleNextYear = () => {
    const currentIndex = data.findIndex(item => item.year === currentYear)
    if (currentIndex < data.length - 1) {
      const nextYear = data[currentIndex + 1].year
      setCurrentYear(nextYear)
      smoothScrollTo(yearListRef.current, nextYear)
    }
  }

  const smoothScrollTo = (element, year) => {
    const yearElement = element.querySelector(`span[data-year="${year}"]`)
    if (yearElement) {
      const targetX =
        yearElement.offsetLeft -
        element.offsetWidth / 2 +
        yearElement.offsetWidth / 2
      const duration = 500
      const startTime = performance.now()

      const animateScroll = timestamp => {
        const currentTime = timestamp - startTime
        if (currentTime < duration) {
          const ease = Math.easeInOutQuad(currentTime, 0, 1, duration)
          const currentX = element.scrollLeft
          const increment = (targetX - currentX) * ease
          element.scrollLeft = currentX + increment
          requestAnimationFrame(animateScroll)
        } else {
          element.scrollLeft = targetX
        }
      }

      requestAnimationFrame(animateScroll)
    }
  }

  Math.easeInOutQuad = (t, b, c, d) => {
    t /= d / 2
    if (t < 1) return (c / 2) * t * t + b
    t--
    return (-c / 2) * (t * (t - 2) - 1) + b
  }

  return (
    <div>
      <TimelinePagination>
        <NavigationButtons>
          <NavigationButton onClick={handlePrevYear}>&lt;</NavigationButton>
        </NavigationButtons>
        <YearList ref={yearListRef}>
          {data.map((item, index) => (
            <>
              {index !== 0 && (
                <>
                  <YearDot /> {/* Adiciona bolinha decorativa */}
                  {/* <YearDot />
                  <YearDot /> */}
                </>
              )}
              <Year
                key={item.key}
                className={item.year === currentYear ? 'active' : ''}
                onClick={() => setCurrentYear(item.year)}
                data-year={item.year}
              >
                {item.year}
              </Year>
            </>
          ))}
        </YearList>
        <NavigationButtons>
          <NavigationButton onClick={handleNextYear}>&gt;</NavigationButton>
        </NavigationButtons>
      </TimelinePagination>
      <TimelineContent>
        {data.map((item, index) => {
          const itemCount = React.Children.toArray(
            item.content.props.children
          ).length
          return (
            <div
              key={item.key}
              className={`${
                item.year === currentYear ? 'active' : 'hidden'
              } w-full flex items-center ${
                itemCount <= 2 ? 'justify-center' : ''
              }`}
            >
            
                {item.content}
              
            </div>
          )
        })}
      </TimelineContent>
    </div>
  )
}

export default TimelineInfografico
