import styled from "styled-components";
export const Container = styled.section`
  margin-top: 10rem;
  padding: 3rem 15vw;
  padding-top: 0;
  background: #Fafafa;
  @media (max-width: 768px) {
    margin-top: 1rem;
    padding: 1rem 6vw;
    overflow-x: hidden;
  }
  /* background: ${({ theme }) => theme.color.bg}; */
`;
