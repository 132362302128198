import React from 'react'
import { BoxLeft, BoxRight, BoxContent, Container } from './styles'
import HeaderPages from '../../components/HeaderPages/HeaderPages'
import Carousel from '../../components/Carousel/Carousel'

const Sobre = () => {
  return (
    <>
      <HeaderPages content='Apresentação' marginRight='1rem' />

      <Container>
        <BoxContent>
          <BoxLeft>
            <p>
              O Observatório Maranhão Agrário é um espaço dedicado à coleta e à
              disponibilização de dados e informações sobre a questão agrária do
              estado do Maranhão. Lançado em 2024 e em constante
              desenvolvimento, o Observatório visa facilitar discussões e
              pesquisas que ajudem a compreender a diversidade e a complexidade
              do cenário agrário, mediado por uma análise do espaço geográfico
              maranhense.
            </p>
            <p>
              Desenvolvido pelo Laboratório de Extensão, Pesquisa e Ensino de
              Geografia (LEPENG) da Universidade Federal do Maranhão (UFMA), o
              Observatório conta com um <b>Portal de notícias</b> que divulga
              informações sobre conflitos agrários, formas de resistência e
              eventos relevantes. Além disso, o site abriga um <b>Atlas</b> com
              mais de 600 mapas do estado, que proporciona uma variedade de
              leituras e interpretações, apoiadas pela ciência geográfica.
            </p>
            <p>
              Resultado de um longo processo de pesquisa, a Linha do Tempo da
              Questão Agrária agrupa um conjunto de informações a partir da
              indissociabilidade do espaço e tempo da questão agrária. Já o{' '}
              <b>Espaço de Memórias</b> foi criado para preservar as histórias
              das vítimas de assassinatos no campo, crimes que, alimentados pela
              impunidade, ainda ocorrem com frequência no Maranhão.
            </p>
            <p>
              O acervo da <b>Biblioteca</b> Maria da Glória Rocha Ferreira
              também pode ser consultado no site, assim como <b>Publicações</b>{' '}
              resultantes das nossas pesquisas. Além disso, o Observatório
              disponibiliza <b>Infográficos</b> que oferecem sínteses
              informativas sobre a questão agrária maranhense, tornando o
              conhecimento mais acessível e compreensível.
            </p>
            <p>
              Com essas iniciativas, o Observatório Maranhão Agrário se
              estabelece como um recurso essencial para pesquisadores,
              estudantes e a comunidade em geral, promovendo uma compreensão
              mais profunda das dinâmicas agrárias no Maranhão e contribuindo
              para o debate sobre a necessidade de políticas públicas e de
              justiça social no Maranhão.
            </p>
            <p className='lastText'>
              Prof. Dr. Ronaldo Barros Sodré <br /> Coordenador do Observatório
            </p>
          </BoxLeft>
          <BoxRight>
            <Carousel />
          </BoxRight>
        </BoxContent>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-4 font-medium mb-4'>
          <h2 className='col-span-full text-2xl font-bold text-gray-900 text-center'>
            Equipe
          </h2>
          <div className='flex flex-col leading-[0.5rem] items-center text-lg'>
            <a
              href='http://lattes.cnpq.br/9906914823774018'
              target='_blank'
              rel='noreferrer'
            >
              <p>Daniel Oliveira</p>
            </a>
            <p>Coordenação do Atlas</p>
          </div>
          <div className='flex flex-col leading-[0.5rem] items-center text-lg'>
            <a
              href='http://lattes.cnpq.br/1819268107334832'
              target='_blank'
              rel='noreferrer'
            >
              <p>Amanda Acruchi</p>
            </a>
            <p>Coordenação da Linha do Tempo</p>
          </div>
          <div className='flex flex-col leading-[0.5rem] items-center text-lg'>
            <a
              href='http://lattes.cnpq.br/7059412503591896'
              target='_blank'
              rel='noreferrer'
            >
              <p>Mariana Reis</p>
            </a>
            <p>Coordenação de Notícias</p>
          </div>
          <div className='flex flex-col leading-[0.5rem] items-center text-lg'>
            <a
              href='http://lattes.cnpq.br/4073098468694675'
              target='_blank'
              rel='noreferrer'
            >
              <p>Gustavo Souza</p>
            </a>
            <p>Coordenação do Espaço de</p>
            <p>Memórias</p>
          </div>
          <div className='flex flex-col leading-[0.5rem] items-center text-lg'>
            <a
              href='http://lattes.cnpq.br/2279486546430822'
              target='_blank'
              rel='noreferrer'
            >
              <p>Jaine do Nascimento</p>
            </a>
            <p>Coordenação dos Infográficos</p>
          </div>
          <div className='flex flex-col leading-[0.5rem] items-center text-lg'>
            <a
              href='http://lattes.cnpq.br/4037110203344817'
              target='_blank'
              rel='noreferrer'
            >
              <p>Paulo Ricardo Martins</p>
            </a>
            <p>Coordenação de Publicações</p>
          </div>
          <div className='flex flex-col leading-[0.5rem] items-center text-lg'>
            <a
              href='http://lattes.cnpq.br/5123738695580815'
              target='_blank'
              rel='noreferrer'
            >
              <p>Márcia Renata</p>
            </a>
            <p>Coordenação da Biblioteca</p>
          </div>
        </div>
      </Container>
    </>
  )
}
export default Sobre
