import styled from "styled-components";
export const Container = styled.section`
  margin-top: 3rem;
  padding: 3rem 15vw;
  padding-top: 0;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  @media(max-width: 768px){
    margin-top: 1rem;
    padding: 3rem 4vw;
    padding-top: 1rem;
    flex-direction: column-reverse;
  }

  /* background: ${({ theme }) => theme.color.bg}; */
`;
