import styled from 'styled-components'
export const Container = styled.section`
  display: flex;
  flex-direction: column;
  h2,
  h3,
  p {
    margin: 0;
  }
  section {
    width: 320px;
    position: relative;
    h2::before {
      content: '';
      display: block;
      width: 5px; /* Adjust width as needed */
      height: 100%;
      position: absolute;
      left: -10px; /* Adjust left as needed */
      top: 0;
    }
    h2 {
      position: relative;
      font-size: 1.125rem;
      line-height: 1.4rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }
    h3 {
      color: #953b2c;
      font-weight: 800;
      font-size: 1.2em;
    }
    p {
      font-family: 'Piazzolla', serif;
      font-weight: 500;
      text-align: left !important;
      line-height: 1.5 !important;
      font-size: 1.1rem !important;
    }
  }
`
