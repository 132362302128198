/* Estilos para a linha do tempo */
import styled from 'styled-components'
export const TimelinePagination = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
  overflow-x: auto;
  max-width: 120%;
  margin-left: -10%;
  width: 120%;
  justify-content: center;
  ::-webkit-scrollbar {
    height: 5px;
    border-radius: 30px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #6f3228; /* color of the scroll thumb */
    border-radius: 30px; /* roundness of the scroll thumb */
    //border: 5px solid #f4fcff; /* creates padding around scroll thumb */
  }
  ::-webkit-scrollbar-track {
    background: #f4fcff; /* color of the tracking area */
  }
`

export const NavigationButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  background-color: transparent;
  padding: 10px;
  flex: 0;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 23px;
    margin: 0 10px;
    outline: none;

    padding: 5px;
    color: #d7ab24;
    font-weight: 300;
    background: #953b2c;
  }
`

export const YearList = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding: 20px;
`

export const Year = styled.span`
  font-size: 23px;
  padding: 0 10px;
  cursor: pointer;
  transition: color 0.3s ease;
  color: #953b2c; /* Cor dos anos não selecionados */
  font-family: 'Anton', sans-serif; /* Altere a fonte dos anos aqui */
  font-weight: 600;
  letter-spacing: 1px;

  &.active {
    color: #d7ab24; /* Cor do ano selecionado */
  }
`

export const YearDot = styled.div`
  width: 5px;
  height: 5px;
  background-color: #d7ab24;
  border-radius: 50%;
  margin: 0 2px;
`

export const NavigationButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  margin: 0 10px;
  outline: none;
`

export const TimelineContent = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: 20px;
  width: 120%;
  margin-left: -10%;
  position: relative;

 



  div.active {
    display: flex;

    gap: 6rem;
    background-color: transparent;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
      height: 17px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #6f3228; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 4px solid #f4fcff; /* creates padding around scroll thumb */
    }
    &::-webkit-scrollbar-track {
      background: #f4fcff;
      border-radius: 20px; /* color of the tracking area */
    }
  }
`
