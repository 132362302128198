import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import './styles.css';
import { Link } from 'react-router-dom';

async function getItems() {
  const response = await fetch(
    'https://bdmaranhaoagrario.shop/api/maps?fields[0]=id&fields[1]=title&fields[2]=level&populate[parentId][fields][0]=id&filters[level][$in][0]=0&filters[level][$in][1]=1&filters[level][$in][2]=2&filters[level][$in][3]=3&filters[image][$null]=true&pagination[page]=1&pagination[pageSize]=200',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer 4b9915a6c4cf1ed9f8e9176f2bf098ab2985140b8ed3cbb71d4d45aa16ff2e7ba54b7dca3d9831853db6939045eeb3d8886a945557ae8a8eff76d137baf592e7d8ebbfec8237928f3346f42a62175028deaf2e6eef8640fd4a7b7aaad751b836bc59e734ee41ca8598ab29a1979af92a693f156528b220e12e9eef76e06d7673'
      }
    }
  );
  const data = await response.json();
  return data.data;
}

const CustomMenu = ({ onItemClick }) => {
  const [menuItems, setMenuItems] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const fetchItems = async () => {
      if (!dataLoaded) {
        const data = await getItems();

        const buildSubmenu = (parentItem, level) => {
          return data
            .filter(
              child =>
                child.attributes.level === level &&
                (!child.attributes.image || !child.attributes.image.data) &&
                child.attributes.parentId?.data?.id === parentItem.id
            )
            .map(child => {
              const hasSubmenu =
                level < 3 &&
                data.some(
                  subChild =>
                    subChild.attributes.level === level + 1 &&
                    (!subChild.attributes.image ||
                      !subChild.attributes.image.data) &&
                    subChild.attributes.parentId?.data?.id === child.id
                );

              const uniqueId = `item-${child.id}-lvl${level}`;

              return {
                key: `sub${child.id}`,
                label: hasSubmenu ? (
                  <div id={uniqueId} className={`custom-area lvl${level}`}>
                    {child.attributes.title}
                  </div>
                ) : (
                  <div id={uniqueId} className={`custom-area lvl${level} pl-6 pr-0 mr-0`}>
                    <Link
                      to={`/mapas/${child.id}_${child.attributes.title
                        .toLowerCase()
                        .replace(/\s+/g, '_')}`}
                      className={`custom-link lvl${level} mr-0`}
                      onClick={() => onItemClick()}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <div className='ml-[-1rem]'>{child.attributes.title}</div>
                      <div className='pl-6'>
                        <p className='text-[1.6rem] mb-[0.35rem] font-bold'>›</p>
                      </div>
                    </Link>
                  </div>
                ),
                children: hasSubmenu ? buildSubmenu(child, level + 1) : null
              };
            });
        };

        const level0Items = data
          .filter(item => item.attributes.level === 0)
          .map(item => {
            const titles = {
              12: 'Configuração territorial',
              30: 'Dinâmicas populacionais',
              47: 'Dinâmicas econômicas',
              77: 'Estrutura fundiária',
              79: 'Condição do produtor em relação à área',
              100: 'Silvicultura em 2022',
              104: 'Assentamentos rurais',
              174: 'Ocupações de terra',
              205: 'Conflitos Agrários'
            };
            const customTitle = titles[item.id] || item.attributes.title;

            const children = buildSubmenu(item, 1);
            const uniqueId = `item-${item.id}-lvl0`;

            return {
              key: `sub${item.id}`,
              label:
                children.length > 0 ? (
                  <div id={uniqueId} className='custom-area lvl0 '>{customTitle}</div>
                ) : (
                  <div id={uniqueId} className='custom-area lvl0 '>
                    <Link
                      to={`/mapas/${item.id}_${item.attributes.title
                        .toLowerCase()
                        .replace(/\s+/g, '_')}`}
                      className='custom-link lvl0 '
                      onClick={() => onItemClick()}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <div className="ml-[-10px]">{customTitle}</div>
                      <div>
                        <p className='text-[1.6rem] mb-[0.35rem] font-bold'>›</p>
                      </div>
                    </Link>
                  </div>
                ),
              children: children.length > 0 ? children : null
            };
          });

        setMenuItems(level0Items);
        setDataLoaded(true);
      }
    };
    fetchItems();
  }, [onItemClick, dataLoaded]);

  return (
    <Menu
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['sub1']}
      mode='inline'
      items={menuItems}
      className='custom-menu'
    />
  );
};

export default CustomMenu;