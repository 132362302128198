import React from 'react'
import img2 from '../../assets/images/indigenas/2.png'
import img3 from '../../assets/images/indigenas/3.png'
import img4 from '../../assets/images/indigenas/4.png'
import img5 from '../../assets/images/indigenas/5.png'
import img1 from '../../assets/images/indigenas/1.png'
import img6 from '../../assets/images/indigenas/6.png'
import img7 from '../../assets/images/indigenas/7.png'
import img8 from '../../assets/images/indigenas/8.png'
import img9 from '../../assets/images/indigenas/9.png'
import img10 from '../../assets/images/indigenas/10.png'
import img11 from '../../assets/images/indigenas/11.png'
import img12 from '../../assets/images/indigenas/12.png'
import img13 from '../../assets/images/indigenas/13.png'
import img14 from '../../assets/images/indigenas/14.png'
import img15 from '../../assets/images/indigenas/15.png'
import img16 from '../../assets/images/indigenas/16.png'
import img17 from '../../assets/images/indigenas/17.png'
import img18 from '../../assets/images/indigenas/18.png'
import img19 from '../../assets/images/indigenas/19.png'
import img20 from '../../assets/images/indigenas/20.png'
import img21 from '../../assets/images/indigenas/21.png'
import img22 from '../../assets/images/indigenas/22.png'

function CardImages () {
  return (
    <section>
      <section className='grid grid-cols-5 gap-x-1 items-center'>
        <img src={img1} className='w-[90%] h-auto transition-transform duration-300 transform hover:scale-110' alt='POBZÉ' />
        <img src={img2} className='w-[90%] h-auto transition-transform duration-300 transform hover:scale-110' alt='KAAPOR' />
        <img src={img3} className='w-full h-auto transition-transform duration-300 transform hover:scale-110' alt='CANELA APANYEKRA' />
        <img src={img4} className='w-full h-auto transition-transform duration-300 transform hover:scale-110' alt='TUPINAMBÁ' />
        <img src={img5} className='w-full h-auto transition-transform duration-300 transform hover:scale-110' alt='CAYCAYSES' />
      </section>
      <section className='grid grid-cols-5 gap-x-1 mt-1 items-center'>
        <img src={img6} className='w-full h-auto transition-transform duration-300 transform hover:scale-110' alt='BARBADO' />
        <img src={img7} className='w-full h-auto transition-transform duration-300 transform hover:scale-110' alt='KRIKATI' />
        <img src={img8} className='w-full h-auto transition-transform duration-300 transform hover:scale-110' alt='SAKAMEKRÁ' />
        <img src={img9} className='w-full h-auto transition-transform duration-300 transform hover:scale-110' alt='CANELA RAMKOKAMEKRÁ' />
        <img src={img10} className='w-[85%] h-auto transition-transform duration-300 transform hover:scale-110' alt='KRIÊ' />
      </section>
      <section className='grid grid-cols-4 gap-x-1 mt-1 justify-center px-[3rem] items-center'>
        <img src={img11} className='w-full h-auto transition-transform duration-300 transform hover:scale-110' alt='TREMEMBÉ' />
        <img src={img12} className='w-full h-auto transition-transform duration-300 transform hover:scale-110' alt='ARAIOSE' />
        <img src={img13} className='w-full h-auto transition-transform duration-300 transform hover:scale-110' alt='AMANAJÓ' />
        <img src={img14} className='w-[85%] h-auto transition-transform duration-300 transform hover:scale-110' alt='GUAJÁ' />
      </section>
      <section className='grid grid-cols-4 gap-x-1 mt-1 justify-center px-[3rem] items-center'>
        <img src={img15} className='w-full h-auto transition-transform duration-300 transform hover:scale-110' alt='URUATI' />
        <img src={img16} className='w-full h-auto transition-transform duration-300 transform hover:scale-110' alt='KAPIEKRÁ' />
        <img src={img17} className='w-[85%] justify-self-center h-auto transition-transform duration-300 transform hover:scale-110' alt='KRENYÊ' />
        <img src={img18} className='w-[110%] h-auto transition-transform duration-300 transform hover:scale-110' alt='KENKATEIÊ' />
      </section>
      <section className='grid grid-cols-4 gap-x-1 mt-1 justify-center px-[3rem] items-center'>
        <img src={img19} className='w-full h-auto transition-transform duration-300 transform hover:scale-110' alt='GAMELLA' />
        <img src={img20} className='w-full h-auto transition-transform duration-300 transform hover:scale-110' alt='GAVIÃO PYKOPJÊ' />
        <img src={img21} className='w-full h-auto transition-transform duration-300 transform hover:scale-110' alt='GUAJAJARA' />
        <img src={img22} className='w-full h-auto transition-transform duration-300 transform hover:scale-110' alt='GUANAUÉ' />
      </section>
    </section>
  )
}

export default CardImages
