import React from 'react'
import { Container } from './styles'
import HeaderPages from '../../components/HeaderPages/HeaderPages'
import { Link } from 'react-router-dom'
import {
  FaBook,
  FaFileAlt,
  FaFileSignature,
  FaNewspaper,
  FaAtlas
} from 'react-icons/fa'

import img1 from '../../assets/images/publicacoes/1.jpg'
import img2 from '../../assets/images/publicacoes/3.png'
import img3 from '../../assets/images/publicacoes/2.jpeg'
import img4 from '../../assets/images/publicacoes/4.png'

const Publicacoes = () => {
  return (
    <>
      <HeaderPages content='Publicações' marginRight='1rem'/>
      <Container>
        <main className='md:mt-[-6rem]'>
          <p className='leading-relaxed text-lg md:px-10 font-semibold'>
            Este espaço é dedicado à divulgação das pesquisas realizadas pelo
            LEPENG. Aqui, estão disponíveis uma variedade de produções
            científicas, incluindo teses, dissertações, livros, capítulos de
            livros e artigos que são fruto do nosso trabalho rigoroso e
            investigativo. Além disso, disponibilizamos os relatórios da Rede
            DATALUTA, da qual o Laboratório é um membro ativo. Nossa missão é
            facilitar o acesso a informações valiosas e estimular o debate
            acadêmico, tornando as pesquisas mais acessíveis para estudantes,
            profissionais e todos que se interessam pelos temas que trabalhamos.
          </p>

          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 py-10 text-center'>
            <Link
              to='/publicacoes/teses_e_dissertacoes'
              className='font-bold text-xl flex flex-col items-center text-gray-900'
            >
              <FaFileSignature className='mb-2 text-4xl text-[#6d3127]' /> Teses
              e dissertações
            </Link>
            <Link
              to='/publicacoes/monografias'
              className='font-bold text-xl flex flex-col items-center text-gray-900'
            >
              <FaFileAlt className='mb-2 text-4xl text-[#6d3127]' /> Monografias
            </Link>
            <Link
              to='/publicacoes/livros_e_capitulos'
              className='font-bold text-xl flex flex-col items-center text-gray-900'
            >
              <FaBook className='mb-2 text-4xl text-[#6d3127]' /> Livros e
              capítulos de livros
            </Link>
            <Link
              to='/publicacoes/artigos'
              className='font-bold text-xl flex flex-col items-center text-gray-900'
            >
              <FaNewspaper className='mb-2 text-4xl text-[#6d3127]' /> Artigos
            </Link>
          </div>

          <div className='text-center md:py-10 '>
            <Link
              to='/publicacoes/relatorios'
              className='font-bold text-xl flex flex-col items-center text-gray-900'
            >
              <FaAtlas className='mb-2 text-4xl text-[#6d3127]' />
              Relatórios Dataluta
            </Link>
          </div>

          <div className='text-center mt-16'>
            <h2 className='text-2xl font-bold text-gray-900'>
              Periódicos parceiros do Observatório Maranhão Agrário
            </h2>
            <div className='flex flex-col md:flex-row items-center gap-4 mt-8'>
              <a
                href='http://periodicoseletronicos.ufma.br/index.php/interespaco'
                target='_blank'
                rel='noreferrer'
                className='w-[50%] flex justify-center'
              >
                <img
                  src={img1}
                  alt='Interespaço'
                  className='w-full'
                />
              </a>
              <a
                href='https://periodicos.unb.br/index.php/BD/index'
                target='_blank'
                rel='noreferrer'
                className='w-[50%] flex justify-center'
              >
                <img src={img2} alt='DataLuta' className='w-full' />
              </a>

              <a
                href='https://seer.ufu.br/index.php/campoterritorio'
                target='_blank'
                rel='noreferrer'
                className='w-[50%] flex justify-center '
              >
                <img
                  src={img3}
                  alt='Campo Território'
                  className='w-full h-[63px]'
                />
              </a>
              <a
                href='https://revista.fct.unesp.br/index.php/nera/index'
                target='_blank'
                rel='noreferrer'
                className='w-[50%] flex justify-center '
              >
                <img src={img4} alt='Nera' className='w-full h-[63px]' />
              </a>
            </div>
          </div>
        </main>
      </Container>
    </>
  )
}
export default Publicacoes
