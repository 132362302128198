import styled from 'styled-components'
export const Container = styled.section`
  margin-top: 4rem;
  padding: 3rem 15vw;
  padding-top: 0;
  @media (max-width: 768px) {
    margin-top: 1rem;
    padding: 1rem 6vw;
    overflow-x: hidden;
  }
  h1 {
    text-align: left;
    font-size: 4.5em;
    color: #b4372a;
    font-weight: 100;
    line-height: 3.6rem;
    font-family: 'GothicJoker', sans-serif;
    span {
      font-weight: 500;
    }
  }
  p {
    font-family: 'Piazzolla', serif;
    font-weight: 500;
    text-align: justify;
    line-height: 1.6;
    font-size: 1.2rem;
  }
  /* background: ${({ theme }) => theme.color.bg}; */
`
