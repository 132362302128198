import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  height: 500px;
  overflow: hidden;
  z-index: 0;
  overflow-x: hidden;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translate(-50%, -50%);
  }

  div {
    position: relative;
    text-align: center;
  }
`

export const BackgroundVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`

export const Footer = styled.footer`
  height: 300px;
`
