import ImageInfografico from "../../components/ImageInfografico/ImageInfografico"
export const data = [
    {
      key: 0,
      year: '2010',
      content: (
        <ImageInfografico srcImg="https://res.cloudinary.com/dbnsmasqe/image/upload/v1725138637/infograficos/INFOGRAFICOS_LEPENG_page-0001_dndivr.jpg" />
      )
    },
    {
      key: 1,
      year: '2011',
      content: (
        <ImageInfografico srcImg="https://res.cloudinary.com/dbnsmasqe/image/upload/v1725138636/infograficos/INFOGRAFICOS_LEPENG_page-0002_wt1unl.jpg" />
      )
    },
    {
      key: 2,
      year: '2012',
      content: (
        <ImageInfografico srcImg="https://res.cloudinary.com/dbnsmasqe/image/upload/v1725138638/infograficos/INFOGRAFICOS_LEPENG_page-0003_jlldjv.jpg" />
      )
    },
    {
      key: 3,
      year: '2013',
      content: (
        <ImageInfografico srcImg="https://res.cloudinary.com/dbnsmasqe/image/upload/v1725138638/infograficos/INFOGRAFICOS_LEPENG_page-0004_cq59uk.jpg" />
      )
    },
    {
      key: 4,
      year: '2014',
      content: (
        <ImageInfografico srcImg="https://res.cloudinary.com/dbnsmasqe/image/upload/v1725138641/infograficos/INFOGRAFICOS_LEPENG_page-0005_pcbnbi.jpg" />
      )
    },
    {
      key: 5,
      year: '2016',
      content: (
        <ImageInfografico srcImg="https://res.cloudinary.com/dbnsmasqe/image/upload/v1725138636/infograficos/INFOGRAFICOS_LEPENG_page-0006_dax4bz.jpg" />
      )
    },
    {
      key: 6,
      year: '2017',
      content: (
        <ImageInfografico srcImg="https://res.cloudinary.com/dbnsmasqe/image/upload/v1725138638/infograficos/INFOGRAFICOS_LEPENG_page-0007_w1sveu.jpg" />
      )
    },
    {
      key: 7,
      year: '2018',
      content: (
        <ImageInfografico srcImg="https://res.cloudinary.com/dbnsmasqe/image/upload/v1725138638/infograficos/INFOGRAFICOS_LEPENG_page-0008_imywg0.jpg" />
      )
    },
    {
      key: 8,
      year: '2019',
      content: (
        <ImageInfografico srcImg="https://res.cloudinary.com/dbnsmasqe/image/upload/v1725138639/infograficos/INFOGRAFICOS_LEPENG_page-0009_af06kq.jpg" />
      )
    },
    {
      key: 9,
      year: '2020',
      content: (
        <ImageInfografico srcImg="https://res.cloudinary.com/dbnsmasqe/image/upload/v1725138639/infograficos/INFOGRAFICOS_LEPENG_page-0010_qef5uf.jpg" />
      )
    },
    {
      key: 10,
      year: '2021',
      content: (
        <ImageInfografico srcImg="https://res.cloudinary.com/dbnsmasqe/image/upload/v1725138640/infograficos/INFOGRAFICOS_LEPENG_page-0011_gnenny.jpg" />
      )
    },
    {
      key: 11,
      year: '2022',
      content: (
        <ImageInfografico srcImg="https://res.cloudinary.com/dbnsmasqe/image/upload/v1725138642/infograficos/INFOGRAFICOS_LEPENG_page-0012_ybubdx.jpg" />
      )
    },
    {
      key: 12,
      year: '2023',
      content: (
        <ImageInfografico srcImg="https://res.cloudinary.com/dbnsmasqe/image/upload/v1725138642/infograficos/INFOGRAFICOS_LEPENG_page-0013_vq2qjj.jpg" />
      )
    }
]