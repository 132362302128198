import CardTimeline from '../../../components/CardTimeline/CardTimeline'
import CardImages from '../../../components/CardImages/CardImages'
import { FaInfinity } from 'react-icons/fa6'


export const data = [
  {
    key: 0,
    year: (
      <>
        <FaInfinity size='30' />
      </>
    ),
    content: <CardImages/>
  },
  {
    key: 1,
    year: 'XVII',
    content: (
      <>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Upaon-Açu é ocupada por franceses
            </h2>
            <h3>1612</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858379/questao_agraria/1612_isxmcg.png'
              alt='1612'
              className='max-w-full'
            />
            <p>
              Às margens da grande ilha de Upaon-Açu, onde viviam os Tupinambá,
              uma esquadra francesa liderada por Daniel de la Touche conseguiu
              ancorar empreendendo sua ocupação no local. Obteve êxito na
              fundação do forte de São Luís, após as frustradas tentativas de
              conquistas de expedições portuguesas e espanholas. Um ano depois
              os franceses iniciaram expedições para o interior maranhense
              desencadeando conflitos devido à resistência indígena ante a
              colonização. Após confrontos com os portugueses, os francos foram
              expulsos. Nas batalhas, muitos indígenas foram assassinados em
              combate.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Formação do Estado do Maranhão Colonial
            </h2>
            <h3>1621</h3>
            <p>
              Para assegurar o domínio português e ocupar economicamente o norte
              do Brasil, foi estabelecido por carta régia o Estado autônomo que
              compreendia as Capitanias do Piauí, Maranhão, Grão-Pará e Rio
              Negro. A chegada dos holandeses em 1641 mais uma vez demonstrou a
              fragilidade administrativa do Estado português, que só conseguiu
              expulsar os batavos três anos depois.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Instituição do Estado do Maranhão e Grão Pará{' '}
            </h2>
            <h3>1654</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858379/questao_agraria/1654_ayso6y.png'
              alt='1654'
              className='max-w-full mb-3'
            />
            <p>
              Com um olhar mais atento para a região, Portugal prossegue a
              ocupação colonial do território. Continuaram a chegar casais
              açorianos com vistas a ocupar o interior. Seguiu-se o modelo
              econômico de engenhos de açúcar com a utilização de mão-de-obra
              indígena escravizada. Após a divisão do Estado do Maranhão
              Colonial em Capitanias autônomas, a Coroa observou que tal medida
              não favorecia Portugal, em decorrência disso o Estado foi
              novamente reunido, agora denominado Maranhão e Grão-Pará.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Escravidão dos indígenas praticadas pelos Jesuítas
            </h2>
            <h3>1660</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858379/questao_agraria/1660_h5obfc.png'
              alt='1660'
              className='max-w-full mb-3'
            />
            <p>
              O Estado recém-formado organizou expedições de “resgate” de
              indígenas, baseadas em carta régia, redigida sob a influência do
              padre Antônio Vieira. Após expedições nos territórios jurunas,
              tupinambaranas, arauques e conduris, depois de uma série de
              conflitos e massacres os jesuítas capturaram 1200 tupinambás.
              Usando o pretexto de redenção, catequizavam e escravizavam
              indígenas. Descontentes com as imposições jesuítas, colonos,
              autoridades e câmaras do Estado se aliaram para sua primeira
              expulsão.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline className='flex flex-col'>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Criação da Companhia de Comércio do Maranhão e Grão-Pará (Estanco)
            </h2>
            <h3>1682</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858379/questao_agraria/1682_lfhuoj.png'
              alt='1682'
              className='max-w-full mb-3'
            />
            <p>
              Com a proibição da escravização indígena e a fim de potencializar
              a lavoura e indústria, a Companhia foi criada comprometendo-se, em
              um período de doze anos, a introduzir até dez mil africanos para
              serem escravizados. Além de importar os gêneros necessários ao
              consumo dos colonos e para fomento das produções. Por outro lado,
              seus membros detinham exclusividade do comlércio e da captura de
              indígenas no sertão para seu serviço.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Revolta de Beckman
            </h2>
            <h3>1684</h3>
            <p>
              Os colonos estavam insatisfeitos com a administração do então
              Estado. A Companhia de Comércio do Maranhão não cumpriu com o
              determinado, gerando o descontentamento. O governador e a Corte
              permaneceram omissos. O comerciante Manuel Beckman, junto a outros
              colonos abastados, prenderam o capitão-mor e os jesuítas, selaram
              as portas dos armazéns de estanco como atos de sua insatisfação e
              lograram êxito por certo tempo em suas reivindicações.
            </p>
          </section>
        </CardTimeline>
      </>
    )
  },
  {
    key: 2,
    year: 'XVIII',
    content: (
      <>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Frente de expansão litorânea
            </h2>
            <h3>1722</h3>
            <p>
              A expansão da colonização no Maranhão foi executada por meio de
              algumas frentes expansionistas. Estas tinham como primeiro passo a
              quebra de resistência dos nativos. Os colonizadores utilizaram-se
              de mecanismos como as “guerras justas”, embates violentos
              legitimados sob o pretexto de combate ao paganismo, a ação dos
              sertanistas, as tropas de resgate e expedições de reconhecimento.
              Uma das frentes foi direcionada ao litoral maranhense mantendo o
              mesmo procedimento. Com a dominação dos Tremembés, fundou-se a
              missão Nossa Senhora da Conceição em Tutóia, que viabilizou a
              comunicação terrestre entre Maranhão e Ceará. Na mesma época
              abriu-se caminho entre Belém e Alcântara. Estas vias litorâneas,
              ainda que rudimentares, viabilizaram a aceleração do povoamento na
              região, por onde transitavam viajantes e mercadorias.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Diretório dos Índios
            </h2>
            <h3>1755</h3>
            <p>
              Foi a lei que determinou os aldeamentos indígenas em forma de
              vilas, passando a serem administrados por agentes públicos, não
              mais por religiosos. A política do Marquês de Pombal buscava
              incorporar o indígena à sociedade dos brancos, transformá-los em
              trabalhadores ativos de padrão europeu, a fim de assegurar o
              povoamento e a defesa do território colonial. Para tanto, os
              idiomas e a cultura indígena precisavam ser extintos e a
              mestiçagem entre colonos brancos e mulheres indígenas fora
              estimulada para diluir a herança nativa.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section className='mt-5'>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Companhia Geral do Comércio do Grão-Pará e Maranhão
            </h2>
            <h3>1755</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1727137895/questao_agraria/1755_so58ao.png'
              alt='1755'
              className='max-w-full mb-3'
            />
            <p>
              Depois de ter seu nome modificado para Estado do Grão-Pará e
              Maranhão, sendo sua sede administrativa em Belém, o Marquês de
              Pombal cria a Companhia Geral do Comércio do Grão-Pará e Maranhão
              que propiciou a entrada maciça de mão de obra compulsória de
              origem africana, promovendo uma economia agroexportadora,
              principalmente algodoeira. O trânsito de navios negreiros no
              Atlântico Sul emergiu fazendo da região amazônica, sobretudo o
              Maranhão, referência no comércio de mão de obra escravizada no
              hemisfério sul.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Frente de expansão pastoril nos sertões
            </h2>
            <h3>1760</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858379/questao_agraria/1760_vcnhlr.png'
              alt='1760'
              className='max-w-full mb-3'
            />
            <p>
              A frente de ocupação tocada pelos criadores de gado e vaqueiros do
              nordeste oriental adentrou o interior maranhense. Conhecida como
              “caminhos do gado”, essas missões expansionistas devassaram
              terras, vasculharam as margens dos rios, expulsaram e dizimaram
              indígenas, ocupando os sertões na região de Pastos Bons com grande
              hostilidade no processo de povoamento. Como resultado dessa
              expansão, foram criados vários povoados nas cabeceiras dos rios
              Neves, Mearim, Farinha e Macapá, transformando-se depois em vilas
              que tinham como principal atividade econômica a pecuária bovina.
              Na década de 1760, o couro produzido nessa região chegou a ganhar
              o mercado internacional, ainda que discretamente. Também se
              favoreceu nesse tempo do declínio da produção de gado em Marajó,
              uma vez que, aberta a Estrada Real (1769) entre o interior do
              Grão-Pará e Maranhão, atendeu à demanda de carne e farinha para a
              região.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Tráfico negreiro para o Maranhão
            </h2>
            <h3>1761</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858380/questao_agraria/1761_fysdfw.png'
              alt='1761'
              className='max-w-full mb-3'
            />
            <p>
              A segunda metade do século XVIII foi marcada pela grande
              transferência forçada de africanos para o estado, diversificando
              também a origem destes, que eram predominantemente embarcados nos
              portos de Serra Leoa, Cabo Verde, Guiné, Mina e Angola. Estima-se
              que desembarcaram ao longo desse período mais de 40.000
              escravizados no porto de São Luís. A inserção dos africanos no
              Maranhão perdurou até o século XIX, mesmo quando o tráfico
              negreiro já era proibido. O fluxo de negros trazidos de outras
              regiões do país se tornou frequente. Esse fato desencadeou
              profundas influências na economia, cultura e, posteriormente, na
              questão agrária, pela formação de vários quilombos que originaram
              comunidades tradicionais que até hoje lutam pelo direito de
              permanecer na terra.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Produção de arroz
            </h2>
            <h3>1766</h3>
            <p>
              A produção agrícola foi alavancada pela Companhia de Comércio, um
              dos motivos que permitiu a introdução de arroz de origem
              estrangeira (arroz branco da Carolina - Estados Unidos da
              América), que substituiu os primeiros arrozais do estado, até
              então produzidos pelos povos indígenas com o arroz da terra ou
              arroz vermelho. Com a adoção de tal medida, a produção de arroz
              realizada pelo Maranhão passou de 627 arrobas, em 1770, para
              102.944 arrobas em 1774, ganhando o mercado internacional. Ainda
              hoje o cultivo de arroz é fonte de renda para várias comunidades
              de trabalhadores rurais no Maranhão.
            </p>
          </section>
        </CardTimeline>
      </>
    )
  },
  {
    key: 2,
    year: 'XIX',
    content: (
      <>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>Balaiada</h2>
            <h3>1838</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1721779675/questao_agraria/1838_q85jwa.png'
              alt='1838'
              className='max-w-[115%] ml-[-1rem] mb-2'
            />
            <p>
              No início do século XIX, o campesinato se tornou mais numeroso com
              as plantações de algodão no norte do Maranhão. A aristocracia
              rural que dominava aquela região era indiferente às péssimas
              condições de vida dos camponeses, resultando em um levante social
              que contou com a participação de vaqueiros, indígenas e
              escravizados e outros desfavorecidos do campo. Os revoltosos
              conseguiram tomar a região de Caxias e até venceram alguns
              combates, liderados pelo vaqueiro Raimundo Gomes, o artesão Manoel
              dos Anjos Ferreira e o quilombola Cosme Bento, que liderava um
              exército de três mil negros, mas acabaram sendo derrotados pelas
              forças do governo.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Economia canavieira
            </h2>
            <h3>1840</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858380/questao_agraria/1840_ijkihy.png'
              alt='1840'
              className='max-w-full'
            />
            <p>
              Com o declínio da economia após a Balaiada, que coincidiu com o
              aumento da produção algodoeira estadunidense, o governo fomentou a
              produção da cana-de-açúcar através da concessão de premiações para
              os lavradores que mais produzissem. Essa estratégia propiciou a
              expansão do cultivo por toda a Baixada Maranhense ao longo dos
              vales do Itapecuru e Pindaré. Isso impulsionou a produção que
              passou de 417 arrobas, em 1821, para 100.000 arrobas, em 1858,
              viabilizando a exportação.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Criação da Lei de Terras
            </h2>
            <h3>1850</h3>
            <p>
              Criada durante o reinado de D. Pedro II, a lei (nº 0601/18 de
              setembro de 1850) foi determinante para a histórica concentração
              fundiária no Brasil. Ela estabelecia critérios para a compra de
              terras devolutas, visto que antes eram concedidas apenas por
              título de doação (sesmarias) e que a simples ocupação já
              determinava a posse. A lei determinava que qualquer cidadão
              poderia adquirir a propriedade de terras mediante pagamento. Mas,
              na prática, favoreceu apenas os grandes latifundiários, que usavam
              de artimanhas para legitimar diversas posses adquiridas e tinham
              condições de pagar por grandes áreas, enquanto a classe
              assalariada conseguia, no máximo, pequenas propriedades. No
              Maranhão essa legislação contribuiu para a concentração de terras
              nas mãos da elite rural, exacerbando as desigualdades
              preponderantes até a atualidade.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Casas inglesas de importação e exportação
            </h2>
            <h3>1850</h3>
            <p>
              Durante o século XIX, havia uma significativa influência britânica
              no comércio marítimo. As casas de importação, localizadas
              principalmente em São Luís, desempenharam um papel importante como
              intermediárias nesse comércio, pois durante esse período, o
              Maranhão era uma região produtora de bens como algodão, açúcar e
              arroz, e o comércio internacional era uma parte fundamental da
              economia local. A presença dessas casas comerciais contribuiu para
              a inserção do Maranhão nas redes globais de comércio,
              influenciando não apenas a economia, mas também a cultura da
              região.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Abolição da escravatura
            </h2>
            <h3>1888</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858380/questao_agraria/1888_dzliei.png'
              alt='1888'
              className='max-w-full mb-4'
            />
            <p>
              A abolição da escravatura, por meio da Lei Áurea, marcou uma
              virada significativa no Maranhão, desafiando estruturas sociais
              preexistentes, pois 55% dos escravizados do império habitavam o
              estado. A transição para mão de obra assalariada teve repercussões
              diretas na dinâmica agrária local, impactando as práticas de
              cultivo e a distribuição de terras. Entretanto, os obstáculos
              enfrentados pelos ex-escravizados para obter acesso à terra
              deixaram um legado persistente, influenciando continuamente as
              relações entre emancipação e questão agrária na região.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Proclamação da República e o Massacre de 17 de novembro
            </h2>
            <h3>1889</h3>
            <p>
              Logo após a proclamação da república, monarquistas liberais
              insuflaram trabalhadores, muitos desses negros que temiam a volta
              da escravidão, entre eles: pescadores, estivadores, operários
              portuários e outros que se uniram em passeata pela cidade de São
              Luís. Dois dias depois do Brasil tornar-se república, esse grande
              grupo proletário manifestava-se contra a falta de democracia, ação
              exacerbada dos republicanos. Houve grande fuzilamento com quatro
              mortos e mais de quatrocentos feridos (vários com gravidade), além
              de muitos manifestantes serem presos e torturados. Este fato
              histórico é pouco conhecido devido a tentativa de seu apagamento
              no período de mudança política. Embora com poucas baixas, recebeu
              a qualificação de massacre pela intenção de assassinato por parte
              dos soldados e pelo grande número de manifestantes alvejados.
            </p>
          </section>
        </CardTimeline>
      </>
    )
  },
  {
    key: 3,
    year: '1900',
    content: (
      <>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Povoamento do campo maranhense
            </h2>
            <h3>1900</h3>
            <p>
              Os ex-escravizados passaram a viver nas terras abandonadas, doadas
              e devolutas. Essa população se estabeleceu em locais ermos, em
              meio às matas, vivendo de seu auto-abastecimento, sem monetização
              dos recursos. Assim como este contingente, os descendentes dos
              povos originários, quilombolas, cearenses e piauienses pobres
              ocuparam as terras do estado, em sua maioria devolutas,
              contribuindo para o campesinato maranhense. No início do século
              XX, um grande grupo de nordestinos somou-se aos camponeses,
              povoando uma outra parte das terras livres do Maranhão.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              O massacre de Alto Alegre
            </h2>
            <h3>1901</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858381/questao_agraria/1901_pr9czj.png'
              alt='1901'
              className='max-w-full'
            />
            <p>
              No povoado de Alto Alegre construiu-se um internato para os
              Guajajara, povo habitante da região, com catequese, letras e
              artes. Isso causou repulsa aos indígenas que não queriam entregar
              seus filhos para a educação europeia. Tamanha foi a revolta, que
              em 13 de março, liderados pelo cacique Cauiré Imana, todas as
              aldeias se reuniram e atacaram o Colégio das Irmãs, a residência
              dos religiosos e dos demais moradores, matando todos os habitantes
              dos arredores, cerca de 200 pessoas. Esse levante marcou a
              história do indigenismo brasileiro como símbolo de resistência e
              luta pela preservação da cultura indígena.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Conflitos no sertão maranhense
            </h2>
            <h3>1903</h3>
            <p>
              Devido ao estado de abandono do sertão por parte do poder público,
              houve uma série de denúncias da população sertaneja, que atribuía
              a este a falta de segurança pública e de infraestrutura em geral
              como, por exemplo, rodovias, escolas, prédios públicos, saneamento
              básico, assim como a grande corrupção do governo que violava leis.
              Esta situação era descrita pelos jornais da época como se houvesse
              um estado anárquico, com diversas rebeliões e manifestações hostis
              em grande parte do sertão. As cidades de Grajaú, Imperatriz,
              Carolina e Riachão foram algumas onde tais conflitos ocorreram.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              O algodão e a industrialização
            </h2>
            <h3>1904</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858381/questao_agraria/1904_olodcs.png'
              alt='1904'
              className='max-w-full'
            />
            <p>
              Em busca de levantar a economia local, em declínio devido ao
              empobrecimento em massa dos fazendeiros em razão da libertação dos
              escravizados e da queda de exportação, ocorre a implantação de um
              expressivo pólo têxtil, baseado principalmente na cotonicultura já
              tradicional no estado. Apesar da baixa do preço do algodão bruto
              nas décadas anteriores, a produção de tecidos crus, tintos,
              grossos e finos, além de novelos de fios nas fábricas chegou a
              progredir e empregar um bom contingente de operários. Porém, tal
              empreitada já nasceu vitimada pelos interesses dos cafeicultores
              do sudeste e do protecionismo do país, seguido por um parque
              oleaginoso, a partir do babaçu.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              A mão de obra informal pós-abolição
            </h2>
            <h3>1906</h3>
            <p>
              A produção agrícola dos latifúndios se manteve a partir do algodão
              e da pecuária extensiva. Impossibilitados de empregar mão de obra
              de baixo custo por meio do trabalho escravo, os latifundiários
              passaram a empregar trabalhadores sazonalmente. Os camponeses
              assentados em suas terras eram pagos para prestar serviços apenas
              nos períodos necessários. Dessa maneira não havia vínculo
              empregatício nas fazendas e evitavam o assalariamento rural.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Coronelismo e Oligarquias da República Velha
            </h2>
            <h3>1908</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858381/questao_agraria/1908_gedowc.png'
              alt='1908'
              className='max-w-full ml-[4rem]'
            />
            <p className='mt-[-6rem] absolute top-[68.5%] left-[7%]'>
              Nesta década os grandes <br />
              latifundiários chamados <br /> de “coronéis” se uniram <br />
              ao governo para manter <br />
              sua hegemonia no âmbito regional. Esta união era mantida por meio
              de práticas que iam desde a manipulação do eleitorado à
              distribuição de cargos públicos em troca de votos. O acordo era
              estritamente vinculado à sustentação dos interesses econômicos dos
              donos de terra e dos grandes comerciantes. Embora o coronelismo
              tenha sido presente em grande parte do país, tal prática se
              estendeu durante todo o século XX no Maranhão. Ainda hoje é
              possível observar algumas condutas que remetem a esse período.
            </p>
          </section>
        </CardTimeline>
      </>
    )
  },
  {
    key: 4,
    year: '1910',
    content: (
      <>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Mulheres nas fábricas
            </h2>
            <h3>1910</h3>
            <p>
              Tentando dissipar a crise econômica instaurada pelo declínio da
              agropecuária no estado, a promessa da industrialização trazida
              pelo sistema fabril que era instalado em algumas cidades do
              interior e na capital trouxe novas perspectivas. As fábricas
              contribuíram para a migração de mão de obra trabalhadora para
              esses centros industriais e marca a entrada de mulheres no mercado
              de trabalho, sob duras e longas jornadas de produção. Tais
              circunstâncias impulsionam a formação de associações operárias
              que, anos mais tarde, travariam embates decisivos por melhores
              direitos e condições de trabalho nas fábricas maranhenses.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Serviço de Proteção aos Índios (SPI)
            </h2>
            <h3>1910</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858382/questao_agraria/1910_vctpam.png'
              alt='1910'
              className='max-w-full'
            />
            <p>
              Criado a partir de um decreto ( nº. 8.072, de 20 de junho de
              1910), o SPI visava tanto a “proteção” quanto a "integração” das
              populações indígenas. Contudo, não passava de um mecanismo para
              fundar colônias agrícolas que utilizavam a mão de obra encontrada
              pelas expedições oficiais e conseguir a posse de suas terras
              tradicionais. Sua instituição ocasionou um período altamente
              crítico de supressão cultural e extermínio dos povos originários.
              Diversas frentes de expansão para o interior, ao longo de todo o
              país, faziam a guerra contra os nativos, expulsando-os de suas
              terras e dizimando os que resistiam.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              O porto e a formação de Balsas
            </h2>
            <h3>1911</h3>
            <p>
              Neste ano iniciou-se a construção de um porto na então Vila Santo
              Antônio de Balsas, que permitiu uma linha de navegação regular no
              rio que deu o nome a atual cidade, indo até rio Parnaíba. Esses
              rios já eram muito utilizados para comunicação entre as fazendas
              da região, favorecendo a articulação até dos vilarejos mais
              remotos em embarcações obsoletas. O trânsito dos barcos a vapor
              propiciou a fixação de famílias vindas de outras regiões do
              Maranhão e de outros estados nordestinos, o que movimentou o
              comércio e a economia local. A agricultura também se desenvolveu
              com um canal mais fácil e rápido para a comercialização de seus
              produtos, predominantemente carne e couro à época.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              A economia do babaçu
            </h2>
            <h3>1916</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858382/questao_agraria/1916_cdqlit.png'
              alt='1916'
              className='max-w-full ml-[44px]'
            />
            <p>
              Devido à escassez mundial de óleos vegetais durante os primeiros
              anos da Primeira Guerra Mundial, o babaçu maranhense ganha
              relevância econômica no meio internacional. Nesse período, houve
              uma arrecadação sem precedentes no estado, colocando o óleo de
              babaçu como terceiro produto mais exportado. Porém, a produção não
              conseguiu acompanhar a grande demanda do mercado europeu. A
              projeção não se manteve por muito tempo e os lucros com o babaçu
              diminuíram drasticamente.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              O "mal das fábricas"
            </h2>
            <h3>1917</h3>
            <p>
              No Maranhão, a tuberculose ganhou o título de "mal das fábricas",
              tornando-se a principal causa de morte em São Luís no ano de 1917.
              As más condições de trabalho, aliadas à falta de acesso a serviços
              médicos adequados, aumentaram o risco de contágio e dificultaram a
              recuperação dos doentes. Muitos desses operários, vindos do meio
              rural em busca de melhores oportunidades nas fábricas, enfrentavam
              condições adversas, perpetuando um ciclo de pobreza e doença. Essa
              migração silenciosa contribuiu para a continuidade do problema e
              evidenciou as desigualdades sociais e de saúde na época.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Mandioca nas pequenas lavouras
            </h2>
            <h3>1918</h3>
            <p>
              A estrutura rural do Maranhão permaneceu sem grandes avanços,
              caracterizada pela predominância da grande propriedade, baixa
              qualificação do trabalho rural e remuneração deprimida, resultando
              em uma cultura de subsistência restrita e desequilíbrios na
              balança comercial. Os latifundiários concentraram-se na pecuária
              extensiva e na produção de algodão, com o surgimento do
              assalariamento rural após a Primeira Guerra Mundial. Enquanto
              isso, a mandioca teve um crescimento razoável na pequena lavoura,
              por ser um cultivo que mantém níveis de produtividade aceitáveis
              mesmo em terras degradadas, comuns nas áreas acessíveis aos
              pequenos lavradores.
            </p>
          </section>
        </CardTimeline>
      </>
    )
  },
  {
    key: 5,
    year: '1920',
    content: (
      <>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Proibição e marginalização do bumba-boi
            </h2>
            <h3>1920</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858383/questao_agraria/1920_kn6y5l.png'
              alt='1920'
              className='max-w-[120%] ml-[-1rem] mb-[-2rem]'
            />
            <p>
              Sob o argumento de promover a "civilidade" o bumba-boi foi
              proibido no centro urbano de São Luís. Essa medida refletia o
              preconceito e a estigmatização da cultura popular maranhense,
              vista como uma "brincadeira de preto" e desprovida de valor
              artístico. A elite local, ao tentar impor uma visão de progresso,
              marginalizava essas manifestações culturais, relegando-as aos
              subúrbios e áreas rurais. Essa proibição evidenciava a tensão
              entre a valorização de uma cultura erudita e a rica tradição
              popular do estado, formada por elementos afro-brasileiros e
              indígenas.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              A frente nordestina
            </h2>
            <h3>1920</h3>

            <p>
              Embora esse fluxo migratório tenha se originado no século
              anterior, o interesse pela economia da borracha, em direção à
              Amazônia e a impossibilidade de permanência em sua terra de origem
              pelas secas periódicas, fenômenos naturais típicos do semi-árido,
              são as motivações apontadas como explicação para o deslocamento de
              famílias e da sua fixação, pelo acaso da existência de “terras
              devolutas”, no território maranhense durante a década de 1920.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Demarcação de terras
            </h2>
            <h3>1921</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1721780477/questao_agraria/1921_1_ohzh21.png'
              alt='1921'
              className='max-w-[110%] ml-[-1rem] mb-2'
            />
            <p>
              Foi uma medida governamental adotada com o intuito de organizar e
              regularizar a medição e demarcação das terras públicas e privadas.
              Em outras palavras, o decreto visava criar um registro mais claro
              e completo das terras, tanto as pertencentes ao governo quanto as
              de propriedades privadas. Esperava-se que essa regularização
              contribuísse para o fomento da economia local, incentivando
              investimentos e assegurando os direitos dos proprietários de
              terras. O decreto (n°417, de 11 de abril de 1921) representava um
              passo importante para a modernização e ordenamento do setor
              agrário no Maranhão, equilibrando os interesses do Estado e dos
              agricultores maranhenses.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Manuel Bernadino e a “Liga da Defesa”
            </h2>
            <h3>1921</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1721780477/questao_agraria/1921_2_vow620.png'
              alt='1921'
              className='max-w-[110%] ml-[-1rem] mb-[-2rem}'
            />
            <p>
              Surge como importante figura política, Manuel Bernadino,
              posteriormente apelidado de "Lenin da Matta". Liderou
              trabalhadores camponeses e despossuídos do Maranhão nas três
              primeiras décadas do século XX. Estabelecido no município de Dom
              Pedro, enfrentou um latifundiário que tinha por prática o estupro
              de mulheres no município de Mirador, originando a Liga da Defesa,
              composta por 100 homens armados para prevenir crimes e mediar
              conflitos agrários. O movimento aderiu à Coluna Prestes em 1925
              com cerca de 200 homens. Bernadino era reconhecido por suas visões
              socialistas, influenciadas pela bíblia, espiritismo,
              vegetarianismo e leituras de Tolstói.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              O Maranhão na Crise de 1929
            </h2>
            <h3>1929</h3>
            <p>
              Diferente de outros setores, o início da década seguinte trouxe
              melhores resultados para a produção agrícola maranhense, visto que
              a crise de 1929 não trouxe maiores consequências para o campo. Na
              verdade, o que se percebeu foi um aumento no volume das
              exportações de arroz, farinha e mandioca quando comparado ao
              período antecedente. Observou-se um aumento também da exportação
              de algodão para os teares do centro-sul.
            </p>
          </section>
        </CardTimeline>
      </>
    )
  },
  {
    key: 6,
    year: '1930',
    content: (
      <>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Revolução de 30
            </h2>
            <h3>1930</h3>
            <p>
              A Era Vargas, inaugurada pós-revolução de 30 com amplo apoio da
              população do norte do país, acelerou o processo de urbanização e
              industrialização no Brasil. Isso refletiu no estado do Maranhão,
              cujos habitantes consideravam-se negligenciados pelo poder
              público, de forma que surgiu a esperança de maiores investimentos
              e consequentemente o aumento da qualidade de vida da população.
              Mais uma vez o êxodo rural intensificou-se pela busca de melhores
              oportunidades, ocasionando a periferização urbana. As expectativas
              nos grandes centros não foram supridas, pois esses trabalhadores
              recebiam baixos salários e tinham maiores custos para se manterem.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              O monopólio estrangeiro do algodão
            </h2>
            <h3>1933</h3>
            <p>
              O algodão, maior produto maranhense na época, foi entregue ao
              monopólio de uma economia estrangeira, o que tornou sua taxa de
              prensagem exorbitante, juntamente com a falta de qualidade e
              manutenção das máquinas de prensa, situações que contribuíram para
              prejudicar as exportações. Além disso, o governo estadual sofria
              com diversas reclamações dos comerciantes por conta do acréscimo
              do imposto de indústria e profissões, aumento que tinha a intenção
              de prevenir surpresas deficitárias no orçamento. O intenso
              contrabando na faixa do Rio Parnaíba e impostos proibitivos
              forçaram o escoamento da produção para estados vizinhos, como o
              Pará e o Piauí.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Investimentos mercantis
            </h2>
            <h3>1936</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858383/questao_agraria/1936_agpqmf.png'
              alt='1936'
              className='max-w-[110%] ml-[-1rem] my-[-1rem]'
            />
            <p>
              O sistema econômico era dominado pelo capital mercantil, com as
              grandes casas comerciais comandando a acumulação. Havia grande
              fomento na produção agrícola e extrativista junto aos
              investimentos urbanos. No início, se concentrou quase que
              totalmente na criação de fábricas têxteis, falidas posteriormente
              por conta da má qualidade do algodão e dos equipamentos utilizados
              que eram ultrapassados. espaço para o incremento do óleo babaçu,
              que foi considerado a salvação econômica do Maranhão, porém também
              não alcançou os resultados esperados.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Instituição do “Estado Novo” e o indigenismo
            </h2>
            <h3>1937</h3>
            <p>
              Após promulgar uma nova Constituição Federal, Vargas deu início ao
              Estado Novo, período em que o Congresso Federal foi fechado,
              concentrando todo o poder na figura do presidente. Com um forte
              discurso nacionalista, Getúlio lançou a Marcha para o Oeste,
              executada pelo Serviço de Proteção ao Índio, que promoveu a
              “pacificação” dos grupos isolados de indígenas, integrando-os à
              sociedade de forma repentina e abrupta. Esse projeto buscou fixar
              os indígenas e os sertanejos às terras, disciplinando a força de
              trabalho no sistema capitalista. De tal maneira, promoveu a
              miscigenação e aculturação desses povos. Houve resistência à
              apropriação de suas terras e exploração de mão de obra análoga à
              escravidão.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Inauguração da via férrea
            </h2>
            <h3>1938</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1727137898/questao_agraria/1938_zkduim.png'
              alt='1938'
              className='max-w-full'
            />
            <p>
              A inauguração da via férrea, ainda que sem a ponte metálica,
              unindo o Maranhão e o Piauí facilitou a integração do sertão
              maranhense e trouxe melhores condições econômicas e sociais para
              os sertanistas devido a rapidez no transporte e na entrega de
              mercadorias aos destinatários. Contudo, a construção da ferrovia
              favoreceu sobretudo as pessoas com maior poder aquisitivo, que
              usufruíram dos benefícios ocasionados pela circulação econômica
              gerada com a fábrica têxtil e agilidade do transporte.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Economia cambaleante
            </h2>
            <h3>1939</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858377/questao_agraria/1938_ikcz8p.png'
              alt='1939'
              className='max-w-full'
            />
            <p>
              Ao fim da década de 1930, principalmente com o início da Segunda
              Guerra, a economia maranhense se reanimou pela alta da demanda do
              algodão, dos tecidos e do babaçu. Infelizmente, o fomento ao
              volume de produção foi muito restrito, mantendo o perfil da
              economia inalterado. A única modificação evidenciada de fato foi o
              crescimento vegetativo da população. Porém, com a taxa de mão de
              obra declinante devido a decrescente produtividade do solo e
              redução de área de produção, contava ainda com uma infraestrutura
              insuficiente para o transporte e tecnologia arcaica.
            </p>
          </section>
        </CardTimeline>
      </>
    )
  },
  {
    key: 7,
    year: '1940',
    content: (
      <>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Organização sindical
            </h2>
            <h3>1944</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858378/questao_agraria/1944_zcopxg.png'
              alt='1944'
              className='max-w-full'
            />
            <p>
              Os trabalhos da Comissão Sindical da Agricultura Brasileira
              originaram o decreto-lei n° 7038/44, que estabeleceu a
              representação da agricultura abrangendo separadamente o sindicato
              patronal e o sindicato de trabalhadores rurais, a partir de normas
              semelhantes àquelas estabelecidas para o meio urbano. Porém logo
              foi contestada pela própria comissão alegando que as relações
              trabalhistas no campo eram mais complexas devido aos laços
              familiares e de parceria entre as partes envolvidas, das quais
              muitas vezes o empregador se valia para disfarçar a exploração,
              como por exemplo pelo apadrinhamento.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Formação de associações rurais
            </h2>
            <h3>1945</h3>
            <p>
              O sistema de representação agrícola implementado em um novo
              decreto não dividiu patrões e empregados, como acontecia no meio
              urbano, mas englobava profissionais rurais podendo estes serem
              proprietários, parceiros ou arrendatários. Previa a criação de uma
              Confederação da agricultura com associações municipais, estaduais
              e federais denominando-a Confederação Rural Brasileira (CRB).
              Desta forma, essa norma acaba suprimindo os direitos dos
              trabalhadores rurais, que não tinham sua classe distinguida
              daqueles que eram seus empregadores, muito embora tenha
              contribuído para sua organização em associações.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Reforma Agrária e a Constituição de 46
            </h2>
            <h3>1946</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858378/questao_agraria/1946_w40ggc.png'
              alt='1946'
              className='max-w-full'
            />
            <p>
              A Constituição promulgada em 1946 trazia artigos que previam a
              desapropriação de terras, ainda que “mediante prévia e justa
              indenização em dinheiro ". Embora entendida como um documento
              democrático, possuía as suas limitações e não atendia por inteiro
              às demandas populares em ascensão na época. A reforma agrária
              ficou inviabilizada porque o documento definia sua realização
              apenas por meio de compensação monetária. Ainda assim, teve sua
              relevância por ser uma das primeiras vezes a debater na Assembleia
              Constituinte pautas sobre a necessidade de algum tipo de
              redistribuição de terras.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Exploração de minerais preciosos
            </h2>
            <h3>1948</h3>
            <p>
              Nesta década, houve um movimento de exploração de metais e pedras
              preciosas na região sudoeste do Maranhão entre os municípios de
              Grajaú e Imperatriz, que são próximos ao riacho Barbosa (afluente
              do rio Grajaú), o que atraiu muitas pessoas do centro-sul
              maranhense e dos estados do Pará, Piauí e do Ceará. Como principal
              via de acesso ao rio Tocantins, a exploração de diamantes, ouro,
              esmeraldas, azulinas e ferro prosperou. Esse processo contribuiu
              para o aumento populacional e urbanização dessa parte do estado.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Rota de migração
            </h2>
            <h3>1949</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723306041/questao_agraria/1949_wqphbx.png'
              alt='1949'
              className='max-w-full'
            />
            <p>
              Nesse ano foi aberta uma estrada, chamada de “estrada do
              Permínio”, que partia da margem do rio Tocantins em direção ao
              distrito grajauense de Amarante construída pelos comerciantes
              Permínio Queiroz e Floriano Costa. Essa rota tornou-se importante
              pois servia para o escoamento de insumos agrícolas, muito
              abundantes na região. Imediatamente após a ligação rodoviária de
              Imperatriz a Grajaú, uma nova etapa socioeconômica se estabeleceu
              no município, com a abertura de uma rota comercial. Paralelamente,
              mais uma vez prejudicados pelas secas e por conflitos agrários,
              ocorreu um surto migratório de maranhenses e nordestinos de outros
              estados para o município de Imperatriz, onde havia grande volume
              de terras devolutas, vegetação diversa e águas perenes.
            </p>
          </section>
        </CardTimeline>
      </>
    )
  },
  {
    key: 8,
    year: '1950',
    content: (
      <>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Ameaças a territórios
            </h2>
            <h3>1950</h3>
            <p>
              A década de 1950 marca o início do processo de perda de terras em
              diversos territórios no estado, como é o caso de Santa Rosa dos
              Pretos em Itapecuru Mirim. Com o passar do tempo, o que sobreveio
              foi um esquadrinhamento territorial nessa comunidade. A
              expropriação para construção da ferrovia Transnordestina, rodovia
              BR-135, fazendas, linhões de energia e a estrada de ferro da Vale
              impactaram profundamente a região. Processo que ocorre ainda hoje,
              apesar da resistência dos moradores que continuam lutando por seu
              lugar.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Rebelião do Maranhão
            </h2>
            <h3>1951</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723305905/questao_agraria/1951_wxvyy2.png'
              alt='1951'
              className='max-w-full'
            />
            <p>
              Foi uma das maiores greves da história do Maranhão. A anulação de
              16 mil votos da capital pelo Tribunal Regional Eleitoral (TRE)
              acabou por fazer Eugênio Barros (PST) vitorioso na eleição de
              governador do estado, quando, na primeira apuração, ocupava o
              segundo lugar. Tal ordem causou grande revolta na população, que
              ocupou a Praça da Liberdade (atual Praça João Lisboa), instituindo
              greve geral. Vários sindicatos de trabalhadores e organizações
              aderiram e reivindicaram a alteração do resultado. Embora tal
              manifestação tenha sido muito relevante por evidenciar o poder de
              mobilização das classes trabalhadoras, não obteve êxito em seu
              principal pleito. A despeito do apelo da população, o candidato
              foi empossado no cargo.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Comissão Estadual de Reforma Agrária
            </h2>
            <h3>1954</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723305808/questao_agraria/1954_h2epp0.png'
              alt='1954'
              className='max-w-full mb-4'
            />
            <p>
              Comissão criada por trabalhadores rurais maranhenses no município
              de São Luís, a partir das resoluções aprovadas na II Conferência
              dos Trabalhadores Agrícolas, realizada em São Paulo. Articulada a
              uma campanha nacional, percorreu municípios do Maranhão com
              objetivo de recolher cinco milhões de assinaturas pela reforma
              agrária. Era composta por pessoas de diferentes frentes como
              advogados, trabalhadores rurais e estudantes. Sua ação foi
              fundamental para a articulação e organização dos campesinos, que
              iniciaram um processo de criação de associações, como a Associação
              dos Trabalhadores Agrícolas do Maranhão (ATAM). Esta surgiu com o
              prisma de articular as lutas vinculadas à União dos Lavradores e
              Trabalhadores Agrícolas do Brasil (ULTAB). Tal atuação
              possibilitou ainda um ambiente de troca de ideias e debates entre
              os camponeses de diferentes regiões diante dos desafios
              enfrentados.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              A jornada de Manoel da Conceição
            </h2>
            <h3>1955</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858378/questao_agraria/1955_ctwmf3.png'
              alt='1955'
              className='max-w-full'
            />
            <p>
              O líder camponês Manoel da Conceição, um dos mais importantes
              símbolos de resistência do campo e da ditadura militar, ainda
              criança, teve sua vida marcada pela exclusão político-econômica e,
              junto à sua família, foi expulso das terras em que vivia. A partir
              dessa situação, foi levado a residir em Bacabal do Mearim-MA,
              tendo sua vocação maior voltada ao trabalho da terra. Nesse
              período deparou-se com a expulsão novamente, por um latifundiário
              pecuarista. Entre expulsões, perseguição de grileiros e massacres
              aos trabalhadores rurais da região, a família se dispersou, indo
              Manoel para o povoado São José do Tufi, no município de Pindaré
              Mirim. Neste local conheceu e se interessou pelo movimento de luta
              dos trabalhadores rurais, participando de um curso de formação
              sindical e cooperativa através do Movimento de Educação de Base
              (MEB), nesse momento seu envolvimento tornou-se cada vez mais
              intenso nas lutas sindicais, tornando-se uma grande liderança no
              meio.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              A Rodovia Belém-Brasília
            </h2>
            <h3>1958</h3>
            <p>
              Na década de 50, com a necessidade de povoar a região amazônica e
              ligar as regiões Norte e Nordeste do Brasil com o restante do
              país, inicia-se a construção da rodovia Belém-Brasília . O trecho
              que atravessa o Maranhão é destacado pela BR-010 e perpassa
              diversos municípios do oeste do estado, como Imperatriz e
              Governador Edson Lobão, destacando nessa área um intenso fluxo de
              passageiros e cargas que abastecem a região Norte e Nordeste do
              país, além do fluxo provenientes das atividades extrativistas,
              minerárias e carvoeiras que abastecem as indústrias instaladas na
              região de Imperatriz. A rodovia promoveu a redefinição do espaço
              geográfico e econômico, uma vez que permitiu a ocupação de regiões
              de baixa demografia com o estabelecimento de novas atividades
              econômicas, além de otimizar a comunicação do estado com o
              restante do país.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Instituição e declínio da SUDENE
            </h2>
            <h3>1959</h3>
            <p>
              A Superintendência do Desenvolvimento do Nordeste (SUDENE) tinha
              como seu principal objetivo encontrar soluções que permitissem a
              progressiva diminuição das desigualdades verificadas entre as
              regiões brasileiras. No Maranhão, desenvolveu projetos de
              irrigação em áreas secas, cultivo de plantas resistentes à aridez,
              entre outras. Com a Ditadura Militar, a instituição foi sofrendo
              cada vez mais o desvio dos objetivos iniciais, sendo considerada
              uma entidade que, além de não realizar os fins a que se propunha,
              era um foco de corrupção.
            </p>
          </section>
        </CardTimeline>
      </>
    )
  },
  {
    key: 9,
    year: '1960',
    content: (
      <>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Criação de búfalos na Baixada Maranhense
            </h2>
            <h3>1961</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858377/questao_agraria/1961_rhfcek.png'
              alt='1961'
              className='max-w-[110%] ml-[-1rem] my-[-1.5rem]'
            />
            <p>
              Em razão da visão desenvolvimentista do governo estadual da época,
              empreendeu-se a criação de búfalos nos campos da Baixada
              Maranhense, ocasionando conflitos sócio-ambientais por não
              observarem as peculiaridades ambientais da região, nem as
              comunidades tradicionais que a habitavam e tinham como fonte de
              subsistência os recursos naturais do local. Isso resultou em um
              considerável aumento de conflitos no campo, pois os criadores
              cercavam grandes áreas dos campos inundáveis de uso comum a fim de
              convertê-las em currais bubalinos, impactando a economia camponesa
              e o ambiente local.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              O Golpe militar
            </h2>
            <h3>1964</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723305746/questao_agraria/1964_pok74d.png'
              alt='1964'
              className='max-w-full'
            />
            <p>
              A repressão aos movimentos democráticos na ditadura militar
              colocou entre os “inimigos do regime”, sindicalistas, estudantes,
              políticos, religiosos progressistas e defensores da democracia em
              geral, entre esses estavam os movimentos de trabalhadores rurais.
              No Maranhão, a sede do Sindicato dos Trabalhadores Rurais de
              Pindaré Mirim fora ocupada pelo Exército e mais de 200 lideranças
              presas e levadas para São Luís, entre eles, o sindicalista rural
              Manoel da Conceição. Todas as organizações e movimentos sociais
              foram neutralizados. Aqui destaca-se o Movimento de Educação de
              Base (MEB), voltado à educação popular da cidade e do campo com o
              intuito de ampliar a participação política e social dessas camadas
              com foco na sindicalização e formação de lideranças no meio rural,
              que foi totalmente desmantelado à época.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              A vez da SUDAM
            </h2>
            <h3>1966</h3>
            <p>
              A Superintendência do Desenvolvimento da Amazônia (SUDAM) concedia
              incentivos fiscais para projetos em favor da Região Amazônica.
              Atuou principalmente na captação de investimentos para a Amazônia,
              em todos os estados que esta abrange. Também conduzia a
              coordenação, supervisão e, até mesmo, a elaboração e execução de
              programas e planos de outros órgãos federais. No Maranhão, essas
              políticas atraíram o interesse de grupos econômicos externos que
              aproveitaram a oportunidade para instalar grandes latifúndios na
              Amazônia maranhense.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Guerrilha do Araguaia
            </h2>
            <h3>1966</h3>
            <p>
              Foi um movimento guerrilheiro iniciado ao fim da década de 1960 na
              região denominada Bico do Papagaio, onde corre o rio Araguaia,
              abrangendo parte dos territórios do Maranhão, Pará e do atual
              Tocantins. Criada pelo Partido Comunista do Brasil (PCdoB), a
              guerrilha tinha por objetivo promover uma revolução socialista do
              campo para a cidade. Tal local foi escolhido devido a grande
              população rural migrante especialmente do nordeste em busca de
              terras, garimpo e caça. A maioria desses acabaram explorados por
              fazendeiros e grileiros, prestando serviço por salários
              baixíssimos. Com este cenário, os mais de oitenta militantes do
              partido (universitários e profissionais liberais), se infiltraram
              nas comunidades, estabelecendo bases nos vilarejos e se preparando
              para uma revolução. Em 1972, foram descobertos pelo Exército
              brasileiro e executados em sua grande maioria, sobrevivendo menos
              de vinte dos combatentes.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Criação da FUNAI
            </h2>
            <h3>1967</h3>
            <p>
              A Fundação Nacional do Índio (FUNAI) veio substituir o Serviço de
              Proteção aos Índios (SPI), órgão responsável por atuar nas
              questões indígenas no país. Embora tenha sido criada para proteger
              essa população e promover seu desenvolvimento, muitas vezes teve
              seus procedimentos voltados aos interesses da União,
              principalmente durante a Ditadura, que utilizava as demarcações
              para reconhecimento e domínio das terras amazônicas, que à época
              eram cedidas apenas como usufruto aos povos originários. Com o
              surgimento de novas leis, a FUNAI foi se tornando muito relevante,
              pois foi responsável por trazer ao conhecimento dos governantes e
              da nação, dados que viabilizaram políticas indigenistas, sendo a
              principal executora dessas.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Lei Sarney de Terras
            </h2>
            <h3>1969</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1721781399/questao_agraria/1969_huuptr.png'
              alt='1969'
              className='max-w-full ml-6 mb-2'
            />
            <p>
              Sob o pretexto de modernizar o setor agrícola do Maranhão, o então
              governador José Sarney criou a Lei nº 2.979/69. A ilação dessa lei
              era que apenas empresários capitalistas poderiam modernizar o
              Estado, uma vez que o pequeno agricultor “é arcaico e sem
              instrução”. Criou-se Delegacias de Terras, cujo propósito era
              disciplinar e organizar a ocupação das terras livres e titular
              áreas reservadas à colonização. E assim a lei promoveu a entrega
              dos recursos naturais do estado aos grandes empreendedores rurais.
              A maior parte das terras devolutas foram destinadas aos grupos
              empresariais que formaram grandes latifúndios, causando inúmeros
              conflitos com a população campesina nas décadas subsequentes.
            </p>
          </section>
        </CardTimeline>
      </>
    )
  },
  {
    key: 10,
    year: '1970',
    content: (
      <>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              A Fundação do INCRA
            </h2>
            <h3>1970</h3>
            <p>
              O Instituto Nacional de Colonização e Reforma Agrária (INCRA) é
              uma autarquia federal criada com a missão prioritária de realizar
              a reforma agrária, manter o cadastro nacional de imóveis rurais e
              administrar as terras públicas da União. Sua constituição é
              posterior aos fatos que marcaram a origem do Estatuto da Terra,
              durante o período da ditadura militar, e a extinção do Instituto
              Brasileiro de Reforma Agrária (IBRA) e do Instituto Nacional de
              Desenvolvimento Agrário (INDA). Esse órgão viabilizou um avanço na
              consolidação de assentamentos rurais e na regularização fundiária,
              além da assistência técnica e social do trabalhador rural. Com um
              histórico marcado de controvérsias envolvendo o processo de
              regularização fundiária, o instituto é extinto em 1987,
              posteriormente retoma suas atividades, transformando-se em um
              importante instrumento governamental no processo de reforma
              agrária no Brasil.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>COMARCO</h2>
            <h3>1971</h3>
            <p>
              Com a missão de impulsionar o mercado de terras no Maranhão,
              atendendo ao ensejo do governo militar cujo objetivo era integrar
              o mercado local ao mundial, o governo Sarney criou a Companhia
              Maranhense de Colonização (COMARCO) e acabou direcionando-se para
              grandes obras de infraestrutura por todo o estado. Apesar de ter
              sido idealizada com o objetivo de coordenar a política de
              colonização do campesinato e fomentar a criação de projetos
              agroeconômicos (extrativo-siderúrgico-energético) no estado,
              mostrou-se apenas como um arranjo de interesses que contribuiu
              para a manutenção do controle político oligárquico, além de
              facilitar o acesso a terras griladas no estado.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>FETAEMA</h2>
            <h3>1972</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723305678/questao_agraria/1972_q5xcal.png'
              alt='1972'
              className='max-w-full'
            />
            <p>
              A Federação dos Trabalhadores Rurais Agricultores e Agricultoras
              do Estado do Maranhão (FETAEMA) surge com um papel de unir tal
              categoria de trabalhadores para o debate sobre questões da
              agricultura familiar, previdência social, assalariamento rural,
              conflitos agrários, entre outros desafios que se opõem ao modelo
              de desenvolvimento conservador e concentrador de terras no
              Maranhão e no Brasil. Essa organização tem a finalidade de
              fomentar a luta sindical a partir da implementação das políticas
              estruturantes do Projeto de Desenvolvimento Sustentável e
              Solidário (PADRSS). Sua atuação é marcante no processo de disputa
              de projetos políticos de desenvolvimento.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              II Plano Nacional de Desenvolvimento
            </h2>
            <h3>1974</h3>
            <p>
              O Segundo Plano Nacional de Desenvolvimento, diferentemente do
              primeiro (1971), voltou seus incentivos à produção de energia,
              insumos básicos e bens de capital. A prioridade era implantar
              grandes projetos industriais: ferro, aço, celulose e alumínio. O
              país enfrentava a crise do petróleo, o que aumentou suas
              importações em 80%, necessitando de um aumento na produção
              nacional. No Maranhão, a dinamização desse processo permitiu a
              instalação de mega projetos industriais encabeçados pelo Programa
              Grande Carajás. Dessa forma, o estado novamente voltava a se
              inserir na dinâmica do capitalismo internacional, agora como
              corredor de exportação de minério de ferro, impulsionando a
              distribuição de grandes extensões de terras, o que tornou a
              grilagem uma conduta comum.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Comissão Pastoral da Terra
            </h2>
            <h3>1975</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723160292/questao_agraria/1975_aabnbc.png'
              alt='1975'
              className=' mb-4'
            />
            <p>
              A Comissão Pastoral da Terra é uma instituição civil, sem fins
              lucrativos, criada pela Conferência Nacional dos Bispos do Brasil
              (CNBB) para atuar nas questões agrárias no período em que a
              repressão atingia agentes pastorais e lideranças populares. Logo,
              adquiriu caráter ecumênico, tanto no sentido dos trabalhadores que
              eram apoiados, quanto na incorporação de agentes de outras igrejas
              cristãs, destacadamente da Igreja Evangélica de Confissão Luterana
              no Brasil – IECLB. Fundada durante a ditadura militar,
              objetivou-se a ser resposta à grave situação vivida pelos
              trabalhadores rurais e posseiros, explorados em seu trabalho,
              submetidos a condições análogas à escravidão e expulsos das terras
              que ocupavam.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>PRODECER</h2>
            <h3>1979</h3>
            <p>
              O Programa de Cooperação Nipo-Brasileira para o Desenvolvimento
              dos Cerrados (PRODECER), iniciado na década de 1970, desempenhou
              um papel crucial na intensificação da produção de soja no Brasil,
              especialmente na região dos Cerrados. Embora o cultivo de soja já
              existisse no país, o PRODECER expandiu significativamente sua
              produção ao fornecer recursos técnicos, financeiros e
              infraestruturais. Por mais que tenha contribuído para a expansão
              agrícola, o programa transformou áreas anteriormente consideradas
              inadequadas em regiões de monocultura, muitas vezes à custa da
              biodiversidade e dos ecossistemas locais. Sua abordagem focada na
              produtividade levanta questões sobre a sustentabilidade ambiental
              e os impactos sociais nas comunidades, marcando uma era de
              desenvolvimento agrícola.
            </p>
          </section>
        </CardTimeline>
      </>
    )
  },
  {
    key: 11,
    year: '1980',
    content: (
      <>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Consórcio Alumar
            </h2>
            <h3>1980</h3>
            <p>
              Inicialmente formado pelas empresas estrangeiras ALCOA, ALCAN, BHP
              BILLITON e ABALCO, o Consórcio ALUMAR é um dos maiores na produção
              de alumínio e alumina. Na sua instalação em São Luís, foram
              destruídos 17 povoados, efetuadas mais de 20.000 ações de despejo
              e desapropriadas mais de 20.000 propriedades. Algumas famílias
              receberam irrisórias indenizações, mas de forma antagônica, foi
              farta a truculência policial no processo de desocupação. O governo
              do Estado entregou cerca de 11% de toda área física da Ilha de São
              Luís, correspondente a 90.000 hectares, à ALCOA – que também se
              apropriou de 57% de toda área (tipicamente rural) do distrito
              industrial do município.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Projeto Grande Carajás
            </h2>
            <h3>1982</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858378/questao_agraria/1982_pauxcd.png'
              alt='1982'
              className='max-w-full mb-4'
            />
            <p>
              A construção da Estrada de Ferro Carajás ocorreu nos estados do
              Maranhão e Pará com o objetivo de transportar os minérios da
              Província Mineral de Carajás - PA para outras localidades e
              principalmente para os portos, a fim de ser importado e exportado.
              Inicialmente sua função era o transporte de matéria-prima, em
              seguida iniciou o transporte de pessoas. Em teoria, o projeto
              deveria promover o desenvolvimento dos municípios cortados pelos
              trilhos, mas o resultado foi o aumento das desapropriações de
              terras, conflitos que geraram assassinatos e malefícios para a
              população que sofre com a poluição sonora, do ar, do solo e das
              águas, prejudicando a saúde e o modo de vida das comunidades.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Centro de Lançamento de Alcântara
            </h2>
            <h3>1983</h3>
            <p>
              Com a justificativa de ser um empreendimento que traria
              desenvolvimento para uma região pouco explorada, com um
              posicionamento estratégico e pouca densidade demográfica, em 1980
              o governo do estado doou aproximadamente metade do território do
              município de Alcântara para a construção do CLA, sendo inaugurado
              em 1983. Desde a permissão da construção, a numerosa população
              quilombola residente foi afetada com a desapropriação violenta das
              terras tradicionalmente habitadas. Povoados inteiros foram
              compulsoriamente deslocados para agrovilas que não supriam
              adequadamente suas necessidades de terra, água e casa própria,
              gerando diversos conflitos e dificuldades estruturais.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>MST</h2>
            <h3>1985</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723305618/questao_agraria/1985_lb3iay.png'
              alt='1985'
              className='max-w-full mb-4'
            />
            <p>
              O Movimento dos Trabalhadores Rurais Sem Terra (MST) iniciou sua
              estruturação na Amazônia Legal maranhense em meados da década de
              1980 como parte de seu processo de organização em todo o Brasil. O
              movimento emergiu como uma resposta à expansão dos latifúndios e à
              expropriação dos territórios camponeses. A primeira ocupação no
              Maranhão ocorreu em uma área a cerca de 30km do município de
              Imperatriz, onde foram ocupadas terras improdutivas pertencentes a
              uma multinacional japonesa. A área, posteriormente denominada
              Assentamento Vila Conceição, foi ocupada por 250 famílias de
              trabalhadores rurais e, ao longo do tempo, tornou-se referência na
              produção de alimentos no estado.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Assassinato do PE Josimo
            </h2>
            <h3>1986</h3>

            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723305448/questao_agraria/1986_exzoqf.png'
              alt='1986'
              className='max-w-[70%] ml-6 mb-4'
            />

            <p>
              Coordenador da Comissão Pastoral da Terra (CPT) na região do Bico
              do Papagaio, o padre, atento ao sofrimento dos trabalhadores
              rurais, despertou grande insatisfação por parte dos
              latifundiários. Josimo expressou seu chamado religioso em defesa
              do povo excluído e por isso vivia sob constantes ameaças, e em 10
              de maio de 1986, foi assassinado com dois tiros em Imperatriz-MA.
              Seu sepultamento contou com um número considerável de camponeses,
              o que trouxe grande notoriedade para o caso, levando à condenação
              de seus algozes. Esse crime gerou comoção, sendo até hoje
              considerado um marco na luta campesina.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Lei do Babaçu livre
            </h2>
            <h3>1986</h3>
            <p>
              Devido a necessidade das populações tradicionais de quebradeiras
              de coco babaçu impedirem o avanço da devastação dos babaçuais,
              além de garantirem acesso às áreas de extração em razão do
              crescente cercamento e privatização dos campos, algumas leis
              municipais foram criadas para tentar tratar do tema. No entanto, a
              lei estadual nº 4734/18 de junho de 1986 tornou-se particularmente
              relevante ao proibir a derrubada das palmeiras em todo o estado,
              apesar de não prever o livre acesso para a coleta do coco.
            </p>
          </section>
        </CardTimeline>
      </>
    )
  },
  {
    key: 12,
    year: '1990',
    content: (
      <>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Implantação da Suzano
            </h2>
            <h3>1991</h3>
            <p>
              Inicialmente voltada à produção de celulose, a empresa logo
              direcionou-se majoritariamente à produção carvão vegetal,
              expandindo o plantio de eucalipto em toda região do município de
              Imperatriz. O empreendimento tinha por objetivo avançar a cultura
              do eucalipto na fronteira agrícola da Amazônia Legal,
              apropriando-se de recursos naturais com a intenção de
              transformá-los em commodities. O cultivo do eucalipto ocupa
              imensas áreas afetando a água, o ar e o solo, trazendo prejuízo
              para a população rural.
            </p>
          </section>
        </CardTimeline>

        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Polícia Militar como instrumento de repressão
            </h2>
            <h3>1992</h3>
            <p>
              A polícia militar, seja por ordem do Poder Judiciário, seja em
              apoio aos grandes latifundiários, realizou 23 intervenções no
              Maranhão, sendo 20 delas violentas, de acordo com a CPT. Foram
              efetuadas a expulsão e destruição das comunidades, como as
              ocorridas em Serraria, no município de Vitória do Mearim e na
              comunidade de Terra Bela, em Imperatriz. As habitações foram
              demolidas, plantações dizimadas, a população ameaçada e torturada,
              além do assassinato de um camponês. A atuação da brigada militar
              contribuiu junto com as ações dos fazendeiros e pistoleiros para o
              aumento dos conflitos agrários.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Expropriação de territórios indígenas
            </h2>
            <h3>1993</h3>
            <p>
              O governo deveria concluir a demarcação das terras indígenas,
              conforme a Constituição Federal de 1988. No entanto, adotou uma
              política de negociação com invasores e interessados nos recursos
              dessas áreas, sugerindo que poderia ajustar tais terras conforme
              conveniência política. Essa conduta enfraqueceu o direito dos
              povos originários e a obrigação do governo, que fez uso de força
              policial para intimidar essa população. Tal política inviabilizou
              demarcações, como no território dos Krikati, no Maranhão, deixando
              os indígenas à própria sorte na luta pela recuperação de suas
              terras, devido à omissão e conivência do estado.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Aumento dos campos de soja
            </h2>
            <h3>1995</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723305307/questao_agraria/1995_ipuevs.png'
              alt='1995'
              className='max-w-full'
            />
            <p>
              Com o desenvolvimento de tecnologias para o cultivo de soja no
              Cerrado, subsídios governamentais à exportação e a estrada de
              ferro para o escoamento da produção para o porto em São Luís, a
              produção de soja no Maranhão (inexpressiva até os anos 1980)
              evoluiu exponencialmente na década de 1990. Partindo do Cerrado do
              sul até o Baixo Parnaíba, chegou a dobrar a área ocupada com a
              produção entre 1995 (87,7 mil hectares) e 2000 (178,7 mil
              hectares), ocupando terras antes destinadas às culturas
              tradicionais de alimentos básicos. Apesar da alta taxa de
              crescimento de produção e exportação, tal avanço econômico não se
              refletiu nos aspectos socioambientais. Ao contrário, promovendo
              apenas a concentração de renda, gerou poucos postos de trabalho e
              ainda prejudicou a agricultura campesina e a saúde do camponês
              devido ao empobrecimento e contaminação do solo e da água pela
              retirada da cobertura vegetal e o intensivo uso de insumos
              altamente poluentes.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Maior taxa de pobreza do Brasil
            </h2>
            <h3>1995</h3>
            <p>
              Mesmo com o aumento de exportações de commodities e a construção
              de diversos empreendimentos que prometiam mais desenvolvimento e
              qualidade de vida para a população de vários municípios
              maranhenses, em 1995 o estado do Maranhão alcançou a marca de
              maior taxa de pobreza absoluta do Brasil com 77,8%, segundo o
              IPEA. Quanto à taxa de pobreza extrema, a marca foi de 53,1%, o
              que torna clara a grande desigualdade social e má distribuição de
              recursos. Os dados evidenciam uma disparidade significativa entre
              o crescimento econômico (aumento da exportação e novos
              empreendimentos) e a situação real da população, que continuava
              enfrentando níveis alarmantes de pobreza.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Massacre de Eldorado dos Carajás
            </h2>
            <h3>1996</h3>
            <p>
              Milhares de sem-terra estavam protestando no Sudoeste do Pará com
              o objetivo de chegar em Belém para pedir a desapropriação da
              Fazenda Macaxeira, que já era ocupada por inúmeras famílias. A
              manifestação foi interrompida por policiais de maneira
              extremamente repressiva com o aval do governo estadual. Durante
              tentativas de negociações, houveram inúmeros conflitos
              territoriais com a polícia militar, onde 19 trabalhadores ligados
              ao MST foram assassinados, dentre esses, 11 maranhenses. O
              Massacre de Eldorado dos Carajás marcou a luta pela terra no
              Brasil e no mundo, colocando a questão agrária sob uma outra
              perspectiva.
            </p>
          </section>
        </CardTimeline>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Trabalho infantil nas carvoarias
            </h2>
            <h3>1997</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723305369/questao_agraria/1997_eingk3.png'
              alt='1997'
              className='max-w-full'
            />
            <p>
              A Equipe de Fiscalização Móvel do Ministério do Trabalho realizou
              vistorias a carvoarias na Amazônia maranhense em junho de 1997. A
              partir da análise das fichas dos funcionários em dois
              empreendimentos selecionados, constatou-se a presença de treze
              adolescentes entre os trabalhadores. Os quais apresentavam o mesmo
              perfil: oriundos de extrema pobreza e de baixíssima escolaridade.
              Os considerados alfabetizados nas fichas mal conheciam o alfabeto.
            </p>
          </section>
        </CardTimeline>
      </>
    )
  },

  {
    key: 13,
    year: '2000',
    content: (
      <>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Usina Hidrelétrica Estreito
            </h2>
            <h3>2002</h3>
            <p>
              O município de Estreito foi definido como localidade para
              construção de uma usina hidrelétrica. Nesse ano, o projeto foi
              licitado, concessionado e houve a realização de diversas
              audiências públicas, gerando muita insatisfação dos habitantes da
              região. A população do estado do Maranhão e Tocantins, onde estão
              os doze municípios abrangidos pela usina, foi bastante
              prejudicada, incluindo diversos povos indígenas como os Apinajé,
              Gavião, Krikati e Krahó, além de ribeirinhos, quilombolas e
              quebradeiras de coco. As comunidades foram afetadas pela perda de
              suas casas e fonte de renda por conta do desmatamento, realocação,
              poluição, retirada dos animais que viviam na região, inúmeras
              complicações no rio Tocantins e alagamento de áreas importantes.
              Ademais, houve as implicações da chegada de milhares de pessoas
              para a construção da usina que entrou em operação em 2011 com
              concessão válida até 2037.
            </p>
          </section>
        </CardTimeline>

        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Lula e a reforma agrária
            </h2>
            <h3>2003</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1717858379/questao_agraria/2003_oojtlw.png'
              alt='2003'
              className='max-w-[110%] ml-[-1rem] mb-2 mt-[-4rem]'
            />
            <p>
              A chegada de um governo de esquerda à presidência da República
              alimentou as esperanças pela realização de uma ampla reforma
              agrária. Ainda em 2003, como forma de chamar atenção para a
              questão fundiária no Maranhão, 80 famílias sem-terra ergueram 5
              acampamentos no estado. No entanto, o que houve foi o aumento do
              número de conflitos. Foram mais de 90 casos com cerca de 10.000
              famílias envolvidas; 153 famílias expulsas; 2.216 famílias
              despejadas; 1.965 ameaças de despejo; 2.714 ameaças de expulsão;
              656 casas destruídas e 341 roças destruídas e 5 assassinatos.
            </p>
          </section>
        </CardTimeline>

        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Bolsa Família
            </h2>
            <h3>2003</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723160962/questao_agraria/2003_rizfja.png'
              alt='2003'
              className='max-w-full'
            />
            <p>
              O Programa Bolsa Família, criado durante o governo Lula em 2003,
              originou-se a partir da junção de três programas elaborados no
              mandato de Fernando Henrique Cardoso: Bolsa Escola, Bolsa
              Alimentação e Auxílio Gás. Desde sua implementação, o programa
              retirou cerca de 3 milhões de pessoas da situação de pobreza
              extrema no Brasil, também foi citado pelo FAO (Organização das
              Nações Unidas para a Alimentação e Agricultura) como um dos
              principais fatores contribuintes para a retirada do país do mapa
              da fome. Estudos indicam que a permanência na escola entre os mais
              pobres aumentou 36% em dez anos de programa.
            </p>
          </section>
        </CardTimeline>

        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Criação da Reserva Extrativista Chapada Limpa
            </h2>
            <h3>2007</h3>
            <p>
              A Resex de Chapada Limpa foi a primeira unidade de conservação de
              uso sustentável criada no bioma Cerrado, o mais ameaçado do país.
              Localizada no município de Chapadinha e com uma área de 11.971,24
              hectares, a reserva pôs fim a anos de conflito entre fazendeiros e
              camponeses na região do leste maranhense que foi amplamente
              devastada com mais de 70 mil hectares ocupados com o plantio de
              soja. Criada para proteger o modo de vida dos extrativistas e o
              uso sustentável do cerrado, a reserva passou a beneficiar cerca de
              120 famílias de comunidades tradicionais que então puderam
              transitar e usufruir dos frutos que o cerrado oferece para seu
              sustento.
            </p>
          </section>
        </CardTimeline>

        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Trabalho escravo
            </h2>
            <h3>2007</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723160863/questao_agraria/2007_x4rixu.png'
              alt='2007'
              className='max-w-full'
            />
            <p>
              O Ministério do Trabalho e Emprego apontava 6.025 trabalhadores
              resgatados em todo o Brasil. Tal levantamento determinou que 378
              trabalhadores desse total foram resgatados no Maranhão. Também
              questionava a naturalidade dos resgatados em todo o Brasil, onde
              indicava que 1.610 eram maranhenses. Esse ano registrou o maior
              número de trabalhadores maranhenses já resgatados de outros
              estados em situação análoga à escravidão.
            </p>
          </section>
        </CardTimeline>

        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Lei Antibabaçu
            </h2>
            <h3>2008</h3>
            <p>
              A Assembleia Legislativa do Maranhão propôs uma alteração para o
              primeiro artigo da Lei Estadual nº 4.734/86, conhecida como Lei do
              Babaçu Livre. Tal proposta pretendia proibir a derrubada de
              palmeiras de babaçu em todo território maranhense, exceto em áreas
              urbanas de municípios que compunham regiões metropolitanas e em
              cidades com população acima de 500.000 habitantes. A intenção dos
              deputados era que a expansão das indústrias não fosse prejudicada
              pelos palmeirais, permitindo o desmatamento e possibilitando a
              falsa noção de desenvolvimento econômico nos municípios afetados.
              Porém, a realidade seria de um impacto ainda maior às comunidades
              que dependem do babaçu. A proposta ficou conhecida como Lei
              Antibabaçu, sendo aceita em 2011.
            </p>
          </section>
        </CardTimeline>
      </>
    )
  },
  {
    key: 14,
    year: '2010',
    content: (
      <>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Refinaria Premium I
            </h2>
            <h3>2010</h3>
            <p>
              A Refinaria Premium I da Petrobras foi anunciada como o grande
              empreendimento de desenvolvimento econômico e industrial do
              estado. O projeto, que geraria cerca de 25 mil empregos, aumentou
              a especulação imobiliária na região. Contudo, foi interrompido
              cinco anos mais tarde, quando a Petrobras informou que não havia
              perspectivas econômicas na refinaria. Com a construção do
              empreendimento, a população foi impactada pelo desmatamento,
              construção de estradas, desapropriação de comunidades e a
              instalação de redes de transmissão de energia bem próximas às
              casas da população.
            </p>
          </section>
        </CardTimeline>

        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Greve de fome no INCRA
            </h2>
            <h3>2011</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723160861/questao_agraria/2011_tgrjrc.png'
              alt='2011'
              className='max-w-full'
            />
            <p>
              A ocupação de quilombolas, indígenas, camponeses e extrativistas
              na Superintendência do INCRA, em São Luís, foi motivada por conta
              da extrema insegurança dessas comunidades em seu próprio
              território, onde é comum ocorrer a retirada das famílias de suas
              terras, intimidações vindas dos grandes proprietários de terra e
              constantes ameaças e assassinatos. Com o objetivo de conquistar a
              regularização e titulação dos seus territórios e receber proteção,
              foi organizado um acampamento na sede da instituição em São Luís,
              no qual dezenas de comunidades se uniram para ter seus direitos
              assegurados. Foi realizada coletivamente uma greve de fome por
              cerca de 10 dias, um ato extremo representando a gravidade do
              quadro em que se encontravam essas populações.
            </p>
          </section>
        </CardTimeline>

        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Complexo Termelétrico Parnaíba
            </h2>
            <h3>2013</h3>
            <p>
              Sendo um dos maiores geradores de energia termelétrica do Brasil e
              localizado no município de Santo Antônio dos Lopes, o Complexo
              Parnaíba foi construído em território outrora povoado por
              comunidades locais, desencadeando diversos prejuízos. Dentre as
              inúmeras problemáticas, destaca-se o reassentamento inadequado e
              insuficiente das famílias atingidas, prejuízos à agricultura
              familiar, à extração de coco babaçu e, consequentemente, à renda
              do povo. Causando muitos danos à saúde dos moradores por conta da
              poluição sonora, do ar e das águas. Ocorrências como essas
              incitaram uma onda de protestos por parte da população, que
              reivindicava a efetivação da lei, com a proteção ao meio ambiente
              e o cumprimento de promessas feitas pela empresa responsável, a
              Eneva.
            </p>
          </section>
        </CardTimeline>

        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Criação do MATOPIBA
            </h2>
            <h3>2015</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723305182/questao_agraria/2015_y1aqc9.png'
              alt='2015'
              className='max-w-full'
            />
            <p>
              É oficializada a formação de uma nova fronteira agrícola que, além
              do Maranhão, recobre áreas dos estados do Tocantins, Piauí e
              Bahia. O Maranhão ocupa 33% dos 73.137.485 hectares, alcançando
              135 municípios, mais da metade dos 217 que conformam o estado.
              Para além dos números, a região contempla diversas Comunidades
              Tradicionais, Terras Indígenas, Áreas Quilombolas, Assentamentos
              Rurais e Unidades de Conservação, que viram nos últimos anos o
              crescimento dos casos de conflitos e violências devido às
              especulações para instalação de projetos na região.
            </p>
          </section>
        </CardTimeline>

        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Violência contra os Gamela
            </h2>
            <h3>2017</h3>
            <p>
              No município de Viana, na Baixada Maranhense, indígenas da etnia
              Gamela foram vítimas de um ataque coletivo, que resultou em cinco
              feridos por balas, duas pessoas mutiladas e outras quinze
              machucadas, entre elas menores de idade. Esse episódio endossa a
              posição do Maranhão como o segundo estado brasileiro com mais
              casos de violência contra a pessoa indígena no país. O crime de
              ódio foi protagonizado pelos moradores do município, incitados por
              um discurso intolerante realizado durante um “protesto pela paz”
              onde a identidade dos Gamela era questionada, o que levou os
              presentes a deslocarem-se até o local onde os indígenas estavam
              acampados e realizarem o crime.
            </p>
          </section>
        </CardTimeline>

        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Despejos do Cajueiro
            </h2>
            <h3>2019</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723305183/questao_agraria/2019_ryng7i.png'
              alt='2019'
              className='max-w-full'
            />
            <p>
              Em decorrência de fortes pressões por uma modernização de cunho
              autoritário, no ano de 2019, houveram reivindicações por parte da
              iniciativa privada para a construção de um porto. Construído com
              capital chinês, cerca de 200 hectares de terra da comunidade do
              Cajueiro no núcleo do Parnauaçu, localizada na zona rural da
              cidade de São Luís seriam atingidos. Através de ações de despejo
              envolvendo aparatos militares e das empresas privadas associadas à
              construção do novo porto, cerca de 19 policiais derrubaram 22
              casas sem mandado judicial. No ato, além da truculência,
              utilizaram gás lacrimogêneo contra mulheres, crianças e idosos. A
              situação segue em processo judicial e a comunidade segue
              realizando ações de resistência como, por exemplo, o lançamento do
              site “Raízes do Cajueiro”, documentação online sobre a resistência
              em defesa do território e da vida.
            </p>
          </section>
        </CardTimeline>
      </>
    )
  },
  {
    key: 15,
    year: '2020',
    content: (
      <>
        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Pandemia de Covid-19
            </h2>
            <h3>2020</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723304928/questao_agraria/2020_skbgoj.png'
              alt='2020'
              className='max-w-full mb-4'
            />
            <p>
              O cenário de crise sanitária causada pela pandemia de COVID-19
              agravou ainda mais a situação de vulnerabilidade das famílias
              camponesas. Além de enfrentarem os dilemas diários, passaram
              também a lidar com as questões de saúde, visto as altas taxas de
              disseminação e mortalidade da doença. O isolamento social, medida
              defendida para conter o espalhamento do vírus, foi colocado em
              prática por várias comunidades que passaram a controlar a entrada
              de pessoas. Também, foram repensadas as formas de manifestação
              para evitar aglomerações.
            </p>
          </section>
        </CardTimeline>

        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Mãe e filho quebradores de coco são mortos
            </h2>
            <h3>2021</h3>
            <p>
              No município de Penalva, Maria José Rodrigues, de 78 anos, e seu
              filho, José do Carmo Corrêa Júnior, de 38 anos, foram vítimas de
              mais uma das ações do agronegócio predatório no Maranhão. Enquanto
              realizavam a coleta do coco babaçu, mãe e filho foram atingidos e
              vitimados por uma palmeira derrubada com um maquinário pesado a
              mando de um fazendeiro local, que ignorou as leis de proteção aos
              palmeirais e ao acesso das áreas para sua retirada.
            </p>
          </section>
        </CardTimeline>

        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Pulverização aérea
            </h2>
            <h3>2022</h3>
            <img
              src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723305083/questao_agraria/2022_ot1m2m.png'
              alt='2022'
              className='max-w-full mb-2'
            />
            <p>
              Nos últimos anos, muitos têm sido os problemas à saúde humana,
              como queimaduras, doenças de pele e intoxicação enfrentadas por
              pessoas que moram próximas às áreas de produção de monoculturas.
              Os recursos naturais essenciais para a sobrevivência dessas
              populações também têm sido afetados. A contaminação tem provocado
              a perda das produções agrícolas destinadas à subsistência. Desse
              modo, a pulverização aérea também é uma forma de expulsar o povo
              de suas comunidades. A falta de fiscalização e punição para quem
              realiza essa prática contribui para a constância dela.
            </p>
          </section>
        </CardTimeline>

        <CardTimeline>
          <section>
            <h2 className='before:bg-[#0C0858] text-[#0C0858]'>
              Novo plano diretor de São Luís
            </h2>
            <h3>2023</h3>
            <p>
              Com o objetivo de atualizar questões relacionadas ao
              macrozoneamento da capital maranhense, no ano de 2014 iniciam-se
              as primeiras revisões do antigo Plano Diretor. Além da pouca
              participação popular, o processo foi marcado por uma série de
              discussões retrógradas, como a diminuição de áreas de dunas e
              aquíferos. Consoante à grande aprovação do empresariado do ramo
              imobiliário e da indústria da construção civil, o “Novo Plano
              Diretor” do município de São Luís foi aprovado em 2023. O aumento
              das zonas urbana e industrial em detrimento da rural, chancelados
              pelo Plano, contribuem para o aumento de conflitos e da tensão
              social e ambiental em toda Ilha.
            </p>
          </section>
        </CardTimeline>

        <CardTimeline>
          <section>
            <h2 className='before:bg-[#D7AB24] text-[#D7AB24]'>
              Lei da Grilagem
            </h2>
            <h3>2023</h3>
            <p>
              A Lei estadual 12.169/2023 (Lei da Grilagem) ampliou de 200
              hectares para 2.500 a regularização fundiária de áreas
              individuais. Antes da promulgação, apenas agricultores familiares
              eram contemplados com tal normalização. A nova regra passou a
              permitir o acesso de pequenos, médios e grandes empreendedores às
              terras públicas, dando a possibilidade da aquisição pelo
              agronegócio. Tal ação constitui contribuição direta para a
              grilagem, o que atinge diretamente as comunidades tradicionais,
              levando ao aumento dos conflitos agrários, mortes no campo e
              desmatamento.
            </p>
          </section>
        </CardTimeline>

        <CardTimeline>
          <section>
            <h2 className='before:bg-[#106246] text-[#106246]'>
              Brasil é julgado na Corte Interamericana de Direitos Humanos
            </h2>
            <h3>2023</h3>
            <p>
              O Estado Brasileiro foi chamado pela Corte Interamericana de
              Direitos Humanos a responder por violações contra comunidades
              quilombolas do município de Alcântara durante a construção do
              Centro de Lançamento pela Força Aérea Brasileira. Essa foi a
              primeira vez que o Brasil, assim como as Forças Armadas, foram
              julgados em um tribunal internacional por uma ação em território
              quilombola. A construção da base resultou na expropriação de
              terras de várias comunidades, gerando conflitos que já duram mais
              de quarenta anos. O Brasil reconheceu as violações e prometeu
              reparar as comunidades, garantindo a posse das terras e recursos
              financeiros, mas essas reparações ainda não foram cumpridas.
            </p>
          </section>
        </CardTimeline>
      </>
    )
  }
]
