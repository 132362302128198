export const theme = {
  color: {
    bg: "#F1F2F1",
    primaryColor: "#6F3228",
    secondaryColor: "#ff9b42",
    terciaryColor: "#DBCCCE",
    white: " #f7f4f3",
    grey: "#c7cedb",
    darkGrey: "#647f92",
    black: "#010101",
    light: '#F6FAF6'
  },
  fonts: {},
  spacings: {},
};
