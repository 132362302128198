import styled from 'styled-components'
export const Container = styled.section`
  display: flex;
  flex-direction: column;
  h2,
  h3,
  p {
    margin: 0;
  }
  section {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      padding: 0;
    }
    h2 {
      position: relative;
      font-size: 1.125rem;
      line-height: 1.4rem;
      font-weight: 600;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      text-align: center;
    }
    h3 {
      color: #953b2c;
      font-weight: 800;
      font-size: 1.2em;
    }
    p {
      margin-top: 0.5rem;
      line-height: 1.35rem;
      text-align: center;
      font-size: 1.125rem !important;
    }
  }
`
