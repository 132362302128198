import React, { useEffect, useState } from 'react'
import { Container } from './styles'
import { Spinner } from '@material-tailwind/react'
import HeaderPages from '../../components/HeaderPages/HeaderPages'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'

const PublicacoesChildren = () => {
  const { id } = useParams()
  const [publi, setPubli] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true)
      const url = `https://bdmaranhaoagrario.shop/api/publications?filters[type][$eq]=${id}&sort[0]=createdAt:desc`
      const response = await fetch(url, {
        headers: {
          Authorization:
            `Bearer 4b9915a6c4cf1ed9f8e9176f2bf098ab2985140b8ed3cbb71d4d45aa16ff2e7ba54b7dca3d9831853db6939045eeb3d8886a945557ae8a8eff76d137baf592e7d8ebbfec8237928f3346f42a62175028deaf2e6eef8640fd4a7b7aaad751b836bc59e734ee41ca8598ab29a1979af92a693f156528b220e12e9eef76e06d7673`
        }
      })
      const jsonData = await response.json()
      setPubli(jsonData.data)
      setLoading(false)
    }

    fetchPost()
  }, [id])

  const capitalizeFirstLetter = string => {
    if (!string) return ''

    // Substitui underscores por espaços
    const formattedString = string.replace(/_/g, ' ')

    // Capitaliza a primeira letra de cada palavra
    return formattedString
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const formatId = id => {
    if (id === 'teses_e_dissertacoes') {
      return 'Teses e Dissertações'
    } else if (id === 'livros_e_capitulos') {
      return 'Livros e capítulos de livros'
    } else if (id === 'relatorios') {
      return 'Relatórios Dataluta'
    }
    return capitalizeFirstLetter(id)
  }
  return (
    <>
      <HeaderPages content='Publicações' />
      <Container>
        <main className='md:mt-[-6rem]'>
          <h1 className='md:mb-10'>{formatId(id)}</h1>
          {loading ? (
            <div className='flex items-center justify-center h-[80vh]'>
              <Spinner color='red' size='3xl' />
            </div>
          ) : (
            <ul className='mb-[5rem]'>
              {publi.map(publi => (
                <li key={publi.id} className='list-disc list-inside'>
                  <a
                    href={publi.attributes.link}
                    target='_blank'
                    rel='noreferrer'
                    className='text-xl md:text-2xl font-semibold text-[#6d3127] hover:cursor-pointer hover:text-[#b4372a]'
                  >
                    {`${publi.attributes.title}.`}<br/>
                    <div className='ml-6 text-[0.9rem] md:text-[1.2rem] text-[#000]'>{`${publi.attributes.author ? publi.attributes.author + "." : ""} ${publi.attributes.year ? publi.attributes.year + "." : ""}`}</div>
                  </a>
                </li>
              ))}
            </ul>
          )}
        </main>
        <Link
          to='/publicacoes'
          className='text-xl bg-[#6d3127] py-1 px-3 text-white rounded hover:scale-105 transition-transform duration-300'
        >
          Voltar
        </Link>
      </Container>
    </>
  )
}
export default PublicacoesChildren
